import React from "react";
import { useNavigate } from "react-router-dom";
import "./payment.css";

const PaymentFailure = () => {
  const navigate = useNavigate();
  return (
    <div className="payment-failure">
      <div className="icon-container">
        <span className="failure-icon">&#10007;</span> {/* Unicode X symbol */}
      </div>
      <h1 className="failure-message">Payment Failed!</h1>
      <p className="error-text text-center fs-5">
        Something went wrong with your transaction. Please try again or contact
        support if the issue persists.
      </p>
      <div className="button-container">
        <button className="home-button" onClick={() => navigate("/")}>
          Return to Home
        </button>
      </div>
    </div>
  );
};

export default PaymentFailure;
