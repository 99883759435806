import React from "react";
import IEltImages from "../components/Test prep/Ielts/Ielts-images";
import IeltsContent from "../components/Test prep/Ielts/IeltsContent";
import TestBanner from "../components/Test prep/TestBanner";
import HelmetWrapper from "../HelmetWrapper";

function Ielts() {
  return (
    <>
      <HelmetWrapper
        title="IELTS Exam Guide | Confluence Education"
        description="Discover everything you need to know about the IELTS exam. Prepare effectively for your language proficiency test with Confluence Education's expert resources."
        canonicalUrl="https://www.confluenceedu.com/ielts"
      />
      <TestBanner IELTS={"IELTS"} />
      <IeltsContent />
      <IEltImages />
    </>
  );
}

export default Ielts;
