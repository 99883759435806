import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import "./latestBlog.css";
import config from "../../Boot/config.json";
import DefaultImage from "../../Assets/MobileBanner.jpg";
import { GiPin } from "react-icons/gi";
import { RxDrawingPin } from "react-icons/rx";
import { AuthContext } from "../../App";

import axios from "axios";

function LatestBlog({ pinned, AddToWl }) {
  const [latestData, setLatestData] = useState([]);
  const { token } = useContext(AuthContext);

  useEffect(() => {
    const getLateestBlogs = async () => {
      try {
        const response = await axios.get(`${config.baseUrl}/Blogs/latestBlogs`);
        setLatestData(response.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    getLateestBlogs();
  }, [pinned]);

  function urlEncoded(title) {
    return title
      .toLowerCase() // Optional: convert to lowercase
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
  }

  const renderCardImage = (imageUrl) => {
    return imageUrl ? (
      <img
        src={imageUrl}
        alt="latest blog"
        style={{ height: "6rem" }}
        className="latest_blg_image"
      />
    ) : (
      <img
        src={DefaultImage}
        alt="latest blog"
        style={{ height: "6rem" }}
        className="latest_blg_image"
      />
    );
  };

  return latestData.map((latestpost, index) => (
    <div key={index}>
      <Link
        to={`/blog/${urlEncoded(latestpost.mainHeading)}/${latestpost._id}`}
        style={{ textDecoration: "none", color: "black" }}
        className="card-link "
      >
        <div className="latest_blogs mt-2 mx-2 ">
          <div className="latest_blog overflow-hidden rounded-2 d-flex position-relative">
            {renderCardImage(latestpost.Image)}
            <div className="blog_Content">
              <h6 className="mt-2">{latestpost.mainHeading}</h6>
            </div>

            {token &&
              (latestpost.fav === true ? (
                <GiPin
                  className="pins position-absolute top-0 left-0"
                  onClick={(e) => AddToWl(e, latestpost._id, false)}
                />
              ) : (
                <RxDrawingPin
                  className="pins position-absolute top-0 left-0"
                  onClick={(e) => AddToWl(e, latestpost._id, true)}
                />
              ))}
          </div>
        </div>
      </Link>
    </div>
  ));
}
export default LatestBlog;
