import { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import "../countryPage.css";

function IVYUniversitiesPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { universities, country } = location.state || {
    universities: [],
    country: "",
  };
  const [readMore, setReadMore] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState(null);

  const handleReadMore = (university) => {
    setSelectedUniversity(university);
    setReadMore(true);
  };

  const handleClose = () => {
    setReadMore(false);
  };

  const handleBack = () => {
    const formattedCountry = country.replace(/\s+/g, "-");
    navigate(`/destination/Study-in-${formattedCountry}`);
  };
  return (
    <div className="bg-white">
      <button
        onClick={handleBack}
        className="backToBlog py-1 px-2 text-decoration-none position-fixed text-center d-none d-md-inline"
      >
        <FaArrowLeftLong className="me-1" />
      </button>
      <h1 className="text-center countryPageHeading pt-3">
        Universities in {country}
      </h1>
      <div className="d-flex flex-wrap justify-content-center px-xxl-5">
        {universities.map((university, index) => (
          <div key={index} className="ivyLeague bg-white m-3 text-center">
            <div className="d-flex justify-content-center">
              <img src={university.logo} alt={university.university} />
            </div>
            <p className="mb-4">
              {university.description.slice(0, 200)}
              {university.description.length > 200 && "... "}
              {university.description.length > 200 && (
                <span
                  onClick={() => handleReadMore(university.university)}
                  title="Read more about the university"
                  className="text-primary fw-bold readMoreButton"
                >
                  Read More
                </span>
              )}
            </p>
            {readMore && selectedUniversity === university.university && (
              <Modal show={readMore} onHide={handleClose}>
                <Modal.Body>
                  <div className="d-flex justify-content-center">
                    <img
                      src={university.logo}
                      alt={university.university}
                      className="ivyleagueImg"
                    />
                  </div>
                  <p>{university.description}</p>
                </Modal.Body>
              </Modal>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default IVYUniversitiesPage;
