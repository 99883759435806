import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import "./countryPage.css";
import CoursesUS from "./CoursesUS.js";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { useState } from "react";
import { Modal } from "react-bootstrap";
import HelmetWrapper from "../../HelmetWrapper.js";

function CountryPage({ data }) {
  const navigate = useNavigate();
  const [readMore, setReadMore] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState(null);

  const handleReadMore = (university) => {
    setSelectedUniversity(university);
    setReadMore(true);
  };

  const handleClose = () => {
    setReadMore(false);
  };

  const handleViewAll = () => {
    const formattedCountry = data.countryName.replace(/\s+/g, "-");
    navigate(`/${formattedCountry}/universities`, {
      state: {
        universities: data.IVYLeagueColleges,
        country: data.countryName,
      },
    });
  };

  return (
    <>
      <HelmetWrapper
        title={
          data.titleTag
            ? data.titleTag
            : `Study in ${data.countryName} | Confluence Educational Services | Study Abroad`
        }
        description="Discover the advantages of studying abroad for Indian students, including global exposure, quality education, career opportunities, personal development, and language proficiency enhancement."
        canonicalUrl={`https://www.confluenceedu.com/destination/Study-in-${data.countryName.replace(
          /\s+/g,
          "-"
        )}`}
      />
      <div className="bg-white">
        <Link
          to={`/destination`}
          className="backToBlog py-1 px-2 text-decoration-none position-fixed text-center d-none d-md-inline"
        >
          <FaArrowLeftLong className="me-1" />
        </Link>
        <div className="countryPagesec1 d-flex justify-content-center pt-5">
          <div>
            <h1 className="text-center countryPageHeading">
              Study in {data.countryName}
            </h1>
            <p className="text-center mx-auto mb-0 p-3">{data.aboutCountry}</p>
          </div>
        </div>
        {data.WhyStudyinThatCountry && (
          <div>
            <h2 className="text-center countryPageHeading p-4">
              Why study in {data.countryName}?
            </h2>
            <div className="d-flex flex-wrap mx-auto whyStudyUSASec justify-content-center mx-auto ">
              {data.WhyStudyinThatCountry.map((about) => (
                <div className="m-4 m-lg-5 d-flex flex-column justify-content-center align-items-center">
                  <img
                    src={about.icon}
                    className="whystudyIcon"
                    alt="study in usa icon"
                  />
                  <p className="text-center">{about.text}</p>
                </div>
              ))}
            </div>
            {/* <div className="bg-success w-25 p-1 position-absolute bottom-0 end-0"></div> */}
          </div>
        )}

        {data.popularCourses && <CoursesUS courses={data.popularCourses} />}

        {data.TopCities && (
          <div>
            <h2 className="text-center countryPageHeading pt-5">
              Popular cities
            </h2>

            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              loop={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 70,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 70,
                },
                1400: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              {data.TopCities.map((city) => (
                <SwiperSlide key={city.city}>
                  <div className="citycard text-center">
                    <h3>{city.city} </h3>
                    <p>{city.description}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}

        {data.IVYLeagueColleges && (
          <div>
            <h2 className="text-center countryPageHeading pt-3">Ivy League</h2>
            <div className="d-flex flex-wrap justify-content-center px-xxl-5">
              {data.IVYLeagueColleges.length > 8
                ? data.IVYLeagueColleges.slice(0, 7).map(
                    (university, index) => (
                      <div key={index} className="ivyLeague m-3 text-center">
                        <div className="d-flex justify-content-center">
                          <img
                            src={university.logo}
                            alt={university.university}
                          />
                        </div>
                        <p className="mb-4">
                          {university.description.slice(0, 200)}
                          {university.description.length > 200 && "... "}
                          {university.description.length > 200 && (
                            <span
                              onClick={() =>
                                handleReadMore(university.university)
                              }
                              title="Read more about the university"
                              className="text-primary fw-bold readMoreButton"
                            >
                              Read More
                            </span>
                          )}
                        </p>
                        {readMore &&
                          selectedUniversity === university.university && (
                            <Modal show={readMore} onHide={handleClose}>
                              <Modal.Body>
                                <div className="d-flex justify-content-center">
                                  <img
                                    src={university.logo}
                                    alt={university.university}
                                    className="ivyleagueImg"
                                  />
                                </div>
                                <p>{university.description}</p>
                              </Modal.Body>
                            </Modal>
                          )}
                      </div>
                    )
                  )
                : data.IVYLeagueColleges.map((university, index) => (
                    <div key={index} className="ivyLeague m-3 text-center">
                      <div className="d-flex justify-content-center">
                        <img
                          src={university.logo}
                          alt={university.university}
                        />
                      </div>
                      <p className="mb-4">
                        {university.description.slice(0, 200)}
                        {university.description.length > 200 && "... "}
                        {university.description.length > 200 && (
                          <span
                            onClick={() =>
                              handleReadMore(university.university)
                            }
                            title="Read more about the university"
                            className="text-primary fw-bold readMoreButton"
                          >
                            Read More
                          </span>
                        )}
                      </p>
                      {readMore &&
                        selectedUniversity === university.university && (
                          <Modal show={readMore} onHide={handleClose}>
                            <Modal.Body>
                              <div className="d-flex justify-content-center">
                                <img
                                  src={university.logo}
                                  alt={university.university}
                                  className="ivyleagueImg"
                                />
                              </div>
                              <p>{university.description}</p>
                            </Modal.Body>
                          </Modal>
                        )}
                    </div>
                  ))}

              {data.IVYLeagueColleges.length > 8 && (
                <div className="ivyLeague m-3 text-center">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100%", cursor: "pointer" }}
                    onClick={handleViewAll}
                  >
                    <span className="text-primary fw-bold">View All</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CountryPage;
