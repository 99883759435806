import React, { useState, useEffect, useContext } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaPencilAlt } from "react-icons/fa";
import { GiPin } from "react-icons/gi";
import { MdDelete } from "react-icons/md";
import { FaArrowLeftLong } from "react-icons/fa6";
import { RxDrawingPin } from "react-icons/rx";
import { AuthContext } from "../../App";
import config from "../../Boot/config.json";
import PostEditor from "../Posteditor/PostEditor";
import RegistrationForm from "../RegistrationBlock/RegistrationBlock";
import HelmetWrapper from "../../HelmetWrapper";
import {
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

import "./blogPage.css";

function BlogPage() {
  const { token, setPageNum, pageNum } = useContext(AuthContext);
  const [editClicked, setEditClicked] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [favCount, setfavCount] = useState(0);
  const [fav, setFav] = useState(false);
  const [showImage, setShowImage] = useState(true);

  const navigate = useNavigate();

  const handleClick = () => {
    setEditClicked(true);
  };

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Blogs`,
    headers: {
      Authorization: token,
    },
  });

  const deleteBlog = async (e, id) => {
    try {
      await axiosInstance.delete(`/deleteBlog/${id}`);
      setDeleteStatus(!deleteStatus);
      navigate("/Blogs");
    } catch (err) {
      console.log(err);
    }
  };

  const addTWL = async (e, id, value) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const formData = {
        fav: value,
      };
      if (favCount < 9) {
        await axiosInstance.patch(`/favUnFav/${id}`, formData);
        setFav(!fav);
      } else if (favCount >= 9 && value === false) {
        await axiosInstance.patch(`/favUnFav/${id}`, formData);
        setFav(!fav);
      } else {
        alert("Reached max number of pins");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const [data, setData] = useState({});
  const pageNumber = parseInt(page, 10);
  const url = `${config.baseUrl}/${location.pathname}`;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${config.baseUrl}/Blogs/getOne/${id}`
        );
        setfavCount(response.data.favCount);

        setData(response.data.data);

        setFav(data.fav);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, [id, deleteStatus, fav]);

  if (!data) {
    return <p>Blog post not found</p>;
  }
  if (editClicked) {
    return (
      <PostEditor
        editHeading={data.mainHeading}
        editImage={data.Image}
        editCategory={data.category}
        editDescription={data.Data}
        editPreview={data.cardPreview}
        editStatus={true}
      />
    );
  }

  return (
    <div className="mainBlogPageSection">
      <HelmetWrapper
        title={
          data.seoTitle && data.seoTitle.length > 0
            ? data.seoTitle
            : data.mainHeading
        }
        description={
          data.metaDescription && data.metaDescription.length > 0
            ? data.metaDescription
            : "At Confluence Educational Services, we offer a wide range of services designed to cater to the diverse needs of our students. From study abroad consulting to university admissions assistance, we provide comprehensive support at every step of the journey. Our services extend to various destination countries, including the USA, Canada, UK, Ireland, Australia, New Zealand, Germany, France, Italy, Spain, the Netherlands, and Switzerland."
        }
        canonicalUrl={
          data.mainHeading
            ? `https://www.confluenceedu.com/blog/${data.mainHeading
                .toLowerCase()
                .replace(/[^a-z0-9]+/g, "-")
                .replace(/^-+|-+$/g, "")}/${data._id}`
            : ""
        }
      />
      <Link
        to={`/blogs?page=${pageNum}`}
        className="backToBlog py-1 px-2 text-decoration-none position-fixed text-center"
        onClick={() => {
          setPageNum(pageNumber);
        }}
      >
        <FaArrowLeftLong className="me-1" />
      </Link>

      {token && (
        <div className=" float-end d-none d-md-block position-absolute end-0">
          <button
            className=" position-relative text-center del  editbPost"
            onClick={(e) => deleteBlog(e, id)}
          >
            <MdDelete className="fs-3" />
          </button>
          {data.fav === true ? (
            <button
              className=" text-decoration-none position-relative text-center editbPost pinned"
              onClick={(e) => addTWL(e, id, !data.fav)}
            >
              <GiPin className="fs-3" />
            </button>
          ) : (
            <button
              className=" position-relative text-center editbPost rmPin"
              onClick={(e) => addTWL(e, id, true)}
            >
              <RxDrawingPin className="fs-3" />
            </button>
          )}

          <button
            onClick={handleClick}
            className="editbPost edit2 text-decoration-none  position-relative text-center float-end "
          >
            <FaPencilAlt />
          </button>
        </div>
      )}
      <div className="d-lg-flex justify-content-around justify-content-xl-center">
        <div className="blogDetailPage d-block p-4">
          <h1 className="Blog_heading">{data.mainHeading}</h1>
          <img
            src={data.Image}
            alt="study abroad Blog "
            className="Blog_Image"
          />
          <p className="Blog_DetailedContent">
            <span className="invisible">.........</span>
            <div dangerouslySetInnerHTML={{ __html: data.Data }} />
          </p>
        </div>
        <div className="registration">
          <RegistrationForm showFormImage={showImage} />
          <div className="p-4">
            <h4>Share </h4>

            <FacebookShareButton url={url} className="me-3">
              <FacebookIcon size={40} round={true} />
            </FacebookShareButton>
            <WhatsappShareButton url={url} className="me-3">
              <WhatsappIcon size={40} round={true} />
            </WhatsappShareButton>
            <TelegramShareButton url={url} className="me-3">
              <TelegramIcon size={40} round={true} />
            </TelegramShareButton>
            <TwitterShareButton url={url} className="me-3">
              <TwitterIcon size={40} round={true} />
            </TwitterShareButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogPage;
