import React from "react";
import ToeflContent from "../components/Test prep/Toefl/ToeflContent";
import ToeflImages from "../components/Test prep/Toefl/Toefl-images";
import TestBanner from "../components/Test prep/TestBanner";
import HelmetWrapper from "../HelmetWrapper";

function Toefl() {
  return (
    <>
      <HelmetWrapper
        title="TOEFL Exam Guide | Confluence Education"
        description="Discover everything you need to know about the TOEFL exam. Prepare effectively for your language proficiency test with Confluence Education's expert resources."
        canonicalUrl="https://www.confluenceedu.com/toefl"
      />
      <TestBanner TOEFL={"TOEFL"} />
      <ToeflContent />
      <ToeflImages />
    </>
  );
}

export default Toefl;
