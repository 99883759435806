import React from "react";
import GermanFreeDemo from "../../Assets/german free demo.jpeg";
import GermanA1 from "../../Assets/german A1.jpeg";
import GermanIelts from "../../Assets/German+ielts.jpeg";

import TestBanner from "./TestBanner";
import "./Ielts/ielts-images.css";

function German() {
  const germanImgs = [
    {
      imgSrc: GermanFreeDemo,
      alt: "German free demo",
    },
    {
      imgSrc: GermanA1,
      alt: "german A1",
    },
    {
      imgSrc: GermanIelts,
      alt: "German + Ielts course",
    },
  ];
  return (
    <div>
      <TestBanner German={"German"} />
      <div>
        {germanImgs.map((Img) => (
          <a href="https://testprep.confluenceedu.com/">
            <img
              src={Img.imgSrc}
              alt={Img.alt}
              className="ieltsimg1 mb-2"
            ></img>
          </a>
        ))}
      </div>
    </div>
  );
}

export default German;
