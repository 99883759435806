import React from "react";
import FeeStructureTables from "../FeeStructureTable";
import Services from "./Services.json";
import "./countryImage.css";
import NetherlandsAtoV from "../../../Assets/countryImages/Netherlands A to V.jpg";

function Netherlands() {
  const StudentServices = [
    {
      service: "Bachelors Admissions Counseling",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
    {
      service: "Masters Admissions Counseling",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
    {
      service: "Visit Visa Services",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
  ];

  return (
    <div className="bg-white d-flex justify-content-center flex-column align-items-center">
      {/* <h1>Netherlands</h1> */}
      <img
        src={NetherlandsAtoV}
        alt="Netherlands admission to Visa"
        className="countryAtoVImg"
      />
      <FeeStructureTables
        countryName={"Netherlands"}
        studentServices={StudentServices}
      />
    </div>
  );
}

export default Netherlands;
