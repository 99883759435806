import React from "react";
import "./aboutConfluencecss.css";
import Card from "react-bootstrap/Card";
import Separator from "../../Assets/texticon-1.webp";
import separatorFallBack from "../../Assets/texticon-1.jpg";

function About() {
  const cardDetails = [
    { heading: "18+", subHeading: "Years of Experience" },
    { heading: "3000+", subHeading: "Success Stories" },
    { heading: "10+", subHeading: "Countries" },
    { heading: "10000+", subHeading: "Courses" },
  ];
  return (
    <section className="wr_about">
      <div className="about__section p-3 pb-0 pt-xl-4 mt-0 ">
        <h1 className="about_heading fs-2 text-center">About Confluence Edu</h1>

        <picture>
          <source srcSet={Separator} type="image/webp" />
          <img
            src={separatorFallBack}
            alt="separator"
            className="image__separator d-block  my-3 mx-auto"
          />
        </picture>
        <p className="about__para px-3 d-lg-none  text-center">
          Confluence Education has for years been working on its mission to work
          along with the students and help them get enrolled in universities
          suiting best of their needs. It is growing as one of the most sought
          centres for counselling and educational services.
        </p>

        <div className="about__para2 w-50 d-none d-lg-block text-center mx-auto ">
          Confluence Educational Services is a beacon of guidance and support
          for students aspiring to pursue higher education abroad. Founded 18
          years ago, our organization has been dedicated to facilitating the
          dreams of thousands of students who aim to study in prestigious
          institutions across the globe.
          <br />
          <span className="fs-3 fw-bold mission">Mission </span>
          We serve all overseas Opportunity Education and Employment seekers
          <br />
          <span className="fs-3 fw-bold mission">Vision </span>
          We maintain ethical and quality services up to date to meet the
          aspirations of overseas Education and Employment seekers. <br />
        </div>
      </div>
      <div className="d-flex justify-content-center flexCrad text-center  flex-wrap expcard">
        {cardDetails.map((card, index) => (
          <Card className="displayCarD mx-xl-auto" key={index}>
            <Card.Body>
              <h1 className="cardHeading">{card.heading}</h1>
              <h4 className="mb-2 text-muted">{card.subHeading} </h4>
            </Card.Body>
          </Card>
        ))}
      </div>
    </section>
  );
}

export default About;
