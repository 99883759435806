import React from "react";
import "./blogBanner.css";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import Img3 from "../../Assets/lib2.jpeg";
import { AuthContext } from "../../App";

function BlogBanner() {
  const tokensDetails = useContext(AuthContext);

  const addPostIcon = () => {
    return tokensDetails.token ? (
      <NavLink
        to="/protected/addPost"
        className="addPost d-none d-md-block py-1 px-3 fw-bold position-fixed text-decoration-none rounded-3"
      >
        Add Post
      </NavLink>
    ) : (
      <></>
    );
  };

  return (
    <div>
      {tokensDetails.token && addPostIcon()}
      <div className="total_blog d-flex justify-content-between flex-column flex-md-row align-items-center">
        <div className="blog_content">
          <h4>Blogs on Study Abroad</h4>
          <div className="d-none d-md-block">
            <p>
              Looking for insightful articles, valuable tips, and captivating
              stories about study abroad?
            </p>
            <p className="fs-5">Look no further! </p>
            <p>Our blog is the perfect destination for all your interests.</p>
            <p>
              Bookmark our page and stay updated on the latest trends, tips, and
              stories. Don't miss out!
            </p>
          </div>
        </div>
        <div className="blog_img">
          <img src={Img3} alt="blogImage" />
        </div>
      </div>
    </div>
  );
}

export default BlogBanner;
