import React, { useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoginImage from "../../Assets/RegistrationBlockImage.jpg";
import "./loginPage.css";
import { AuthContext } from "../../App";
import config from "../../Boot/config.json";

function LoginPage() {
  const navigate = useNavigate();
  const { setToken, setNavState } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "email") {
      setEmail(value);
    }
    if (id === "password") {
      setPassword(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      email: email,
      password: password,
    };
    try {
      axios
        .post(`${config.baseUrl}/User/login`, formData)
        .then((response) => {
          console.log("---response", response);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userId", response.data.userId);
          localStorage.setItem("userName", response.data.userName);
          setToken(localStorage.getItem("token"));
          setNavState(true);
          navigate("/blogs");
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.error) {
            setError(err.response.data.error);
          } else {
            setError("Enter a valid email and password");
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="login_page">
      <div className="login_page_card">
        <img src={LoginImage} alt="admin login form" className="login_image" />
        <Form className="login_form">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            id="email"
            aria-describedby="passwordHelpBlock"
            onChange={handleChange}
          />
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            id="password"
            aria-describedby="passwordHelpBlock"
            onChange={handleChange}
          />
          {error && (
            <p className="text-danger fw-medium mt-2 errorMsg fs-6">{error}</p>
          )}
          <Button onClick={handleSubmit}>Submit</Button>
        </Form>
      </div>
    </div>
  );
}

export default LoginPage;
