import React from "react";

function Ireland() {
  return (
    <>
      <svg width="50" height="50" viewBox="0 0 1200 600">
        <rect fill="#169b62" width="1200" height="600" />
        <rect fill="#fff" x="400" width="800" height="600" />
        <rect fill="#ff883e" x="800" width="400" height="600" />
      </svg>
    </>
  );
}

export default Ireland;
