import React, { useState } from "react";
import { Alert, Button, Form, FloatingLabel } from "react-bootstrap";
import "./registrationBlock.css";
import OTPScreen from "../OtpScreen/Otp";
import axios from "axios";

import RegistrationImage from "../../Assets/RegistrationBlockImage.jpg";
import TrainingImg from "../../Assets/RegistrationImg.png";

function RegistrationForm({ showFormImage }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailDisabled, setEmailDisabled] = useState(true);
  const [isAllFieldsDisabled, setAllFieldsDisabled] = useState(true);
  const [alertVisible, setAlertVisible] = useState(false);
  const [verifyBtn, setVerifyBtn] = useState("Verify");
  const [show, setShow] = useState(false);
  const [contactFD, setContactFD] = useState({
    name: "",
    email: "",
    phone: "",
    country: "United States of America",
    year: "2024",
  });

  function handleInputs(e) {
    const { id, value } = e.target;

    if (id === "phone") {
      // If the input is for the phone field, enforce numeric input and apply maxLength
      const numericOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
      const truncatedValue = numericOnly.slice(0, 10); // Apply maxLength constraint

      setContactFD((prevFD) => {
        const updatedFD = { ...prevFD, [id]: truncatedValue };
        return updatedFD;
      });
    } else {
      // For other fields, directly update the state
      setContactFD((prevFD) => {
        const updatedFD = { ...prevFD, [id]: value };
        const { name } = updatedFD;
        setEmailDisabled(name.length <= 2);
        return updatedFD;
      });
    }
  }

  function handleVerification() {
    try {
      setShow(true);
      axios
        .post("https://www.confluenceedu.com:8080/FormData/emailVerification", {
          name: contactFD.name,
          email: contactFD.email,
        })
        .then((response) => {
          alert(`OTP sent to ${contactFD.email}`);
        })
        .catch((err) => {
          if (
            err.response.status === 409 &&
            err.response.data === "email already exists"
          ) {
            alert(`${contactFD.email} already exists try another email`);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    //trim user data
    const { name, email, phone } = contactFD;
    const trimmedname = name.trim();
    const trimmedEmail = email.trim();
    const trimmedPhone = phone.trim();
    // Validate trimmed inputs

    switch (true) {
      case trimmedname.length === 0:
        setErrorMessage("Please enter user Name");
        break;
      case trimmedname.length <= 3:
        setErrorMessage("please enter name atleast 3 letters required");
        break;
      // Check if email includes @
      case trimmedEmail.includes("@") === false:
        setErrorMessage("Please enter a valid email");
        break;

      // Check if mobile is of 10 numbers legth
      case trimmedPhone.length !== 10:
        setErrorMessage("Please enter a valid mobile number");
        break;

      // check if mobile number is valid according to reference of below numbers
      case trimmedPhone < "1000000000":
      case trimmedPhone === "1234567890":
      case trimmedPhone === "9876543210":
      case trimmedPhone === "5678901234":
      case trimmedPhone === "0000000000":
      case trimmedPhone === "1111111111":
      case trimmedPhone === "2222222222":
      case trimmedPhone === "3333333333":
      case trimmedPhone === "4444444444":
      case trimmedPhone === "5555555555":
      case trimmedPhone === "6666666666":
      case trimmedPhone === "7777777777":
      case trimmedPhone === "8888888888":
      case trimmedPhone === "9999999999":
        setErrorMessage("Invalid mobile number");
        break;

      default:
        try {
          contactFD.name = trimmedname;
          contactFD.email = trimmedEmail;
          contactFD.phone = trimmedPhone;
          axios
            .post("https://www.confluenceedu.com:8080/FormData", contactFD)
            .then((response) => {
              setContactFD((prevContactFD) => {
                return {
                  name: "",
                  email: "",
                  phone: "",
                  country: "United States of America",
                  year: "2024",
                };
              });
              setErrorMessage("");
              setAlertVisible(true);
              setEmailDisabled(true);
              setAllFieldsDisabled(true);
              setVerifyBtn("Verify");
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (err) {
          console.log(err);
        }
    }
  }

  return (
    <div
      className={
        !showFormImage
          ? "main_contact_form d-flex flex-direction-md-row justify-content-around align-items-center justify-content-md-aroundevenly"
          : ""
      }
    >
      {alertVisible && (
        <Alert
          variant="success"
          onClose={() => setAlertVisible(false)}
          className="position-fixed top-0 start-50 translate-middle-x w-25 alertpopup"
          dismissible
        >
          <Alert.Heading>Success!</Alert.Heading>
          <p>we will contact you soon</p>
        </Alert>
      )}
      <OTPScreen
        handleClose={setShow}
        showModal={show}
        userEmail={contactFD.email}
        disabledFields={setAllFieldsDisabled}
        checkIsDis={isAllFieldsDisabled}
        verifyButton={setVerifyBtn}
      />

      <div className="form_image d-none d-md-block">
        {!showFormImage && (
          <div>
            <div className="">
              {/* <img
                src={RegistrationImage}
                className="position-relative"
                alt="contactus"
              /> */}
              <img
                src={TrainingImg}
                className="position-relative registrationImg"
                alt="contactus"
              />
            </div>
          </div>
        )}
      </div>
      <div
        className={
          !showFormImage
            ? " form_body rounded-3 m-md-0 m-lg-2 me-xl-5 right-md-0"
            : "BlogForm"
        }
      >
        <div className="contact_form_heading mb-4">
          <h2>Get in Touch With Us</h2>
        </div>
        <FloatingLabel id="floatingInput" label="Full Name" className="mb-3">
          <Form.Control
            type="text"
            id="name"
            placeholder="Enter your name"
            value={contactFD.name}
            onChange={handleInputs}
            required
          />
          <div>
            {errorMessage === "Please enter user Name" ||
            errorMessage === "please enter name atleast 3 letters required" ? (
              <p className="text-danger">{errorMessage}</p>
            ) : null}
          </div>
        </FloatingLabel>

        <FloatingLabel
          id="floatingInput"
          label="Email Address"
          className="mb-3 d-flex"
        >
          <Form.Control
            type="email"
            id="email"
            value={contactFD.email}
            onChange={handleInputs}
            placeholder="Enter your email address"
            disabled={isEmailDisabled}
            style={{ cursor: isEmailDisabled ? "not-allowed" : "auto" }}
            required
          />
          <Button
            className="bg-dark ms-2 text-white border border-light "
            disabled={isEmailDisabled}
            onClick={handleVerification}
          >
            {verifyBtn}
          </Button>
        </FloatingLabel>

        <div>
          {errorMessage === "Please enter a valid email" ? (
            <p className="text-danger">{errorMessage}</p>
          ) : null}
        </div>
        <FloatingLabel
          id="floatingInput"
          label="Mobile Number"
          className="mb-3"
        >
          <Form.Control
            type="tel"
            id="phone"
            value={contactFD.phone}
            onChange={handleInputs}
            maxLength={10}
            placeholder="Enter your Your Phone number"
            disabled={isAllFieldsDisabled}
            style={{ cursor: isAllFieldsDisabled ? "not-allowed" : "auto" }}
            required
          />
          <div>
            {errorMessage === "Please enter a valid mobile number" ? (
              <p className="text-danger">{errorMessage}</p>
            ) : null}
          </div>
        </FloatingLabel>
        <Form.Label htmlFor="preferred_country">
          Choose your Preferred Country:
        </Form.Label>
        <Form.Select
          aria-label="Default select example"
          id="country"
          value={contactFD.country}
          onChange={handleInputs}
          disabled={isAllFieldsDisabled}
          style={{ cursor: isAllFieldsDisabled ? "not-allowed" : "auto" }}
          className="mb-3"
        >
          <option>Germany</option>
          <option value="United States of America">
            United States of America
          </option>
          <option value="United Kingdom">United Kingdom</option>
          <option value="Australia">Australia</option>
          <option value="Canada">Canada</option>
          <option value="Italy">Italy</option>
          <option value="Spain">Spain</option>
          <option value="Ireland">Ireland</option>
          <option value="Netherlands">Netherlands</option>
          <option value="New Zealand">New Zealand</option>
          <option value="Germany">Germany</option>
          <option value="France">France</option>
        </Form.Select>
        <Form.Label htmlFor="year">When are you planning to leave?</Form.Label>
        <Form.Select
          aria-label="Default select example"
          id="year"
          className="mb-3"
          value={contactFD.year}
          onChange={handleInputs}
          disabled={isAllFieldsDisabled}
          style={{ cursor: isAllFieldsDisabled ? "not-allowed" : "auto" }}
        >
          <option>2024</option>
          <option>2025</option>
          <option>2026</option>
          <option>2027</option>
          <option>2028</option>
          <option>2029</option>
          <option>2030</option>
          <option>2031</option>
          <option>2032</option>
          <option>2033</option>
          <option>2034</option>
          <option>2035</option>
          <option>2036</option>
          <option>2037</option>
          <option>2038</option>
          <option>2039</option>
          <option>2040</option>
          <option>2041</option>
          <option>2042</option>
          <option>2043</option>
          <option>2044</option>
        </Form.Select>
        <Form.Control
          type="Submit"
          id="email_address"
          className="mt-3"
          onClick={handleSubmit}
          disabled={isAllFieldsDisabled}
          style={{
            backgroundColor: "orange",
            height: "50px",
            cursor: isAllFieldsDisabled ? "not-allowed" : "auto",
          }}
        />
      </div>
    </div>
  );
}

export default RegistrationForm;
