import "./partnersPage.css";
const PartnerCard = (props) => {
  const { partnerData } = props;

  return (
    <div className="partner-cards-container d-flex justify-content-center">
      {partnerData.length > 0 ? (
        partnerData.map((partner) => (
          <div key={partner._id} className="partner-card">
            {partner.logo && (
              <img
                src={partner.logo}
                alt="Partner Logo"
                className="partner-logo"
              />
            )}
            <h2 className="partner-name" title={partner.universityName}>
              {partner.universityName}
            </h2>
            <p className="partner-description ">{partner.description}</p>
          </div>
        ))
      ) : (
        <p>No partner data available</p>
      )}
    </div>
  );
};

export default PartnerCard;
