import "./coursesus.css";

function CoursesUS({ courses }) {
  // const Courses = [
  //   {
  //     imageURL: Engineering,
  //     department: "ENGINEERING",
  //     courses: [
  //       "Aerospace Engineering",
  //       "Mechanical Engineering",
  //       "Automation Engineering",
  //       "Civil Engineering",
  //       "Industrial Engineering",
  //       "Nuclear Engineering",
  //       "Chemical Engineering",
  //       "Electrical Engineering",
  //     ],
  //   },
  //   {
  //     imageURL: HealthCare,
  //     department: "Health Care",
  //     courses: [
  //       "Medicine",
  //       "Biomedical Informatics",
  //       "Nursing",
  //       "Public Health",
  //       "Health Administration",
  //       "Nutrition",
  //       "Physical Therapy",
  //       "Sports Medicine",
  //     ],
  //   },
  //   {
  //     imageURL: Business,
  //     department: "Business",
  //     courses: [
  //       "International Management",
  //       "Supply Chain",
  //       "Marketing",
  //       "Project Management",
  //       "Human Resources",
  //       "Finance",
  //       "Digital Marketing & E-Commerce",
  //       "International Tourism",
  //       "Sports Management",
  //     ],
  //   },
  //   {
  //     imageURL: NaturalScience,
  //     department: "Natural Science",
  //     courses: [
  //       "Agricultural Biology",
  //       "Applied Mathematics and Statistics",
  //       "Microbiology",
  //       "Space Studies",
  //       "Neural Engineering",
  //       "Imaging Science",
  //       "Biotechnology",
  //       "Marine Science",
  //       "Genetics",
  //       "Geospatial Sciences",
  //     ],
  //   },
  //   {
  //     imageURL: Design,
  //     department: "Design",
  //     courses: [
  //       "Graphic Design",
  //       "UX/UI Design",
  //       "Fashion Design",
  //       "Interior Design",
  //       "Product Design",
  //       "Animation and Video Game Design",
  //     ],
  //   },
  //   {
  //     imageURL: CreativeArts,
  //     department: "CreativeArts",
  //     courses: [
  //       "Fine Arts",
  //       "Film Studies",
  //       "Photography",
  //       "Performing Arts",
  //       "Animation",
  //       "Culinary Arts",
  //     ],
  //   },
  //   {
  //     imageURL: LiberalArts,
  //     department: "Liberal Arts",
  //     courses: [
  //       "Psychology",
  //       "Sociology",
  //       "Anthropology",
  //       "Political Science",
  //       "Economics",
  //       "History",
  //       "International Relations",
  //       "Gender Studies",
  //       "Environmental Studies",
  //     ],
  //   },
  //   {
  //     imageURL: computerScience,
  //     department: "Computer Science",
  //     courses: [
  //       "Media Applications",
  //       "Game Development",
  //       "Databases & Data Mining",
  //       "Computational Mathematics",
  //       "Natural Language Processing",
  //       "Artificial Intelligence and Robotics",
  //       "Biocomputation",
  //       "Computer & Network Security",
  //     ],
  //   },
  //   {
  //     imageURL: Architecture,
  //     department: "Architecture",
  //     courses: [
  //       "Biodigital Architecture",
  //       "Interior Architecture, Interior Design and Decoration",
  //       "Ephemeral Architecture and Temporary Spaces",
  //       "Landscaping",
  //       "Urbanism Projects",
  //       "Construction Management",
  //     ],
  //   },
  //   {
  //     imageURL: Pharmacy,
  //     department: "Pharmacy",
  //     courses: [
  //       "Pharmacology and Toxicology",
  //       "Pharmaceutical Engineering",
  //       "Pharmaceutics and Drug Delivery",
  //       "Molecular, Cellular and Biochemical Pharmacology",
  //       "Pharmacy Administration",
  //       "Biopharmaceutical Commercialization",
  //       "Pharmaceutical Manufacturing",
  //     ],
  //   },
  //   {
  //     imageURL: LAW,
  //     department: "Law",
  //     courses: [
  //       "Criminal Justice",
  //       "Taxation",
  //       "Comparative and International Law",
  //       "Political Theory and Public Law",
  //       "Environmental and Resource Law",
  //       "Justice, Law and Crime Policy",
  //       "Indigenous Peoples Law and Policy",
  //     ],
  //   },
  // ];
  return (
    <>
      <h2 className="text-center p-2 countryPageHeading">Popular Courses</h2>
      <div className=" d-flex flex-wrap justify-content-center justify-content-md-evenly justify-content-xxl-between m-xl-5 px-xxl-5 ">
        {courses.map((value, index) => {
          return (
            <div
              className="coursesUsCrd"
              style={{ backgroundImage: `url(${value.imageURL})` }}
              key={index + 1}
            >
              <div className="coursesCard-content">
                <h2 className="coursesCard-title">{value.department}</h2>
                <div className="coursesCard-body">
                  {value.courses.map((courses, indexValue) => {
                    return (
                      <ul key={indexValue + 1}>
                        <li>{courses}</li>
                      </ul>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default CoursesUS;
