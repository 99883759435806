import React from "react";
import BlogPage from "../components/Blogpage/BlogPage";

function BlogPageM() {
  return (
    <div>
      <BlogPage />
    </div>
  );
}

export default BlogPageM;
