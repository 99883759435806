import { useEffect, useState, useContext } from "react";
import axios from "axios";
import "./resources.css";
import config from "../../../Boot/config.json";
import Modal from "react-bootstrap/Modal";
import { MdDelete, MdDownload } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import HelmetWrapper from "../../../HelmetWrapper";

import { AuthContext } from "../../../App";

function Resources(props) {
  const { token } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [Resources, setResources] = useState([]);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [Error, setError] = useState("");
  const [formData, setFormData] = useState({
    fileHeading: "",
    resourceFile: null,
    resourceDownload: "",
    imageFile: null,
  });

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Resources`,
    headers: {
      Authorization: token,
    },
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (e) => {
    const { id, value, files } = e.target;
    if (id === "fileType") {
      setFormData((prevData) => ({ ...prevData, [id]: value }));
    } else if (id === "fileHeading") {
      setFormData((prevData) => ({ ...prevData, [id]: value }));
    } else if (id === "resourceFile") {
      const fileName = files[0].name;
      const fileNameWithoutExtension = fileName.substring(
        0,
        fileName.lastIndexOf(".")
      );

      setFormData((prevData) => ({ ...prevData, [id]: files[0] }));
      setFormData((prevData) => ({
        ...prevData,
        fileHeading: fileNameWithoutExtension,
      }));
    } else if (id === "resourceDownloadYes" || id === "resourceDownloadNo") {
      setFormData((prevData) => ({
        ...prevData,
        resourceDownload: value,
      }));
    } else if (id === "imageFile") {
      setFormData((prevData) => ({ ...prevData, [id]: files[0] }));
    }
  };

  const handleDeleteResource = async (e, id) => {
    try {
      await axiosInstance.delete(`/deleteResource/${id}`);
      setDeleteStatus(!deleteStatus);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.fileHeading) {
      setError("File heading cannot be empty");
      return;
    } else if (!formData.resourceFile) {
      setError("Please select valid resource file to upload");
      return;
    }

    const myFormData = new FormData();
    myFormData.append("fileHeading", formData.fileHeading);
    myFormData.append("resourceFile", formData.resourceFile);
    myFormData.append("resourceDownload", formData.resourceDownload);
    myFormData.append("imageFile", formData.imageFile);

    axiosInstance
      .post(`/addResource`, myFormData)
      .then((res) => {
        setShow(false);
        alert("Resource added successfully");
        setFormData("");
        setError("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const fetchResources = async () => {
      try {
        let response;
        response = await axios.get(`${config.baseUrl}/Resources/getResource`);
        setResources(response.data.completeData);
      } catch (error) {
        console.log("Error while fetching data", error);
      }
    };
    fetchResources();
  }, [Resources, deleteStatus]);

  const allResources = Resources;

  return (
    <div className="wr_resources">
      <HelmetWrapper
        title=" Study Abroad Resources: Explore Documents, PDFs, and Presentations | Confluence"
        description="Access valuable study abroad resources including documents, PDFs, presentations, and more. Enhance your knowledge with Confluence's comprehensive collection."
        canonicalUrl="https://www.confluenceedu.com/resources"
      />
      {token && (
        <button
          className="end-0 position-fixed m-3 addResourcesBtn rounded-3"
          onClick={handleShow}
        >
          Add resources
        </button>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add resources</Modal.Title>
        </Modal.Header>
        <form
          className="d-flex p-4 resourceForm justify-content-center flex-column align-items-center"
          onSubmit={handleSubmit}
        >
          <fieldset className="w-100 d-flex flex-column">
            <label htmlFor="fileHeading"> Enter file heading</label>
            <input
              type="text"
              id="fileHeading"
              onChange={handleChange}
              value={formData.fileHeading}
              className="rounded-3 p-2"
            />
            {Error && Error === "File heading cannot be empty" ? (
              <p className="text-danger">{Error} </p>
            ) : (
              ""
            )}
          </fieldset>
          <fieldset className="w-100 d-flex flex-column">
            <label htmlFor="resourceFile">Select File</label>
            <input
              type="file"
              accept="*"
              id="resourceFile"
              onChange={handleChange}
              className="rounded-3 p-2"
            />
            {(Error &&
              Error === "Please select valid resource file to upload") ||
            Error === "Please select a resource file to upload" ? (
              <p className="text-danger">{Error} </p>
            ) : (
              ""
            )}
          </fieldset>
          <fieldset className="w-100 d-flex my-3">
            <label htmlFor="resourceDownload">Downloadable:</label>

            <div className="mx-4">
              <input
                type="radio"
                id="resourceDownloadYes"
                name="resourceDownload"
                value="Yes"
                onChange={handleChange}
              />
              <label htmlFor="resourceDownloadYes">Yes</label>
            </div>

            <div>
              <input
                type="radio"
                id="resourceDownloadNo"
                name="resourceDownload"
                value="No"
                onChange={handleChange}
              />
              <label htmlFor="resourceDownloadNo">No</label>
            </div>
          </fieldset>

          <fieldset className="w-100 d-flex flex-column">
            <label htmlFor="imageFile">Select Image (JPG or PNG)</label>
            <input
              type="file"
              accept="image/jpeg, image/png"
              id="imageFile"
              onChange={handleChange}
              className="rounded-3 p-2"
            />
          </fieldset>
        </form>

        <div className="d-flex justify-content-around">
          <button className=" submitBtn m-2 rounded-3  " onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </Modal>
      <div className="d-flex justify-content-center flex-column mx-auto align-items-center gap-3 wr_rcards">
        {allResources.map((resource, index) => (
          <div
            key={index}
            className="resourceCard d-flex  align-items-center  bg-white"
          >
            <img
              src={`${resource.imageFile}`}
              alt=""
              className="resourceImg d-none d-md-block"
            />
            <div className="d-flex justify-content-between p-2 w-100">
              <div>
                <a
                  className="fileHeading"
                  href={`${resource.resourcesFile}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {resource.fileHeading}
                </a>
                <p className="fileType">
                  {resource.resourcesFile.split(".").pop()}
                </p>
              </div>
              <div className="d-flex justify-content-around align-items-center">
                {token && (
                  <button
                    onClick={(e) => handleDeleteResource(e, resource._id)}
                    className="deleteBtn border-0 bg-white fs-4  px-1"
                  >
                    <MdDelete />
                  </button>
                )}
                <a
                  className="text-dark fs-4 px-1"
                  href={`${resource.resourcesFile}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <FaEye />
                </a>

                {resource.resourceDownload === "Yes" && (
                  <a
                    href={`${resource.resourcesFile}`}
                    rel="noreferrer"
                    download={`${resource.resourcesFile}`}
                    target="_blank"
                    className=" fs-4 px-1"
                  >
                    <MdDownload />
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Resources;
