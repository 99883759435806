import React from "react";
import "./StudentJourney.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SeparatorfallBack from "../../Assets/texticon-1.jpg";
import Separator from "../../Assets/texticon-1.webp";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { PiExamFill } from "react-icons/pi";
import { MdOutlineCelebration, MdSportsSoccer } from "react-icons/md";
import {
  FaCcVisa,
  FaCommentDollar,
  FaPeopleCarry,
  FaRegComments,
} from "react-icons/fa";
import {
  FaNewspaper,
  FaCircleDollarToSlot,
  FaPaintbrush,
} from "react-icons/fa6";

function StudentJourney() {
  const cards = [
    {
      stepName: "Career Counseling",
      stepContent: `Guiding students through a myriad of options to make informed choices aligning with their long-term goals.
     `,
      timelineIcon: <FaRegComments />,
    },
    {
      stepName: "Study Abroad Counseling",
      stepContent: `Offering focused support for students aiming to study abroad, streamlining choices to simplify the decision-making process.
     `,
      timelineIcon: <FaRegComments />,
    },
    {
      stepName: "Test Preparation",
      stepContent: `Online, self-paced test preparation emphasizing individual identity through each test, including IELTS, TOEFL, GRE, SAT, and more.
     `,
      timelineIcon: <PiExamFill />,
    },
    {
      stepName: "Preparation for CV and LOR's",
      stepContent: `Assisting in creating impactful Statements of Purpose (SOPs) and Curriculum Vitae (CVs) crucial for university applications and admissions. 
      Facilitating the collection of recommendation letters and meticulously shortlisting universities to match individual profiles.`,
      timelineIcon: <FaNewspaper />,
    },
    {
      stepName: "Community Services",
      stepContent: ` Participation in community service showcases a well-rounded personality, fostering a favorable impression among admission committees.
    `,
      timelineIcon: <FaPeopleCarry />,
    },
    {
      stepName: "Sports Participation",
      stepContent: ` Active involvement in sports portrays a commitment to fitness and overall well-being, emphasizing a balanced lifestyle.
      Sports participation also showcases discipline, teamwork, resilience, and determination, essential qualities valued by universities.
    `,
      timelineIcon: <MdSportsSoccer />,
    },
    {
      stepName: "Other Extracurricular Activities",
      stepContent: `Extracurricular engagements showcase a diverse range of interests, talents, and hobbies, painting a holistic picture of an applicant beyond academics.
    `,
      timelineIcon: <FaPaintbrush />,
    },
    {
      stepName: "Applications to the universities",
      stepContent: `  This is very critical work by counselors and your choice matching the profile of yours to apply for the right universities to save your time and money.
    `,
      timelineIcon: <HiBuildingOffice2 />,
    },
    {
      stepName: "Get admit",
      stepContent: ` Yes, if you got admissions in more than one university, you have to quickly choose the right one.
    `,
      timelineIcon: <MdOutlineCelebration />,
    },
    {
      stepName: "Apply for Education Loan",
      stepContent: `Education loan is a great help at visa time to prove your funds' management for your studies


    `,
      timelineIcon: <FaNewspaper />,
    },
    {
      stepName: "Collect bank balance statement from parents",
      stepContent: `This is to prove you have sufficient money to live and study

    `,
      timelineIcon: <FaCommentDollar />,
    },
    {
      stepName: "Collect three years IT returns",
      stepContent: `To prove, the sponsors have sufficient funds to take care of your living and study expenses

    `,
      timelineIcon: <FaCircleDollarToSlot />,
    },
    {
      stepName: "Preparation for admissions",
      stepContent: ` Number of activities, the counselor advises you to get prepared in advance.

    `,
      timelineIcon: <FaNewspaper />,
    },
    {
      stepName: "Apply for VISA ",
      stepContent: `Offering comprehensive support in preparing for visa interviews and ensuring all necessary documents are in order for a successful application.
    `,
      timelineIcon: <FaCcVisa />,
    },
    {
      stepName: "Receive visa ",
      stepContent: `Your entry to your dream destination

    `,
      timelineIcon: <FaCcVisa />,
    },
    {
      stepName: "Enroll at the university",
      stepContent: `Assisting students in the final step of the journey, ensuring a smooth transition into their chosen universities and commencement of studies.
    `,
      timelineIcon: <MdOutlineCelebration />,
    },
  ];
  return (
    <div className="timeline mb-lg-5">
      <div>
        <h2 className="text-center mb-3">Our Student Journey</h2>
        <picture>
          <source srcSet={Separator} type="image/webp" />
          <img
            src={SeparatorfallBack}
            alt="separator"
            className="image__separator d-block  my-3 mx-auto"
          />
        </picture>
      </div>
      <div className="cards_container">
        <VerticalTimeline>
          {cards.map((card, index) => {
            return (
              <VerticalTimelineElement
                key={index} // Don't forget to include a unique key for each element
                className="vertical-timeline-element--work"
                iconStyle={{
                  background: "rgb(28, 121, 173)",
                  color: "#fff",
                }}
                icon={card.timelineIcon}
              >
                <h3 className="vertical-timeline-element-title">
                  {card.stepName}
                </h3>
                <p>{card.stepContent}</p>
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      </div>
    </div>
  );
}

export default StudentJourney;
