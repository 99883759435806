import React from "react";
import FeeStructureTables from "../FeeStructureTable";
import Services from "./Services.json";
import GermanyAtoV from "../../../Assets/countryImages/Germany A to V.jpg";
import "./countryImage.css";
function Germany() {
  const StudentServices = [
    {
      service: "Germany Admissions Counseling (Public Universities)",
      Fees: 120000,
      GST: 21600,
      Total: 141600,
    },
    {
      service: "Germany Admissions Counseling (Regular Universities)",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
    {
      service: "Visit Visa Services",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
  ];

  return (
    <div className="bg-white d-flex justify-content-center flex-column align-items-center">
      {/* <h1>Germany</h1> */}

      <img
        src={GermanyAtoV}
        alt="Germany Admission to  Visa"
        className="countryAtoVImg"
      />
      <FeeStructureTables
        countryName={"Germany"}
        studentServices={StudentServices}
      />
    </div>
  );
}

export default Germany;
