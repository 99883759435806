import "./notFound.css";
import PageNotFound from "../../Assets/not.jpg";
function NotFound() {
  return (
    <div className="d-flex flex-column flex-md-row justify-content-around align-items-center bg-white wr_notFound">
      <div>
        <h1>OOPS!</h1>
        <h5>Looks like this page is on vacation.</h5>
        <p>
          Let's redirect it back <a href="/">HOME</a>
        </p>
      </div>
      <div>
        <img src={PageNotFound} alt="Page not found" className="notFoundImg" />
        <p className="credits">Image by storyset on Freepik</p>
      </div>
    </div>
  );
}

export default NotFound;
