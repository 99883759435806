import React from "react";
import Ielts from "../../../Assets/img24.jpg";
import "./pte-images.css";
function PteImages() {
  return (
    <div>
      <a href="https://testprep.confluenceedu.com/">
        <img src={Ielts} alt="PTE" className="pteImg mb-2"></img>
      </a>
    </div>
  );
}

export default PteImages;
