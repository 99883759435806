import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./banner.css";
import NewZealand from "../SVG images/NewZealand";
import USA from "../SVG images/USA";
import { FaSearch } from "react-icons/fa";
import Select from "react-select";
import UK from "../SVG images/UK";
import Australia from "../SVG images/Australia";
import Canada from "../SVG images/Canada";
import Germany from "../SVG images/Germany";
import France from "../SVG images/France";
import Italy from "../SVG images/Italy";
import Netherlands from "../SVG images/Netherlands";
import Ireland from "../SVG images/Ireland";
import Spain from "../SVG images/Spain";
import Switzerland from "../SVG images/Switzerland";

function Banner() {
  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const pathName = location.pathname;

  useEffect(() => {
    const handleClickOutside = (event) => {
      const selectContainer = document.querySelector(".selector__box");

      if (selectContainer && !selectContainer.contains(event.target)) {
        handleBlur();
      }
    };

    if (isActive) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isActive]);

  useEffect(() => {
    if (location.pathname === "/destination") {
      handleFocus();
    }
  }, [pathName]);

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsActive(false);
  };

  const options = [
    {
      value: "United States of America",
      label: "United States of America",
      flag: <USA />,
    },
    { value: "United Kingdom", label: "United Kingdom", flag: <UK /> },
    { value: "Australia", label: "Australia", flag: <Australia /> },
    { value: "Canada", label: "Canada", flag: <Canada /> },
    { value: "Germany", label: "Germany", flag: <Germany /> },
    { value: "France", label: "France", flag: <France /> },
    { value: "Italy", label: "Italy", flag: <Italy /> },
    { value: "Netherlands", label: "Netherlands", flag: <Netherlands /> },
    { value: "New Zealand", label: "New Zealand", flag: <NewZealand /> },
    { value: "Spain", label: "Spain", flag: <Spain /> },
    { value: "Ireland", label: "Ireland", flag: <Ireland /> },
    { value: "Switzerland", label: "Switzerland", flag: <Switzerland /> },
  ];
  function searchCountry() {
    if (selectedOption) {
      // const countryName = selectedOption.label.trim();
      // const urlCountry = encodeURIComponent(countryName);
      const formattedCountry = selectedOption.value.replace(/\s+/g, "-");
      navigate(`/destination/Study-in-${formattedCountry}`);
      // window.location.href = `https://dashboard.confluenceedu.com/universityOpenview/${urlCountry}`;
    }
  }

  return (
    <div className="banner">
      <div className="bannerContent ">
        <h1 className="banner__heading text-white">
          Fulfilling Dreams of Studying Abroad
        </h1>
        <p className="para1 text-white d-xl-none">
          Explore boundless horizons and broaden your education journey by
          studying abroad.
        </p>
        <p className="para2 text-white  d-none d-xl-block ">
          Confluence Educational Services is a dynamic institution committed to
          assisting students in realizing their ambitions of studying abroad.
          Our primary objective is to provide comprehensive guidance and
          assistance throughout the process, ensuring every student receives
          personalized support tailored to their unique aspirations and
          circumstances.
        </p>
      </div>

      <div className="selector__box d-flex align-items-center rounded-5">
        <Select
          className={`selector my-0 me-0 me-md-1 p-1 border-0 selectCountry`}
          options={options}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          formatOptionLabel={(option) => (
            <div className="d-flex align-items-center">
              <div className="px-1">{option.flag}</div>
              <div>{option.label}</div>
            </div>
          )}
          onChange={(option) => setSelectedOption(option)}
          menuIsOpen={isActive}
          styles={{
            control: (provided) => ({
              ...provided,
              height: "50px",
              borderRadius: `25px 25px ${isActive ? "25px 25px" : "25px 25px"}`,
              border: "1px solid #ccc",
              overflow: "hidden",
              marginBottom: 0,
            }),
            menu: (provided) => ({
              ...provided,
              marginTop: -10,
              width: "90%",
              padding: "15px",
              marginLeft: "10px",
              borderRadius: `0 0 ${isActive ? "25px 25px" : "0 0"}`,
              zIndex: -1,
            }),
            option: (provided, state) => ({
              ...provided,
              height: "40px",
              padding: "0px 0px 5px 5px",
              margin: "0px 0px 5px 0px",
            }),
          }}
          placeholder="Choose Your Study Destination"
          isSearchable={true}
          menuPlacement="auto"
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <div>
          <button
            className="search_button rounded-circle border-1 text-white pe-2 pb-1"
            onClick={searchCountry}
          >
            <FaSearch />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Banner;
