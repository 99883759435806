import React, { useEffect, useState, useContext } from "react";
import { Card, Spinner } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import DefaultImage from "../../Assets/MobileBanner.jpg";
import "./blogBox.css";
import LatestBlog from "../Blogpage/LatestBlog";
import ReactPaginate from "react-paginate";
import { GiPin } from "react-icons/gi";
import { RxDrawingPin } from "react-icons/rx";
import { MdDelete } from "react-icons/md";
import config from "../../Boot/config.json";
import { AuthContext } from "../../App";
import SearchComponent from "./SearchComponent";
import FilterComponent from "./FilterComponent";
import HelmetWrapper from "../../HelmetWrapper";
function BlogBox({ draftsMode }) {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    token,
    setPageNum,
    pageNum,
    setPaginNum,
    paginNum,
    setCategoryPage,
    categoryPage,
  } = useContext(AuthContext);

  const [fav, setFav] = useState(false);
  const [data, setData] = useState([]);
  const [categoryDropdown, setCategories] = useState([]);
  const [len, setLen] = useState(1);
  const [currentPage, setCurrentPage] = useState(pageNum);
  const [favCount, setfavCount] = useState(0);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [filStat, setFilStat] = useState(false);
  const [cateMode, setCateMode] = useState({
    mode: false,
    category: "",
  });
  function urlEncoded(title) {
    return title
      .toLowerCase() // Optional: convert to lowercase
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
  }
  const renderCardImage = (imageUrl) => {
    return imageUrl ? (
      <Card.Img
        variant="top"
        src={imageUrl}
        style={{ height: "10rem" }}
        loading="lazy"
      />
    ) : (
      <Card.Img
        variant="top"
        src={DefaultImage}
        style={{ height: "10rem" }}
        loading="lazy"
      />
    );
  };

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Blogs`,
    headers: {
      Authorization: token,
    },
  });

  useEffect(() => {
    // Function to fetch data from the API

    const fetchData = async () => {
      try {
        let response;
        if (draftsMode) {
          response = await axiosInstance.get(`/getDrafts?page=${paginNum}`);
          navigate(`?page=${paginNum}`);
        } else {
          response = await axios.get(
            `${config.baseUrl}/Blogs/getAll?page=${currentPage}`
          );
          setfavCount(response.data.favCount);
        }
        setData(response.data.data);
        setLen(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, [currentPage, draftsMode, fav, deleteStatus, paginNum]);

  useEffect(() => {
    (async () => {
      if (!draftsMode) {
        const categoriesRes = await axios.get(
          `${config.baseUrl}/Blogs/categoryDropdown`
        );
        setCategories(categoriesRes.data.data);
      }
    })();
  }, [categoryDropdown]);

  useEffect(() => {
    navigate(`?page=${pageNum || 1}`);
    setCurrentPage(pageNum || 1);
  }, [pageNum]);

  useEffect(() => {
    // Fetch category-based blogs when cateMode changes
    if (cateMode.mode) {
      const queryParams = new URLSearchParams(location.search);
      const catePageUrl = queryParams.get("page") || 1;
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${config.baseUrl}/Blogs/eachCategoryBlogs/${cateMode.category}?page=${catePageUrl}`
          );
          setData(response.data.data);
          setLen(response.data.totalPages);
        } catch (error) {
          console.error("Error fetching category-based data:", error);
        }
      };

      fetchData();
    } else if (!draftsMode) {
      axios
        .get(`${config.baseUrl}/Blogs/getAll?page=${currentPage}`)
        .then((response) => {
          setfavCount(response.data.favCount);
          setData(response.data.data);
          setLen(response.data.totalPages);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [cateMode.mode, categoryPage]);

  useEffect(() => {
    if (location.pathname === "/protected/drafts") {
    }
  }, [location.pathname, setPageNum]);

  let pageCount = len;
  const handlePageClick = async (selectedPage) => {
    if (draftsMode) {
      navigate(`?page=${selectedPage.selected + 1}`);
      setPaginNum(selectedPage.selected + 1);
    } else if (cateMode.mode) {
      navigate(`?page=${selectedPage.selected + 1}`);
      setCategoryPage(selectedPage.selected + 1);
    } else {
      navigate(`?page=${selectedPage.selected + 1}`);
      setCurrentPage(selectedPage.selected + 1);
      setPageNum(selectedPage.selected + 1);
    }
  };

  const deleteBlog = async (e, id) => {
    try {
      await axiosInstance.delete(`/deleteBlog/${id}`);
      setDeleteStatus(!deleteStatus);
    } catch (err) {
      console.log(err);
    }
  };

  const addTWL = async (e, id, value) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const formData = {
        fav: value,
      };
      if (favCount < 9) {
        await axiosInstance.patch(`/favUnFav/${id}`, formData);
        setFav(!fav);
      } else if (favCount >= 9 && value === false) {
        await axiosInstance.patch(`/favUnFav/${id}`, formData);
        setFav(!fav);
      } else {
        alert("Reached max number of pins");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const disableFilter = (value) => {
    setFilStat(false);
    setCateMode((prevCateMode) => ({
      ...prevCateMode,
      mode: false,
      category: "",
    }));
  };

  const currentPosts = data;

  return (
    <div className="d-block">
      <HelmetWrapper
        title="Confluence Education Blog: Study Abroad Insights and ROI Articles"
        description="Unlock valuable insights with Confluence Education's blog. Discover expert advice on studying abroad and maximizing ROI. Stay informed and plan your educational journey with us."
        conanicalUrl={`https://www.confluenceedu.com/blogs?page=${pageNum}`}
      />

      {!draftsMode && (
        <div className="d-flex w-100 w-md-75 flex-md-row flex-column">
          <SearchComponent />
          <>
            {filStat && (
              <div className=" d-flex  bg-white filterSetUp m-3 m-md-0 ms-md-1">
                <FilterComponent categoryValue={cateMode.category} />
                <div
                  onClick={disableFilter}
                  className="px-2  d-flex text-center align-items-center  bg-dark text-white close "
                >
                  X
                </div>
              </div>
            )}
          </>
        </div>
      )}

      <div className="d-block d-lg-flex flex-row ms-lg-0">
        <div className="main_blog_box d-flex flex-wrap justify-content-md-start me-auto me-lg-0">
          {data.length === 0 ? (
            <Spinner animation="border" />
          ) : (
            currentPosts.map((blog, index) => (
              <div key={blog._id}>
                <div>
                  <Card
                    style={{
                      height: "21rem",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 5px 2px rgba(0,0,0,0.2)",
                    }}
                    className="blog_card position-relative"
                  >
                    {/* Use the blog data for title, content, and imageUrl */}
                    <Card.Body style={{ padding: "0" }}>
                      {token &&
                        !draftsMode &&
                        (blog.fav === true ? (
                          <GiPin
                            className="pin position-absolute z-1 bg-white"
                            onClick={(e) => addTWL(e, blog._id, !blog.fav)}
                          />
                        ) : (
                          <RxDrawingPin
                            className="pin position-absolute z-1 bg-white"
                            onClick={(e) => addTWL(e, blog._id, true)}
                          />
                        ))}

                      {renderCardImage(blog.Image)}

                      <div className="p-2">
                        <p className="date">{blog.publishedOn.slice(10, 35)}</p>
                        <Link
                          to={`/blog/${urlEncoded(blog.mainHeading)}/${
                            blog._id
                          }`}
                          style={{ textDecoration: "none" }}
                          className="card-link"
                        >
                          <Card.Title
                            style={{ marginTop: 0 }}
                            className="jobCardHeading"
                          >
                            {blog.mainHeading.slice(0, 25) + "..."}
                            <span className="toolTip">{blog.mainHeading}</span>
                          </Card.Title>
                        </Link>

                        <Card.Text style={{ fontSize: "14px" }}>
                          <p>
                            <span className="blogPreview">
                              {blog.cardPreview.slice(0, 113)}.....
                            </span>
                            <Link
                              to={`/blog/${urlEncoded(blog.mainHeading)}/${
                                blog._id
                              }`}
                              style={{ textDecoration: "none" }}
                            >
                              <span>Read More</span>
                            </Link>
                          </p>
                        </Card.Text>
                      </div>
                    </Card.Body>
                    {token && (
                      <MdDelete
                        className="deletePin"
                        onClick={(e) => deleteBlog(e, blog._id)}
                      />
                    )}
                  </Card>
                </div>
              </div>
            ))
          )}
        </div>
        {!draftsMode && (
          <div className="mb-3 latestBlogs">
            <h4>Latest Blogs</h4>
            <LatestBlog pinned={fav} AddToWl={addTWL} />
          </div>
        )}
      </div>

      {!draftsMode ? (
        <div className="pagination-container">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination d-flex justify-content-center"}
            activeClassName={"active"}
            forcePage={cateMode.mode ? categoryPage - 1 : currentPage - 1}
          />
        </div>
      ) : (
        <div className="pagination-container">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination d-flex justify-content-center"}
            activeClassName={"active"}
            forcePage={paginNum - 1}
          />
        </div>
      )}
    </div>
  );
}

export default BlogBox;
