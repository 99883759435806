import React from "react";
import "./ieltsContent.css";

function IeltsContent() {
  return (
    <div>
      <div className="ielts_content p-2">
        <h1 className="ContentHeading">About IELTS</h1>
        <h5 className="SubHeading">What is IELTS??</h5>
        <p className="para_content">
          <strong style={{ fontSize: "30px" }}>I</strong>ELTS (International
          English Language Testing System) is an internationally administered
          test of English language, established in 1989. Today, it is co-managed
          by Cambridge English Language Assessment and IDP Australia.
        </p>
        <h5 className="SubHeading">What IELTS is tested?</h5>
        <p className="para_content">
          This test checks the ability or efficiency of a non-native English
          language speaking candidate who wishes to apply for student visa,
          permanent visa or work visa for an English speaking country. The four
          basic linguistic skills – listening, reading, writing and speaking are
          assessed in English.
        </p>
        <h5 className="SubHeading">How is IELTS scored?</h5>
        <p className="para_content">
          IELTS assesses a candidate on the four basic linguistic skills of
          Listening, Reading, Writing and Speaking. Each of these skills are
          scored out of 9 bands, and your final score will be the rounded off
          average of these four scores.
        </p>
      </div>
    </div>
  );
}

export default IeltsContent;
