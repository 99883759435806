import { React, useContext, useState, useRef, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import "./imageEditor.css";
import axios from "axios";
import config from "../../../Boot/config.json";
import { AuthContext } from "../../../App";

function ImageEditor({
  showModal,
  handleClose,
  btnChangeV,
  addC,
  addCStat,
  singleCour,
  editButton,
  countriesDD,
  countryhandler,
}) {
  const [altText, setAltText] = useState("");
  const [description, setDescription] = useState("");
  const [university, setUniversity] = useState("");
  const [country, setCountry] = useState("");
  const [whatsNext, setWhatsNext] = useState("");
  const [whyThisCour, setWhyThisCour] = useState("");
  const [abtCourse, setAbtCourse] = useState("");
  const [courseImg, setCourseImg] = useState(null);
  const { token } = useContext(AuthContext);
  const fileInputRef = useRef(null);

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/DisplayCV`,
    headers: {
      Authorization: token,
    },
  });
  useEffect(() => {
    setAltText(singleCour.alt);
    setDescription(singleCour.description);
    setUniversity(singleCour.universityName);
    setCountry(singleCour.country);
    setAbtCourse(singleCour.aboutCourse);
    setWhatsNext(singleCour.whatsNext);
    setWhyThisCour(singleCour.whyThisCourse);
  }, [
    singleCour.alt,
    singleCour.description,
    singleCour.universityName,
    singleCour.country,
    singleCour.aboutCourse,
    singleCour.whyThisCourse,
    singleCour.whatsNext,
  ]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (e.target.name === "alt") {
      setAltText(value);
    }
    if (e.target.name === "description") {
      setDescription(value);
    }
    if (e.target.name === "university") {
      setUniversity(value);
    }
    if (e.target.name === "country") {
      setCountry(value);
    }
    if (e.target.name === "whatsNext") {
      setWhatsNext(value);
    }

    if (e.target.name === "whyThisCour") {
      setWhyThisCour(value);
    }

    if (e.target.name === "abtCourse") {
      setAbtCourse(value);
    }
  };
  const handleImageChange = (e) => {
    const image = e.target.files[0];
    setCourseImg(image);
  };

  const handleCourse = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", courseImg);
    formData.append("alt", altText);
    formData.append("university", university);
    formData.append("country", country);
    formData.append("whatsNext", whatsNext);
    formData.append("abtCourse", abtCourse);
    formData.append("whyThisCour", whyThisCour);
    try {
      if (
        (abtCourse ? abtCourse.length : 0) +
          (whyThisCour ? whyThisCour.length : 0) +
          (whatsNext ? whatsNext.length : 0) <=
        450
      ) {
        axiosInstance
          .post(`/addCourse`, formData)
          .then((response) => {
            alert("Course added sucessfully");
            addC(!addCStat);
            resetFileInput();
            setAltText("");
            setDescription("");
            setCountry("");
            setUniversity("");
            setWhatsNext("");
            setAbtCourse("");
            setWhyThisCour("");
            handleClose();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditCourse = (e, id) => {
    e.preventDefault();
    try {
      if (
        (abtCourse ? abtCourse.length : 0) +
          (whyThisCour ? whyThisCour.length : 0) +
          (whatsNext ? whatsNext.length : 0) <=
        450
      ) {
        const formData = new FormData();
        formData.append("alt", altText);
        formData.append("image", courseImg);
        formData.append("university", university);
        formData.append("country", country);
        formData.append("whatsNext", whatsNext);
        formData.append("aboutCourse", abtCourse);
        formData.append("whyThisCourse", whyThisCour);
        const queryString = JSON.parse(localStorage.getItem("searchCouUni"));
        axiosInstance
          .patch(`/editCourse/${id}`, formData)
          .then((response) => {
            alert("Data suessfully updated");
            handleClose();
            countryhandler(e, queryString);
          })
          .catch((err) => {});
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleVisit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", courseImg);
    formData.append("alt", altText);
    formData.append("description", description);

    try {
      axiosInstance
        .post(`/addVisit`, formData)
        .then((response) => {
          alert("Visit added sucessfully");
          addC(!addCStat);
          resetFileInput();
          setAltText("");
          handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add an Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="otpInput" className="mb-3">
            <Form.Label>
              Select Image File <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              ref={fileInputRef}
              type="file"
              accept="/images*"
              name="image"
              onChange={handleImageChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Enter Alternative Text <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="alternative text"
              name="alt"
              value={altText}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          {btnChangeV === true ? (
            <Form.Group>
              <Form.Label>
                Description <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Few words about the image"
                name="description"
                value={description}
                required
                onChange={handleInputChange}
              />
            </Form.Group>
          ) : (
            <></>
          )}
          {btnChangeV === true ? (
            <></>
          ) : (
            <>
              <Form.Group>
                <Form.Label className="pt-2">
                  University <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter University Name"
                  name="university"
                  value={university}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </>
          )}
          {btnChangeV === true ? (
            <></>
          ) : (
            <>
              <Form.Group>
                <Form.Label className="pt-2">
                  Country <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="country"
                  required
                  value={
                    countriesDD.includes(country) ? country : "Choose Country"
                  }
                  onChange={handleInputChange}
                >
                  <option value="Choose Country" disabled>
                    Choose Country
                  </option>
                  {countriesDD.map((eachCountry) => (
                    <option key={eachCountry} value={`${eachCountry}`}>
                      {eachCountry}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </>
          )}

          {btnChangeV === true ? (
            <></>
          ) : (
            <>
              <Form.Group>
                <div className="text-danger">
                  Total Characters : &nbsp;
                  {(abtCourse ? abtCourse.length : 0) +
                    (whyThisCour ? whyThisCour.length : 0) +
                    (whatsNext ? whatsNext.length : 0)}
                  /450
                  {(abtCourse ? abtCourse.length : 0) +
                    (whyThisCour ? whyThisCour.length : 0) +
                    (whatsNext ? whatsNext.length : 0) >
                  450 ? (
                    <>
                      <p className="text-danger">
                        Please enter 450 characters or below
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                <Form.Label className="pt-1">About Course -&nbsp;</Form.Label>
                <span className="text-danger ">
                  characters : {abtCourse ? abtCourse.length : 0}
                </span>
                <Form.Control
                  as="textarea"
                  placeholder="Enter few words about course"
                  name="abtCourse"
                  value={abtCourse}
                  onChange={handleInputChange}
                  className="abtCourse"
                />
              </Form.Group>
            </>
          )}

          {btnChangeV === true ? (
            <></>
          ) : (
            <>
              <Form.Group>
                <Form.Label className="pt-3">
                  Why this course? - &nbsp;
                </Form.Label>
                <span className="text-danger">
                  characters : {whyThisCour ? whyThisCour.length : 0}
                </span>
                <Form.Control
                  as="textarea"
                  placeholder="Enter few words"
                  name="whyThisCour"
                  value={whyThisCour}
                  onChange={handleInputChange}
                  className="whyThisCour"
                />
              </Form.Group>
            </>
          )}

          {btnChangeV === true ? (
            <></>
          ) : (
            <>
              <Form.Group>
                <Form.Label className="pt-3">Whats next? - &nbsp;</Form.Label>
                <span className="text-danger">
                  characters : {whatsNext ? whatsNext.length : 0}
                </span>
                <Form.Control
                  as="textarea"
                  placeholder="Enter few words"
                  name="whatsNext"
                  value={whatsNext}
                  onChange={handleInputChange}
                  className="whatsNext"
                />
              </Form.Group>
            </>
          )}

          {editButton === true ? (
            <>
              <Button
                variant="primary"
                className="mt-2"
                onClick={(e) => handleEditCourse(e, singleCour._id)}
              >
                Submit
              </Button>
            </>
          ) : (
            <>
              {btnChangeV === true ? (
                <Button
                  variant="primary"
                  className="mt-2"
                  onClick={handleVisit}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="mt-2"
                  onClick={handleCourse}
                >
                  Submit
                </Button>
              )}
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default ImageEditor;
