import React from "react";
import "./toeflContent.css";

function ToeflContent() {
  return (
    <div>
      <div className="toefl_content p-2">
        <h1 className="mt-1">About TOEFL</h1>
        <h5>What is TOEFL?</h5>
        <p className="para_content">
          <strong style={{ fontSize: "30px" }}>T</strong>OEFL (Test of English
          as a Foreign Language) is a standardized test designed to assess the
          English language proficiency of non-native English speakers, primarily
          for academic purposes. It's widely accepted by universities and
          institutions worldwide as part of their admissions process.
        </p>
        <h5>How TOEFL Works:</h5>
        <p className="para_content">
          The test evaluates four key language skills—Reading, Listening,
          Speaking, and Writing. It's conducted in both internet-based (iBT) and
          paper-based (PBT) formats. iBT includes integrated tasks that reflect
          real-life academic situations, while PBT assesses similar skills but
          has a different format.
        </p>
        <h5>Why Prepare with Us:</h5>
        <p className="para_content">
          Our TOEFL preparation courses offer comprehensive guidance and
          resources tailored to help individuals excel in each test section.
          Through expert-led sessions, practice tests, and targeted strategies,
          we aim to equip candidates with the skills and confidence needed to
          achieve their desired scores and succeed in their academic pursuits
          abroad.
        </p>
      </div>
    </div>
  );
}

export default ToeflContent;
