import { useEffect, useState, useRef, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../Boot/config.json";
import JoditEditor from "jodit-react";
import { Modal, Button } from "react-bootstrap";
import "./serviceDescripton.css";
import { FaEdit } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";

//import token
import { AuthContext } from "../../../App";

function ServiceDescription() {
  const editor = useRef(null);
  const navigate = useNavigate();
  const { token, setShowLogin, navState } = useContext(AuthContext);
  const { serviceId } = useParams();

  const userName = localStorage.getItem("userName");
  const userId = localStorage.getItem("userId");

  const [showModal, setShowModal] = useState(false);
  const [paymentGw, setPaymentGW] = useState(false);
  const [submit, setSubmit] = useState(false);

  //description data
  const [data, setData] = useState({
    serviceName: "",
    countryName: "",
    description: "",
    fee: 0,
    gst: 0,
    total: 0,
  });

  const [formData, setFormData] = useState({
    serviceName: "",
    countryName: "",
    description: "",
    fee: 0,
    gst: 0,
    total: 0,
  });

  const [transactionDetails, setTransactionDetails] = useState("");

  // Close the modal
  const handleClose = () => {
    setShowModal(false);
    setPaymentGW(false);
    setFormData({
      serviceName: data.serviceName,
      countryName: data.countryName,
      description: data.description,
      fee: data.fee,
      gst: data.gst,
      total: data.total,
    });
    // setShowLogin(false);
  };

  const handleBack = () => {
    const formattedCountry = data.countryName.replace(/\s+/g, "-");
    navigate(`/destination/Study-in-${formattedCountry}`);
  };

  // Open the modal for editing description
  const handleEditDescription = () => {
    setShowModal(true);
  };

  // Handle content change in the editor
  const handleContentChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };
  const handleDescriptionChange = (newDescription) => {
    setFormData((prev) => ({ ...prev, description: newDescription }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { serviceName, fee, gst, total } = formData;
    if (!serviceName || !fee || !gst || !total) {
      alert("Please fill all the fields");
    } else {
      axios
        .put(
          `${config.baseUrl}/CountryInfo/updateServiceInfo`,

          {
            id: data._id,
            data: formData,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          setSubmit(!submit);

          console.log("Description updated succesfully");
          handleClose();
        })
        .catch((error) => {
          console.error("Error updating description:", error);
        });
    }
  };

  const handleForm = async () => {
    if (navState) {
      try {
        const formData = {
          amount: data.total,
          productInfo: data.serviceName,
          userId: userId,
        };

        await axios
          .post(`${config.dashBoardUrl}/generateHash`, formData)
          .then((res) => {
            setTransactionDetails(res.data);
            setPaymentGW(true);
          })
          .catch((err) => {
            console.log("Avail Services Error---", err);
          });
      } catch (error) {
        console.log("Avail Services Error---", error);
      }
    } else {
      setShowLogin(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios
          .get(`${config.baseUrl}/CountryInfo/getServiceInfo`, {
            params: {
              serviceId: serviceId,
            },
          })
          .then((res) => {
            setData(res.data.data);
          })
          .catch((err) => {
            setData({
              _id: "",
              serviceName: "No service found",
              description: "",
              fee: "",
              gst: "",
              total: "",
            });

            console.log(err);
          });
      } catch (error) {
        console.log("Error fetching service info");
      }
    };
    fetchData();
  }, [submit]);

  useEffect(() => {
    setFormData({
      serviceName: data.serviceName,
      countryName: data.countryName,
      description: data.description,
      fee: data.fee,
      gst: data.gst,
      total: data.total,
    });
  }, [data]);

  return (
    <div className="wr_serviceDescription">
      <button
        onClick={handleBack}
        className="backToBlog py-1 px-2 text-decoration-none position-fixed text-center d-none d-md-inline"
      >
        <FaArrowLeftLong className="me-1" />
      </button>
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
        <div className="rounded-2 serviceDesCard position-relative bg-white p-3 p-md-4 p-lg-5 mt-md-3">
          {token && (
            <button
              className="position-absolute d-none d-md-block border-0 bg-white p-2 rounded-2 top-0 end-0 m-2 mb-2"
              onClick={handleEditDescription}
            >
              <FaEdit className="fs-4" /> <span>Edit</span>
            </button>
          )}

          <h1 className="serviceHeading">{data.serviceName}</h1>
          {data.description ? (
            <div
              dangerouslySetInnerHTML={{ __html: data.description }}
              className="serviceContent"
            />
          ) : (
            <p>No description found</p>
          )}
        </div>
        <div className="rounded-2 serviceFeeCard fs-6 bg-white mt-3">
          <h3 className="serviceHeading mb-2">Fee Details</h3>
          <p className="mb-2 d-flex justify-content-between">
            <span className="">Fees</span> <span>₹ {data.fee}</span>
          </p>
          <p className="mb-2 d-flex justify-content-between">
            <span className="">GST</span>
            <span>₹ {data.gst}</span>
          </p>
          <div className="mb-2 d-flex border-top border-1 mt-3 justify-content-between">
            <p className="mt-2">Total</p> <p className="mt-2">₹ {data.total}</p>
          </div>
          <button
            className="w-100 mt-3 BuyNowBtn rounded-2 py-2 text-white border-0"
            onClick={handleForm}
          >
            Avail Service
          </button>
        </div>

        <Modal show={showModal} onHide={handleClose} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Edit Service Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <fieldset className="d-flex flex-column">
                <label htmlFor="serviceName">Service Name</label>
                <input
                  type="text"
                  id="serviceName"
                  className="p-2 rounded-2 w-50"
                  onChange={handleContentChange}
                  placeholder="Enter Service Name"
                  value={formData.serviceName}
                />
              </fieldset>
              <fieldset className="d-flex flex-column">
                <label htmlFor="serviceName">Fee Amount</label>
                <input
                  type="number"
                  id="fee"
                  placeholder="Enter Fee"
                  className="p-2 rounded-2 w-50"
                  value={formData.fee}
                  onChange={handleContentChange}
                />
              </fieldset>
              <fieldset className="d-flex flex-column">
                <label htmlFor="gst">GST</label>
                <input
                  type="number"
                  id="gst"
                  placeholder="Enter GST"
                  className="p-2 rounded-2 w-50"
                  value={formData.gst}
                  onChange={handleContentChange}
                />
              </fieldset>
              <fieldset className="d-flex flex-column">
                <label htmlFor="total">Total</label>
                <input
                  type="number"
                  id="total"
                  placeholder="Enter Total"
                  className="p-2 rounded-2 w-50"
                  value={formData.total}
                  onChange={handleContentChange}
                />
              </fieldset>
              <JoditEditor
                ref={editor}
                config={config}
                id="content"
                name="content"
                className="mt-3"
                value={formData.description || ""}
                onChange={handleDescriptionChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={paymentGw} onHide={handleClose} size="md">
          <Modal.Header closeButton>
            <h3>Payment Details</h3>
          </Modal.Header>
          <div className="paymentCard d-flex flex-column justify-content-center">
            <p className="d-flex justify-content-between fw-medium">
              <span>Name</span> <span>{userName}</span>
            </p>
            <p className="d-flex justify-content-between fw-medium">
              <span>Email</span> <span>{transactionDetails.email}</span>
            </p>
            <p className="d-flex justify-content-between fw-medium">
              <span>Service</span> <span>{data.serviceName}</span>
            </p>
            <p className="d-flex justify-content-between fw-medium">
              <span>Service Fee</span> <span>₹ {data.fee}</span>
            </p>
            <p className="d-flex justify-content-between fw-medium">
              <span>GST</span> <span>₹ {data.gst}</span>
            </p>
            <p className="d-flex justify-content-between mt-2 fw-bold fs-6 border-top border-secondary pt-3">
              <span>Grand Total</span> <span>₹ {data.total}</span>
            </p>
            {transactionDetails && (
              <div>
                <form
                  action="https://secure.payu.in/_payment"
                  method="post"
                  className="d-flex justify-content-center mt-3"
                >
                  <input
                    type="hidden"
                    name="key"
                    value={transactionDetails.key}
                  />
                  <input
                    type="hidden"
                    name="txnid"
                    value={transactionDetails.transactionId}
                  />
                  <input
                    type="hidden"
                    name="productinfo"
                    value={transactionDetails.productInfo}
                  />
                  <input
                    type="hidden"
                    name="amount"
                    value={transactionDetails.amount}
                  />
                  <input
                    type="hidden"
                    name="email"
                    value={transactionDetails.email}
                  />
                  <input
                    type="hidden"
                    name="firstname"
                    value={transactionDetails.name}
                  />
                  <input
                    type="hidden"
                    name="lastname"
                    value={
                      transactionDetails.lastName
                        ? transactionDetails.lastName
                        : "None"
                    }
                  />
                  <input
                    type="hidden"
                    name="surl"
                    value="https://dashboard.confluenceedu.com:8000/webPayment"
                  />
                  <input
                    type="hidden"
                    name="furl"
                    value="https://dashboard.confluenceedu.com:8000/webPayment"
                  />
                  <input
                    type="hidden"
                    name="phone"
                    value={transactionDetails.phone}
                  />
                  <input
                    type="hidden"
                    name="hash"
                    value={transactionDetails.hash}
                  />
                  <input
                    type="hidden"
                    name="udf1"
                    value={transactionDetails.userId}
                  />
                  <input
                    type="hidden"
                    name="address1"
                    value={transactionDetails.productInfo}
                  />
                  <input
                    type="submit"
                    value="Proceed to payment"
                    className="BuyNowBtn p-2 border-0 rounded-2 fw-medium text-white"
                  />
                </form>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
}
export default ServiceDescription;
