import React from "react";
import Destination from "../components/Study Abroad/DestinationPage";
import HelmetWrapper from "../HelmetWrapper";

function DestinationPage() {
  return (
    <div>
      <HelmetWrapper
        title="Study Abroad - USA,UK,Canada,Italy,Spain,Germany,Ireland | Confluence Education"
        description="Discover the advantages of studying abroad for Indian students, including global exposure, quality education, career opportunities, personal development, and language proficiency enhancement."
        canonicalUrl="https://www.confluenceedu.com/destination"
      />

      <Destination />
    </div>
  );
}

export default DestinationPage;
