import React, { useEffect } from "react";
import "./whatwedo.css";
import Separator from "../../Assets/texticon-1.webp";
import separatorFallBack from "../../Assets/texticon-1.jpg";

import Icon1 from "../../Assets/Visa-Applications.webp";
import Icon1Fallback from "../../Assets/Visa-Applications.jpg";
import Icon2 from "../../Assets/Financial-Opportunities.webp";
import Icon2Fallback from "../../Assets/Financial-Opportunities.jpg";
import Icon3 from "../../Assets/Interview-Preparation.webp";
import Icon3Fallback from "../../Assets/Interview-Preparation.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

function Whatwedo() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  const cards = [
    {
      stepName: "Counseling",
      stepContent: `Personalized counseling sessions serve as compasses, aligning academic backgrounds with career aspirations to guide individuals towards suitable study paths abroad. Seasoned counselors provide tailored advice, empowering informed choices for a fulfilling academic journey. These sessions delve into students' ambitions and strengths, fostering clarity and confidence in decision-making for their future endeavors.
     `,
      timelineIcon: Icon1,
      fallbackIcon: Icon1Fallback,
    },
    {
      stepName: "Assistance",
      stepContent: `Dedicated support helps students navigate the complex landscape of higher education by meticulously shortlisting fitting universities and exploring available scholarships. This assistance aims to ease the financial burdens associated with studying abroad, ensuring deserving candidates access quality education without significant financial constraints.
     `,
      timelineIcon: Icon1,
      fallbackIcon: Icon1Fallback,
    },
    {
      stepName: "Mentoring",
      stepContent: `Ongoing mentorship from experienced professionals offers a lifeline as students embark on their journey of studying abroad. These mentors provide comprehensive support, addressing academic, cultural, and personal adjustments, ensuring a smooth transition and fostering success in a new educational environment.
     `,
      timelineIcon: Icon1,
      fallbackIcon: Icon1Fallback,
    },
    {
      stepName: "Test Preparation",
      stepContent: `Structured and intensive coaching programs equip students with robust strategies, ample practice, and simulated exams essential for excelling in standardized tests. These comprehensive preparations enhance their confidence, familiarize them with test formats, and optimize their performance on examination day.
    `,
      timelineIcon: Icon2,
      fallbackIcon: Icon2Fallback,
    },
    {
      stepName: "University Applications",
      stepContent: `Guided by expert assistance, students navigate through the intricate process of university applications, ensuring the accurate and timely completion of all necessary documentation. This support aids in presenting a compelling and well-rounded profile to universities, increasing the chances of securing admission to desired institutions.
    .`,
      timelineIcon: Icon3,
      fallbackIcon: Icon3Fallback,
    },
    {
      stepName: "Visa Preparation",
      stepContent: `Thorough guidance readies students for visa applications and interviews, ensuring a seamless transition. Equipping them with essential documentation and interview strategies, it simplifies the visa process. This pivotal support fosters confidence in students, enabling them to present themselves effectively during interviews.
    `,
      timelineIcon: Icon1,
      fallbackIcon: Icon1Fallback,
    },
  ];
  return (
    <div
      className="timeline mb-5  position-relative overflow-x-hidden"
      id="timeline_services"
    >
      <div>
        <h2 className="text-center mb-3">
          Timeline To Enroll At The University
        </h2>
        <picture>
          <source srcSet={Separator} type="image/webp" />
          <img
            src={separatorFallBack}
            alt="separator"
            className="image__separator d-block  my-3 mx-auto"
          />
        </picture>
      </div>
      <div className="cards_container d-flex flex-column align-items-center">
        {cards.map((card, index) => {
          const containerClass = "whatwedo_container ";
          const positionClass =
            index % 2 === 0 ? "containerleft" : "containerright";
          const combinedClass = `${containerClass} ${positionClass}`;
          const animation = index % 2 === 0 ? "fade-right" : "fade-left";
          return (
            <div
              className={combinedClass}
              id={`card-${index}`}
              key={index}
              data-aos={animation}
              data-aos-offset={50}
            >
              <picture>
                <source srcSet={card.timelineIcon} type="image/webp" />
                <img
                  src={card.fallbackIcon}
                  alt="time line icons"
                  className="icon_image"
                />
              </picture>

              <div className="textbox">
                <h2 className="heading">{card.stepName}</h2>
                <p className="content">{card.stepContent}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Whatwedo;
