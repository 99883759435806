import React from "react";
import SatContent from "../components/Test prep/Sat/SatContent";
import SatImages from "../components/Test prep/Sat/Sat-images";
import TestBanner from "../components/Test prep/TestBanner";
import HelmetWrapper from "../HelmetWrapper";

function Sat() {
  return (
    <>
      <HelmetWrapper
        title="SAT Exam Overview | Confluence Education"
        description="Learn about the SAT exam structure, duration, and significance for college admissions. Prepare effectively with Confluence Education's specialized SAT courses."
        canonicalUrl="https://www.confluenceedu.com/sat"
      />
      <TestBanner SAT={"SAT"} />
      <SatContent />
      <SatImages />
    </>
  );
}

export default Sat;
