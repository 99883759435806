import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";

const OTPScreen = ({
  showModal,
  handleClose,
  disabledFields,
  userEmail,
  verifyButton,
}) => {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = () => {
    try {
      const FormData = {
        otp: otp,
        email: userEmail,
      };
      axios
        .post(
          "https://www.confluenceedu.com:8080/FormData/OTPVerification",
          FormData
        )
        .then((response) => {
          setOtp("");
          handleClose(false);
          disabledFields(false);
          verifyButton("Verified");
          setOtpError("");
        })
        .catch((error) => {
          setOtpError(error.response.data);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>OTP Verification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="otpInput">
            <Form.Label>Enter OTP:</Form.Label>
            <Form.Control
              type="text"
              placeholder='eg:"5874"'
              value={otp}
              onChange={handleOtpChange}
              maxLength={4}
            />
            <p className="text-danger">{otpError}</p>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Verify
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OTPScreen;
