import React from "react";
import "./ourStory.css";
import HelmetWrapper from "../../HelmetWrapper";

function OurStory1() {
  return (
    <>
      <HelmetWrapper
        title="Our Story - Confluence Education"
        description="Confluence Educational Services began its journey with a vision to bridge the gap between aspiring students and world-class educational opportunities."
        canonicalUrl="https://www.confluenceedu.com/OurStory"
      />
      <div className="our_story p-2">
        <h1>Our Story</h1>
        <p>
          <strong style={{ fontSize: "30px", fontWeight: "300" }}>E</strong>
          stablished in 2005, Confluence Educational Services began its journey
          with a vision to bridge the gap between aspiring students and
          world-class educational opportunities. Over the years, we have evolved
          into a trusted and reputable institution, known for our unwavering
          commitment to student success and satisfaction.
        </p>
        <br />

        <h5>
          <strong>Services Offered by Confluence</strong>
        </h5>

        <p>
          At Confluence Educational Services, we offer a wide range of services
          designed to cater to the diverse needs of our students. From study
          abroad consulting to university admissions assistance, we provide
          comprehensive support at every step of the journey. Our services
          extend to various destination countries, including the USA, Canada,
          UK, Ireland, Australia, New Zealand, Germany, France, Italy, Spain,
          the Netherlands, and Switzerland.
        </p>
        <br />

        <h5>
          <strong>Achievements and Track Record</strong>
        </h5>
        <p>
          Our success stories speak volumes about the efficacy of our services.
          Over the years, we have helped numerous students achieve their
          academic and professional goals by securing admissions in top-tier
          universities around the world. The testimonials of our satisfied
          students stand as a testament to the quality and effectiveness of our
          guidance and support
        </p>
        <br />

        <h5>
          <strong>Why Choose Confluence</strong>
        </h5>
        <p>
          What sets Confluence Educational Services apart is our personalized
          approach and comprehensive support system. We understand that every
          student is unique, and we strive to tailor our services to meet their
          individual needs and aspirations. Whether it's assisting with
          university applications, visa procedures, or accommodation
          arrangements, we go above and beyond to ensure a smooth and
          hassle-free experience for our students.
        </p>
        <br />
        <h5>
          <strong>Global Reach and Partnerships</strong>
        </h5>
        <p>
          Through our extensive network of partnerships with universities and
          educational institutions worldwide, we provide our students with
          access to a diverse range of opportunities and resources. Our
          collaborations enable us to offer exclusive benefits and scholarships,
          further enhancing the prospects of our students and enriching their
          academic experience.
        </p>
        <br />
        <h5>
          <strong>Future Prospects and Goals</strong>
        </h5>
        <p>
          Looking ahead, we remain committed to expanding our reach and
          enhancing our services to better serve the needs of our students. We
          aim to continue our journey of growth and innovation, striving to
          empower more students to pursue their aspirations and achieve success
          on a global scale.
        </p>
        <br />

        <h5>
          <strong>Conclusion</strong>
        </h5>
        <p>
          In conclusion, Confluence Educational Services stands as a beacon of
          hope and opportunity for students aspiring to study abroad. With our
          unwavering commitment to excellence and our comprehensive range of
          services, we are dedicated to empowering students to fulfill their
          dreams and embark on a journey of academic and personal growth.
        </p>
        <br />
      </div>
    </>
  );
}

export default OurStory1;
