import { useContext, useEffect } from "react";
import UserLoginPage from "../components/userLoginPage/UserLoginPage";
import { AuthContext } from "../App";
function UserLogin() {
  const { setShowLogin, showLogin } = useContext(AuthContext);

  //   useEffect(() => {
  //     setShowLogin(true);
  //   }, []);
  return (
    <div
      className=""
      style={{
        minHeight: "600px",
      }}
    >
      <UserLoginPage LoginState={true} />
    </div>
  );
}

export default UserLogin;
