import React from "react";
import "./studyAbroadDes.css";

function StudyAbroadDes() {
  return (
    <div className="Page_main_block  px-4 p-2 p-md-4">
      <h1 className="page1_heading mt-1">Why study Abroad??</h1>
      <p>
        Studying abroad offers numerous advantages for Indian students, ranging
        from academic and personal growth to career opportunities. Here are some
        key reasons why studying abroad is beneficial for Indian students:
      </p>
      <ul>
        <li>
          <strong>Global Exposure:</strong> Studying abroad exposes students to
          diverse cultures, languages, and perspectives, fostering a global
          mindset. This experience enhances intercultural communication skills
          and prepares students for an increasingly interconnected world.
        </li>
        <li>
          <strong>Quality Education:</strong> Many countries renowned for their
          education systems offer high-quality academic programs and
          cutting-edge research facilities. Access to world-class faculty and
          resources can significantly enhance the learning experience.
        </li>
        <li>
          <strong>Career Oppurtunity:</strong> An international degree often
          enhances employability. Employers value the adaptability,
          independence, and cross-cultural skills gained through studying
          abroad. Networking opportunities and internships in a global setting
          can boost a student's professional prospects.
        </li>
        <li>
          <strong>Personal Development:</strong> Living in a different country
          challenges individuals to step out of their comfort zones, promoting
          personal growth, independence, and resilience. It helps develop
          problem-solving skills and adaptability to diverse environments.
        </li>
        <li>
          <strong>Language Proficiency: </strong>Immersing oneself in a country
          where the primary language is different improves language skills
          significantly. This can be a valuable asset in an increasingly
          multilingual job market.
        </li>
      </ul>
    </div>
  );
}

export default StudyAbroadDes;
