import React from "react";
import CareerDescription from "../components/CareerPage/CareerDescription";

function JobDescription() {
  return (
    <div>
      <CareerDescription />
    </div>
  );
}

export default JobDescription;
