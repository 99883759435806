import React from "react";
import "./studyAbroadBanner.css";

function StudyAbroadBanner() {
  return (
    <div className="studyAbroadBanner">
      <h3 className="studyAbroad_Heading d-none d-md-block ">
        Learning Beyond Horizons
      </h3>
    </div>
  );
}

export default StudyAbroadBanner;
