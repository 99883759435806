import React, { useContext, useState, useEffect, useRef } from "react";
import { Form, FormControl, Button, InputGroup } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import BlogPage from "../Blogpage/BlogPage";
import "./postEditor.css";
import JoditEditor from "jodit-react";
import { AuthContext } from "../../App";
import axios from "axios";
import config from "../../Boot/config.json";
import { FaArrowLeftLong } from "react-icons/fa6";
import { TiDelete } from "react-icons/ti";

function PostEditor({
  editHeading,
  editImage,
  editCategory,
  editDescription,
  editPreview,
  editStatus,
}) {
  let { id } = useParams();
  const seoBlogId = id;
  const navigation = useNavigate();
  const [heading, setHeading] = useState("");
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState("");
  const [cardPreview, setCardPreview] = useState("");
  const [description, setDescription] = useState("");
  const [showBlog, setShowBlog] = useState(false);
  const { token, pageNum } = useContext(AuthContext);
  const [editImgStatus, setImgEditStatus] = useState(editStatus);
  const [dropdownOpt, setDropdownOpt] = useState([]);
  const [Categoryfield, setCategoryfield] = useState();
  const [editCategoryStatus, setEditCategoryStatus] = useState(false);
  const [fileError, setFileError] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [seoDetails, setSeoDetails] = useState({
    seoTitle: "",
    slug: "",
    metaDescription: "",
    seoActive: false,
  });

  const editor = useRef(null);

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Blogs`,
    headers: {
      Authorization: token,
    },
  });

  useEffect(() => {
    setHeading(editHeading);
    setImage(editImage);
    setCategory(editCategory);
    setDescription(editDescription);
    setCardPreview(editPreview);
  }, [editHeading, editImage, editCategory, editDescription, editPreview]);

  useEffect(() => {
    try {
      axiosInstance
        .get("/categoryDropdown")
        .then((response) => {
          setDropdownOpt(response.data.data);
        })
        .catch(() => {});
    } catch (err) {
      console.log(err);
    }
  }, [dropdownOpt]);

  const imageEditHandler = () => {
    setImgEditStatus(!editImgStatus);
  };

  const handleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  const handleCheckboxChange = () => {
    setEditCategoryStatus(!editCategoryStatus);
  };

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    if (id === "mainHeading") {
      setHeading(value);
    }
    if (id === "imageFile" && files.length > 0) {
      const selectedFile = files[0];
      const allowedExtensions = ["jpg", "jpeg", "png"];
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        setFileError("Please select a valid JPG or PNG file.");
      } else {
        setFileError(""); // Clear any previous file error
        setImage(selectedFile);
      }
    }
    if (id === "category") {
      setCategory(value);
    }
    if (id === "cardpreview") {
      setCardPreview(value);
    }
    if (id === "handleCategory") {
      setCategoryfield(value);
    }
  };

  const handleContentChange = (newContent) => {
    setDescription(newContent);
  };

  const handleSeoChange = (e) => {
    const { value, id } = e.target;
    if (id === "seoTitle") {
      setSeoDetails((prevState) => ({
        ...prevState,
        seoTitle: value,
      }));
    } else if (id === "slug") {
      setSeoDetails((prevState) => ({
        ...prevState,
        slug: value,
      }));
    } else if (id === "metaDescription") {
      setSeoDetails((prevState) => ({
        ...prevState,
        metaDescription: value,
      }));
    }
  };

  const SEO = () => {
    return (
      <button
        className="bg-dark text-white mt-3 p-2 rounded-2 mx-3"
        onClick={() =>
          setSeoDetails((prevState) => ({ ...prevState, seoActive: true }))
        }
      >
        SEO
      </button>
    );
  };

  const seoDetailsForm = () => {
    return (
      <div className="seoFormWrapper position-fixed w-100 d-flex justify-content-center align-items-center top-0 end-0">
        <form action="" className="bg-white p-3 shadow-sm seoform rounded-2">
          <div className="m-2">
            <div className="d-flex justify-content-between">
              <label htmlFor="title">Title</label>
              <div>{seoDetails.seoTitle.length}/60</div>
            </div>
            <input
              type="text"
              name="seoTitle"
              id="seoTitle"
              placeholder="title"
              maxLength={60}
              value={seoDetails.seoTitle}
              className="rounded-1 p-1"
              onChange={(e) => handleSeoChange(e)}
            />
          </div>
          <div className="m-2">
            <div className="mt-3 d-flex justify-content-between">
              <label htmlFor="Slug">Slug</label>
              <div>{seoDetails.slug.length}/100</div>
            </div>
            <input
              name="slug"
              id="slug"
              cols="30"
              rows="5"
              placeholder="Slug"
              maxLength={100}
              className="rounded-1 p-1"
              value={seoDetails.slug}
              onChange={(e) => handleSeoChange(e)}
            />
          </div>
          <div className="m-2">
            <div className="mt-3 d-flex justify-content-between">
              <label htmlFor="meta description">Meta Description</label>
              <div>{seoDetails.metaDescription.length}/150</div>
            </div>
            <textarea
              name="metaDescription"
              id="metaDescription"
              cols="30"
              rows="5"
              placeholder="Meta Description"
              maxLength={150}
              className="rounded-1 p-1"
              value={seoDetails.metaDescription}
              onChange={(e) => handleSeoChange(e)}
            ></textarea>
          </div>
          <div className="d-flex">
            <button
              className="p-2 border-0 shadow-sm rounded-1 m-2"
              onClick={(e) => hadleSeoSubmit(e, seoBlogId)}
            >
              Submit
            </button>
            <button
              className="p-2 border-0  shadow-sm rounded-1 m-2"
              onClick={() =>
                setSeoDetails((prevState) => ({
                  ...prevState,
                  seoActive: false,
                }))
              }
            >
              close
            </button>
          </div>
        </form>
      </div>
    );
  };

  const buttonHandle = (id) => {
    return id ? (
      <button
        className="bg-dark text-white mt-3 p-2 rounded-2"
        onClick={handleEditPost}
      >
        Publish
      </button>
    ) : (
      <button
        className="bg-dark text-white mt-3 p-2 rounded-2"
        onClick={handleSubmit}
      >
        Publish
      </button>
    );
  };

  const HandleClick = () => {
    setShowBlog(true);
  };

  const navigate = useNavigate();
  const HandleaddClick = () => {
    navigate("/blogs");
  };

  if (showBlog) {
    return <BlogPage />;
  }

  const backButton = (heading) => {
    return heading ? (
      <button
        className="backToBlog py-1 px-2 position-fixed z-1"
        onClick={HandleClick}
      >
        <FaArrowLeftLong />
      </button>
    ) : (
      <button
        className="backButton  py-1 px-2 position-fixed"
        onClick={HandleaddClick}
      >
        <FaArrowLeftLong />
      </button>
    );
  };

  //delete category
  const deleteCategoryHandler = (id) => {
    try {
      axiosInstance
        .delete(`/deleteCategory/${id}`)
        .then((response) => {})
        .catch((err) => {});
    } catch (err) {
      console.log(err);
    }
  };

  //adding category
  const AddCategoryHandler = () => {
    try {
      const formData = { category: Categoryfield };
      axiosInstance
        .post("/AddCategory", formData)
        .then((response) => {
          alert("Category added sucessfully");
          setCategoryfield("");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  //edit a post
  const handleEditPost = (e) => {
    e.preventDefault();

    const myFormData = new FormData();
    myFormData.append("heading", heading);
    myFormData.append("category", category);
    myFormData.append("content", description);
    myFormData.append("cardPreview", cardPreview);
    myFormData.append("image", image);
    myFormData.append("publish", true);

    try {
      axiosInstance
        .patch(`/editBlog/${id}`, myFormData)
        .then((response) => {
          alert("Post Updated Sucessfully");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handlePost = (e, isDraft) => {
    e.preventDefault();

    const myFormData = new FormData();
    myFormData.append("heading", heading);
    myFormData.append("category", category);
    myFormData.append("content", description);
    myFormData.append("cardPreview", cardPreview);
    myFormData.append("image", image);
    myFormData.append("publish", isDraft);
    myFormData.append("id", id);
    try {
      axiosInstance
        .post("addBlog", myFormData)
        .then((response) => {
          alert("Added Successfully");
          if (isDraft === false) {
            navigation("/protected/drafts");
          } else if (isDraft === true) {
            navigation("/Blogs");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const hadleSeoSubmit = (e, seoBlogId) => {
    e.preventDefault();

    try {
      const formData = {
        seoTitle: seoDetails.seoTitle,
        slug: seoDetails.slug,
        metaDescription: seoDetails.metaDescription,
      };
      axiosInstance
        .patch(`/blogSeo/${seoBlogId}`, formData)
        .then((response) => {
          alert("SEO updated");
          setSeoDetails({
            seoTitle: "",
            slug: "",
            metaDescription: "",
            seoActive: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  // Usage
  const handleDraftInputChange = (e) => handlePost(e, false);
  const handleSubmit = (e) => {
    if (!fileError) {
      handlePost(e, true);
    }
  };

  return (
    <div className="postEditorPage d-flex justify-content-center ">
      <div>{backButton(id)}</div>
      <div className="editorBtns">
        {seoBlogId !== undefined &&
          seoBlogId !== null &&
          seoBlogId !== "" &&
          SEO()}
        <button
          className="bg-dark text-white mt-3 p-2 rounded-2 mx-3"
          onClick={handleDraftInputChange}
        >
          Save Draft
        </button>
        {buttonHandle(id)}
      </div>
      {seoDetails.seoActive && seoDetailsForm()}
      <div className="postEditor">
        <Form onSubmit={handleSubmit}>
          {showDetails && (
            <div>
              <Form.Label>Heading</Form.Label>
              <FormControl
                type="text"
                id="mainHeading"
                name="heading"
                value={heading}
                onChange={handleChange}
                placeholder="Heading"
              />
              <Form.Label>Image</Form.Label>
              <InputGroup className="mb-3">
                {editImgStatus ? (
                  <FormControl
                    type="text"
                    name="image"
                    value={typeof editImage === "string" ? editImage : ""}
                    onChange={handleChange}
                    readOnly={typeof editImage === "string"}
                  />
                ) : (
                  <FormControl
                    type="file"
                    name="image"
                    accept="image/jpeg, image/png"
                    id="imageFile"
                    onChange={handleChange}
                  />
                )}
                <Button
                  variant="outline-secondary"
                  onClick={imageEditHandler}
                  id="button-addon2"
                >
                  Edit
                </Button>
              </InputGroup>
              {fileError ? <p className="text-danger">{fileError} </p> : ""}
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Edit Category"
                  onChange={() => handleCheckboxChange(id)}
                  id="additionalInfoCheckbox"
                />
              </Form.Group>
              <InputGroup>
                {editCategoryStatus && (
                  <>
                    <FormControl
                      type="text"
                      id="handleCategory"
                      name="handleCategory"
                      value={Categoryfield}
                      onChange={handleChange}
                      placeholder="Category"
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={AddCategoryHandler}
                      id="button-addon2"
                    >
                      Add
                    </Button>
                  </>
                )}
              </InputGroup>
              <InputGroup>
                {editCategoryStatus &&
                  (dropdownOpt.length !== 0 ? (
                    <div className="bg-white my-2 w-100 rounded-3">
                      <ul type="none" className="d-flex flex-wrap">
                        {dropdownOpt.map((category) => (
                          <li
                            key={category._id}
                            title={category.category}
                            className="bg-dark rounded-3 text-white m-2 py-2 px-1 d-flex justify-content-between wrapDeleteBtns overflow-hidden"
                          >
                            <div
                              className="w-75 d-flex align-items-center overflow-hidden"
                              style={{ wordBreak: "break-all" }}
                            >
                              <span className="w-75">{category.category}</span>
                            </div>
                            <TiDelete
                              size="1.7em"
                              className="deleteCategory"
                              onClick={() => {
                                deleteCategoryHandler(category._id);
                              }}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <></>
                  ))}
              </InputGroup>
              <Form.Label>Categories</Form.Label>
              <Form.Select
                onChange={handleChange}
                id="category"
                name="category"
                value={category}
              >
                <option>Choose a Category</option>
                {dropdownOpt.map((option) => (
                  <option keyId={option._id} value={option.category}>
                    {option.category}
                  </option>
                ))}
              </Form.Select>
              <Form.Label>Card Preview</Form.Label>
              <FormControl
                type="text"
                id="cardpreview"
                name="cardPreview"
                value={cardPreview}
                onChange={handleChange}
                placeholder='eg:"The Indian high commission in Ottawa on Friday (local time) issued "'
              />
            </div>
          )}
          <Form.Check
            type="checkbox"
            label="Show Details"
            className=" mt-3"
            onChange={handleShowDetails}
            id="showDetails"
          />
          <JoditEditor
            ref={editor}
            config={config}
            id="content"
            name="content"
            className="description"
            value={description}
            onChange={handleContentChange}
            tabIndex={1}
          />
        </Form>
      </div>
    </div>
  );
}

export default PostEditor;
