import React from "react";
import Sat1 from "../../../Assets/img28.jpg";
import "./sat-images.css";

function SatImages() {
  return (
    <div>
      <a href="https://testprep.confluenceedu.com/">
        <img
          src={Sat1}
          alt="Sat test prep template"
          className="satimage1 mb-2"
        ></img>
      </a>
    </div>
  );
}

export default SatImages;
