import React from "react";
import "./payment.css";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  return (
    <div className="payment-success">
      <div className="paymentSuccessIcon-container">
        <span className="success-icon">&#10003;</span>{" "}
        {/* Unicode check mark */}
      </div>
      <h1 className="success-message">Payment Successful!</h1>
      <p className="confirmation-text text-center">
        Thank you for choosing ConfluenceEdu services! Your transaction has been
        successfully processed, and a receipt has been sent to your email.
      </p>
      <div className="button-container">
        <button className="home-button" onClick={() => navigate("/")}>
          Return to Home
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
