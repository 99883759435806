import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import WhatsAppImg from "../../Assets/confluence_profile.jpg";
import "./whatsApp.css";

function WhatsApp() {
  return (
    <div>
      <FloatingWhatsApp
        phoneNumber="+91 8186960181"
        accountName="ConfluenceEdu"
        avatar={WhatsAppImg}
        statusMessage="Online"
        allowClickAway="true"
        placeholder="Say Hi to us...."
        messageDelay={1}
        className="floating_whatsapp d-none d-md-block"
      />
    </div>
  );
}

export default WhatsApp;
