import React from "react";
import FeeStructureTables from "../FeeStructureTable";
import Services from "./Services.json";
import IrelandAtoV from "../../../Assets/countryImages/Ireland A to V.jpg";
import "./countryImage.css";

function Ireland() {
  const StudentServices = [
    {
      service: "Bachelors Admission Counseling Services",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
    {
      service: "Masters Admission Counseling Services",
      Fees: 25000,
      GST: 4500,
      Total: 29500,
    },
    {
      service: "Visit Visa Services",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
  ];

  return (
    <div className="bg-white d-flex justify-content-center flex-column align-items-center">
      {/* <h1>Ireland</h1> */}
      <img
        src={IrelandAtoV}
        alt="Ireland Admission to Visa"
        className="countryAtoVImg"
      />
      <FeeStructureTables
        countryName={"Ireland"}
        studentServices={StudentServices}
      />
    </div>
  );
}

export default Ireland;
