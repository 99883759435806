import React from "react";
import FeeStructureTables from "../FeeStructureTable";
import Services from "./Services.json";
import SpainAtoV from "../../../Assets/countryImages/Spain A to V.jpg";
import "./countryImage.css";

function Spain() {
  const StudentServices = [
    {
      service: "Bachelors Admission Counseling Services",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
    {
      service: "Masters Admission Counseling Services",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
    {
      service: "Visit Visa Services",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
    {
      service: "Fashion Counseling",
      Fees: 120000,
      GST: 21600,
      Total: 141600,
    },
    {
      service: "Design Counseling",
      Fees: 120000,
      GST: 21600,
      Total: 141600,
    },
  ];

  return (
    <div className="bg-white d-flex justify-content-center flex-column align-items-center">
      {/* <h1>Spain</h1> */}
      <img
        src={SpainAtoV}
        alt="Spain admission to visa"
        className="countryAtoVImg"
      />
      <FeeStructureTables
        countryName={"Spain"}
        studentServices={StudentServices}
      />
    </div>
  );
}

export default Spain;
