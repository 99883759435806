import React from "react";
import FeeStructureTables from "../FeeStructureTable";
import Services from "./Services.json";
import "./countryImage.css";
import SwitzerLandAtoV from "../../../Assets/countryImages/Switzerland A to V.jpg";
function Switzerland() {
  const StudentServices = [
    {
      service: "Bachelors Admission Counseling Services",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
    {
      service: "Bachelors Admission Counseling Services",
      Fees: 25000,
      GST: 4500,
      Total: 29500,
    },
    {
      service: "Visa Counseling",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
  ];

  return (
    <div className="bg-white d-flex justify-content-center flex-column align-items-center">
      {/* <h1>Switzerland</h1>
       */}
      <img
        src={SwitzerLandAtoV}
        alt="Switzerland admission to visa"
        className="countryAtoVImg"
      />
      <FeeStructureTables
        countryName={"Switzerland"}
        studentServices={StudentServices}
      />
    </div>
  );
}

export default Switzerland;
