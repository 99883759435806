import React from "react";
import Gre1 from "../../../Assets/img26.jpg";
import "./gre-images.css";

function GreImages() {
  return (
    <div>
      <a href="https://testprep.confluenceedu.com/">
        <img
          src={Gre1}
          alt="Gre test prep template"
          className="greimg mb-2"
        ></img>
      </a>
    </div>
  );
}

export default GreImages;
