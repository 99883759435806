import React from "react";
import DuolingoContent from "../components/Test prep/Duolingo/DuolingoContent";
import DuolingoImages from "../components/Test prep/Duolingo/Duolingo-images";
import TestBanner from "../components/Test prep/TestBanner";
import HelmetWrapper from "../HelmetWrapper";

function Duolingo() {
  return (
    <>
      <HelmetWrapper
        title="Duolingo Exam Prep | Confluence Education"
        description="Prepare for the Duolingo proficiency exam with Confluence Education's tailored courses. Enhance your language skills and achieve success with expert instructors."
        canonicalUrl="https://www.confluenceedu.com/duolingo"
      />
      <TestBanner Duolingo={"Duolingo"} />
      <DuolingoContent />
      <DuolingoImages />
    </>
  );
}

export default Duolingo;
