import React from "react";

function Netherlands() {
  return (
    <>
      <svg width="50" height="50" viewBox="0 0 9 6">
        <rect fill="#21468B" width="9" height="6" />
        <rect fill="#FFF" width="9" height="4" />
        <rect fill="#AE1C28" width="9" height="2" />
      </svg>
    </>
  );
}

export default Netherlands;
