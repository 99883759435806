import React from "react";
import CareerPage from "../components/CareerPage/CareerPage";

function Careers() {
  return (
    <div>
      <CareerPage />
    </div>
  );
}

export default Careers;
