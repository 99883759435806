import React from "react";
import "./greContent.css";

function GreContent() {
  return (
    <div>
      <div className="gre_content p-2">
        <h1 className="mt-1">About GRE</h1>
        <h5>What is GRE?</h5>
        <p className="para_content">
          <strong style={{ fontSize: "30px" }}>G</strong>RE is a standardized
          test widely accepted by graduate and business schools around the
          world. It assesses your analytical writing, verbal reasoning, and
          quantitative reasoning skills, providing a comprehensive evaluation of
          your readiness for postgraduate studies.
        </p>
        <h5>How Does the Exam Work?</h5>
        <p className="para_content">
          <strong>Verbal Reasoning:</strong>Evaluates your ability to analyze
          and understand written material, comprehend relationships among parts
          of sentences, and reason with incomplete information.
        </p>
        <p className="para_content">
          <strong>Quantitative Reasoning:</strong>Tests your problem-solving
          ability, focusing on basic concepts of arithmetic, algebra, geometry,
          and data analysis.
        </p>
        <p className="para_content">
          <strong>Analytical Writing:</strong>Measures your critical thinking
          and analytical writing skills through tasks requiring you to
          articulate complex ideas effectively.
        </p>
      </div>
    </div>
  );
}

export default GreContent;
