import { NavLink } from "react-router-dom";
import "./navbar.css";

export function StudyAbroadDropdown({ handleDropdownClick }) {
  return (
    <nav className="testprep-links">
      <NavLink to="/student-guide" onClick={handleDropdownClick}>
        Study Abroad
      </NavLink>
      <NavLink to="/coursesInfo" onClick={handleDropdownClick}>
        Courses
      </NavLink>
      {/* <NavLink to="/visits" onClick={handleDropdownClick}>
        Representatives
      </NavLink>
      <NavLink to="/Notifications" onClick={handleDropdownClick}>
        Notifications
      </NavLink> */}
      <NavLink to="/resources" onClick={handleDropdownClick}>
        Resources
      </NavLink>
      <NavLink to="/partners" onClick={handleDropdownClick}>
        Partners
      </NavLink>
      <div className="dropdown_couIntakes">
        <NavLink to={`/destination`} onClick={handleDropdownClick}>
          Destination
        </NavLink>
      </div>
    </nav>
  );
}
