import React from "react";

function France() {
  return (
    <>
      <svg width="50" height="50" viewBox="0 0 900 600">
        <rect width="900" height="600" fill="#ED2939" />
        <rect width="600" height="600" fill="#fff" />
        <rect width="300" height="600" fill="#002395" />
      </svg>
    </>
  );
}

export default France;
