import React from "react";
import Duolingo1 from "../../../Assets/img30.jpg";
import "./duolingo-images.css";

function DuolingoImages() {
  return (
    <div>
      <a href="https://testprep.confluenceedu.com/">
        <img src={Duolingo1} alt="duolingo" className="duolingoimg mb-2"></img>
      </a>
    </div>
  );
}

export default DuolingoImages;
