import React, { useEffect } from "react";

const withCopyPasteDisabled = (WrappedComponent) => {
  const DisableCopyPaste = () => {
    useEffect(() => {
      const disableCopyPaste = (event) => {
        event.preventDefault();
        return false;
      };

      document.addEventListener("copy", disableCopyPaste);
      document.addEventListener("cut", disableCopyPaste);
      document.addEventListener("paste", disableCopyPaste);

      return () => {
        document.removeEventListener("copy", disableCopyPaste);
        document.removeEventListener("cut", disableCopyPaste);
        document.removeEventListener("paste", disableCopyPaste);
      };
    }, []);

    return <WrappedComponent />;
  };

  return DisableCopyPaste;
};

export default withCopyPasteDisabled;
