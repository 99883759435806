import React from "react";
import BlogBox from "../components/Blogpage/BlogBox";
import BlogBanner from "../components/Blogpage/BlogBanner";
// import BlogCarousel from "../components/Blogpage/BlogCarousel";
// import Blog3 from "../components/Blog2/Blog3";

function Blog() {
  return (
    <>
      {/* <Blog3 /> */}
      {/* <BlogCarousel /> */}
      <BlogBanner />
      <BlogBox />
    </>
  );
}

export default Blog;
