import React from "react";
import "./privacyPolicy.css";
import HelmetWrapper from "../../HelmetWrapper";

function PrivacyPolicyPage() {
  return (
    <>
      <HelmetWrapper
        title="Privacy policy of Confluence Educational services Hyderabad"
        description="Discover everything you need to know about privacy policy of Confluence Educational services."
        canonicalUrl="https://www.confluenceedu.com/PrivacyPolicy"
      />
      <div className="privacy_policy p-2 fw-light">
        <h1>Privacy Policy </h1>
        <p>
          <strong>A</strong>t Confluence Educatioal Services, we prioritize the
          privacy and confidentiality of our clients' information. This Privacy
          Policy outlines how we collect, use, store, and protect personal data
          provided to us through our services.
        </p>
        <br />
        <h5>
          <strong>Information Collection and Use</strong>
        </h5>
        <p>
          We collect personal information such as names, contact details,
          academic history, and other relevant data necessary for the provision
          of our services. This information is voluntarily provided by clients
          and is used solely for the purpose of assisting in the application
          process for higher education.
        </p>
        <br />
        <h5>
          <strong>Use of Information</strong>
        </h5>
        <p>
          The information collected is utilized to assist clients in the
          preparation of documents such as Statements of Purpose (SOPs),
          Recommendation Letters, and application forms. However, it's important
          to note that while we provide guidance and support in creating these
          documents, we do not verify the accuracy or factual information within
          them.
        </p>
        <br />
        <h5>
          <strong>Data Security</strong>
        </h5>
        <p>
          We implement industry-standard security measures to safeguard personal
          information from unauthorized access, alteration, disclosure, or
          destruction. Our systems are regularly updated and maintained to
          ensure the security of data.
        </p>
        <br />
        <h5>
          <strong>Third-Party Disclosure</strong>
        </h5>
        <p>
          We do not sell, trade, or otherwise transfer clients' personal
          information to third parties without explicit consent, except when
          necessary for the provision of our services or as required by law.
        </p>
        <br />
        <h5>
          <strong>Admissions and Visa</strong>
        </h5>
        <p>
          We do not guarantee admissions or visas. The outcomes are solely
          dependent on the applicants' merits, the timing of applications,
          university policies, and other external factors beyond our control.
          Our role is to facilitate the application process and assist with visa
          applications.
        </p>
        <br />
        <h5>
          <strong>Client Rights</strong>
        </h5>
        <p>
          Clients have the right to access, correct, or delete their personal
          information held by us. They can also opt-out of any communication or
          service at any time.
        </p>
        <br />
        <h5>
          <strong>Contact Us</strong>
        </h5>
        <p>
          If there are any questions or concerns regarding this Privacy Policy
          or the handling of personal information, please contact us at (+91
          93911 01403).
        </p>
      </div>
    </>
  );
}

export default PrivacyPolicyPage;
