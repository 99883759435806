import "./destinationPage.css";
import { useNavigate } from "react-router-dom";
import USA from "../../Assets/countryImages/USA.jpg";
import UK from "../../Assets/countryImages/UK.jpg";
import Australia from "../../Assets/countryImages/Australia.jpg";
import Canada from "../../Assets/countryImages/Canada.jpg";
import France from "../../Assets/countryImages/France.jpg";
import Germany from "../../Assets/countryImages/Germany.jpg";
import Ireland from "../../Assets/countryImages/Ireland.jpg";
import Netherlands from "../../Assets/countryImages/Netherlands.jpg";
import NewZealand from "../../Assets/countryImages/NewZealand.jpg";
import Spain from "../../Assets/countryImages/Spain.jpg";
import Switzerland from "../../Assets/countryImages/Switzerland.jpg";
import Italy from "../../Assets/countryImages/Italy.jpg";

function DestinationPage() {
  const navigate = useNavigate();
  const listOfCountries = [
    {
      country: "United States of America",
      image: USA,
    },
    {
      country: "United Kingdom",
      image: UK,
    },
    {
      country: "Australia",
      image: Australia,
    },
    { country: "Canada", image: Canada },

    {
      country: "France",
      image: France,
    },
    { country: "Germany", image: Germany },
    {
      country: "Ireland",
      image: Ireland,
    },
    { country: "Netherlands", image: Netherlands },
    { country: "New Zealand", image: NewZealand },
    { country: "Spain", image: Spain },
    { country: "Switzerland", image: Switzerland },
    { country: "Italy", image: Italy },
  ];

  const handleNavigation = (country) => {
    console.log("-------------country triggered", country);
    const formattedCountry = country.replace(/\s+/g, "-");
    navigate(`/destination/Study-in-${formattedCountry}`);
  };
  return (
    <div className="wr_destinationPage py-5">
      <h1 className="text-center mb-3">Choose Your Destination</h1>
      <div className="d-flex flex-wrap countrycardsWarp mx-auto justify-content-center">
        {listOfCountries.map((country, index) => (
          <div
            className="singleCard position-relative d-flex justify-content-center m-3 rounded-3"
            key={index}
            onClick={() => handleNavigation(country.country)}
          >
            <img src={country.image} alt="country" className="countryImage" />
            <p className="position-absolute text-center countryTitle fs-4">
              {country.country}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DestinationPage;
