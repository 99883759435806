// import { useEffect, useContext } from "react";
// import { AuthContext } from "../App.js";
import Banner from "../components/Home/Banner.js";
import About from "../components/Home/AboutConfluence.js";
import Card from "../components/Home/Coursescards.js";
import Whatwedo from "../components/Home/Whatwedo.js";
import RegistrationForm from "../components/RegistrationBlock/RegistrationBlock.js";
import DestinationPage from "../components/Study Abroad/DestinationPage.js";
import Thinkific from "../components/Home/Thinkific.js";
import HelmetWrapper from "../HelmetWrapper.js";

function Home() {
  return (
    <>
      <HelmetWrapper
        title="Confluence Education | Study Abroad Experts"
        description="Fulfill your dreams of studying abroad with Confluence Educational Services. Get personalized guidance, expert assistance, and comprehensive support for a successful academic journey."
        canonicalUrl="https://www.confluenceedu.com"
      />
      <Banner />
      <About />
      <DestinationPage />
      <Card />
      <Whatwedo />
      <Thinkific />
      <RegistrationForm />
    </>
  );
}

export default Home;
