import React from "react";
import Toefl1 from "../../../Assets/img32.jpg";
import Toefl2 from "../../../Assets/img34.jpg";
import "./toefl-images.css";

function ToeflImages() {
  const toeflImgs = [
    {
      imgSrc: Toefl1,
      alt: "toefl test prep template",
    },
    {
      imgSrc: Toefl2,
      alt: "toef2 test prep template",
    },
  ];
  return (
    <div>
      {toeflImgs.map((toeflImg) => (
        <a href="https://testprep.confluenceedu.com/">
          <img
            src={toeflImg.imgSrc}
            alt={toeflImg.alt}
            className="toeflimg1 mb-2"
          ></img>
        </a>
      ))}
    </div>
  );
}

export default ToeflImages;
