import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import Navbar from "./Routes/Navbar";
import PathConfig from "./Routes/PathConfig";
import Footer from "./components/Footer/Footer";
import WhatsApp from "./components/Whatsapp/WhatsApp";
import OTPScreen from "./components/OtpScreen/Otp";
import UserLoginPage from "./components/userLoginPage/UserLoginPage";
// import PageEvent from "./Assets/Page Event.jpeg";
// import { Modal } from "react-bootstrap";
// import { RxCross1 } from "react-icons/rx";

export const AuthContext = createContext();

function App() {
  const [token, setToken] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [paginNum, setPaginNum] = useState(1);
  const [univPage, setUnivPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [showLogin, setShowLogin] = useState(false);
  const [navState, setNavState] = useState(false);
  // const [showImage, setImageShow] = useState(false);
  const location = useLocation();

  // const handleLogout = () => {
  //   localStorage.removeItem("token");
  //   setToken("");
  // };
  // const handleClose = () => {
  //   setImageShow(false);
  // };

  // const handleLogin = () => {
  //   setShowLogin(true);
  // };

  useEffect(() => {
    // const timeoutId = setTimeout(() => {
    //   if (location.pathname !== "/admin") {
    //     setImageShow(true);
    //   }
    // }, 2000);

    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    }

    const userName = localStorage.getItem("userName");
    const userId = localStorage.getItem("userId");

    console.log("---username", userName);
    console.log("---userId", userId);

    if (userName && userId) {
      setNavState(true);
    }
    // return () => clearTimeout(timeoutId);
  }, []);

  // const logout = () => {
  //   return token ? (
  //     <div onClick={handleLogout}>Logout</div>
  //   ) : (
  //     <div style={{ display: "none" }}></div>
  //   );
  // };

  return (
    <AuthContext.Provider
      value={{
        token,
        // handleLogout,
        // logout,
        setToken,
        pageNum,
        setPageNum,
        paginNum,
        setPaginNum,
        categoryPage,
        setCategoryPage,
        univPage,
        setUnivPage,
        showLogin,
        setShowLogin,
        navState,
        setNavState,
      }}
    >
      <div className="App">
        <Navbar />
        <div className="routePages">
          <PathConfig />
          <OTPScreen />
          <WhatsApp />
          <Footer className="footer" />
        </div>

        <UserLoginPage LoginState={showLogin} />

        {/* <Modal
          show={showImage}
          onHide={handleClose}
          backdrop="static"
          className="mt-5"
        >
          <img
            src={PageEvent}
            alt=""
            className="pageEventImage position-relative"
          />
          <RxCross1
            onClick={handleClose}
            className="crossElement position-absolute rounded-circle p-1 p-md-2"
            title="close"
          </Modal>
          /> */}
      </div>
    </AuthContext.Provider>
  );
}

export default App;
