import React from "react";

function FilterComponent({ categoryValue }) {
  return (
    <div className="d-flex justify-content-between align-items-center shadow-sm rounded-2">
      <h4 className="px-1 fs-4">Filter :</h4>
      <div className="px-2">{categoryValue}</div>
    </div>
  );
}

export default FilterComponent;
