import React from "react";

function Australia() {
  return (
    <>
      <svg width="50" height="50" viewBox="0 0 10080 5040">
        <defs>
          <clipPath id="c">
            <path d="M0,0V1.5H7V3H6zM6,0H3V3.5H0V3z" />
          </clipPath>
          <path
            id="Star7"
            d="M0,-360 69.421398,-144.155019 281.459334,-224.456329 155.988466,-35.603349 350.974048,80.107536 125.093037,99.758368 156.198146,324.348792 0,160 -156.198146,324.348792 -125.093037,99.758368 -350.974048,80.107536 -155.988466,-35.603349 -281.459334,-224.456329 -69.421398,-144.155019z"
          />
          <path
            id="Star5"
            d="M0,-210 54.859957,-75.508253 199.721868,-64.893569 88.765275,28.841586 123.434903,169.893569 0,93.333333 -123.434903,169.893569 -88.765275,28.841586 -199.721868,-64.893569 -54.859957,-75.508253z"
          />
          <use id="Cstar" transform="scale(2.1)" />
        </defs>
        <g transform="scale(840)">
          <rect width="12" height="6" fill="#00008b" />
          <path d="M0,0 6,3M6,0 0,3" stroke="#fff" stroke-width="0.6" />
          <path
            d="M0,0 6,3M6,0 0,3"
            stroke="#f00"
            stroke-width="0.4"
            clip-path="url(#c)"
          />
          <path d="M3,0V3.5M0,1.5H7" stroke="#fff" />
          <path d="M3,0V3.5M0,1.5H7" stroke="#f00" stroke-width="0.6" />
          <path d="M0,3H6V0H8V4H0z" fill="#00008b" />
        </g>
        <g fill="#fff">
          <use id="Comwlth" x="2520" y="3780" />
          <use id="αCrucis" x="7560" y="4200" />
          <use id="βCrucis" x="6300" y="2205" />
          <use id="γCrucis" x="7560" y="840" />
          <use id="δCrucis" x="8680" y="1869" />
          <use id="εCrucis" x="8064" y="2730" />
        </g>
      </svg>
    </>
  );
}

export default Australia;
