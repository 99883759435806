import React from "react";
import "./pteContent.css";

function PteContent() {
  return (
    <div>
      <div className="pte_content p-2">
        <h1 className="mt-1">About PTE</h1>
        <h5>What is PTE?</h5>
        <p className="para_content">
          <strong style={{ fontSize: "30px" }}>P</strong>TE(Pearson Test of
          English) Academic is a computer-based language proficiency exam
          designed for individuals aspiring to study or work abroad. Recognized
          globally by universities, colleges, and governments, PTE assesses and
          validates English language skills across listening, reading, speaking,
          and writing.
        </p>
        <h5>How PTE works</h5>
        <p className="para_content">
          The exam utilizes cutting-edge technology to evaluate language
          abilities through a combination of real-life scenarios, enabling
          test-takers to demonstrate their English proficiency in an unbiased
          and secure environment. PTE Academic delivers quick and accurate
          results, typically within five business days, empowering candidates to
          efficiently plan their academic or career journeys.
        </p>
      </div>
    </div>
  );
}

export default PteContent;
