import React from 'react'

function Switzerland() {
  return (
    <>
   <svg width="50" height="40" viewBox="0 0 32 30" xmlns="http://www.w3.org/2000/svg">
    <path d="m0 0h32v32h-32z" fill="#f00"/>
    <path d="m13 6h6v7h7v6h-7v7h-6v-7h-7v-6h7z" fill="#fff"/>
   </svg>
    </>
  )
}

export default Switzerland