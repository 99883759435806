import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./searchComponent.css";
import { ImSearch } from "react-icons/im";
import config from "../../Boot/config.json";
import axios from "axios";

const SearchComponent = () => {
  // State for search query and filtered data
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [defaultData, setDefaultData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${config.baseUrl}/Blogs/AllBlogs`);
        const sortedBlogs = response.data.fullData.sort((a, b) =>
          a.mainHeading.localeCompare(b.mainHeading)
        );

        // Group blogs by the first letter of mainHeading
        const groupedData = sortedBlogs.reduce((acc, blog) => {
          const firstLetter = blog.mainHeading[0].toUpperCase();
          if (!acc[firstLetter]) {
            acc[firstLetter] = [blog];
          } else {
            acc[firstLetter].push(blog);
          }
          return acc;
        }, {});

        // Sort groupedData by key (alphabetically)
        const sortedGroupedData = Object.keys(groupedData)
          .sort()
          .reduce(
            (acc, key) => ({
              ...acc,
              [key]: groupedData[key],
            }),
            {}
          );

        setFilteredData(sortedGroupedData);
        setDefaultData(sortedGroupedData);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  function urlEncoded(title) {
    return title
      .toLowerCase() // Optional: convert to lowercase
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
  }
  const truncateText = (text) => {
    return text;
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (query.trim() === "") {
      // If the search query is empty, reset filteredData to the original data
      setFilteredData(defaultData);
    } else {
      // If there's a search query, filter the data
      const filteredResults = {};
      for (const letter in defaultData) {
        const filteredBlogs = defaultData[letter].filter((blog) =>
          blog.mainHeading.toLowerCase().includes(query)
        );
        if (filteredBlogs.length > 0) {
          filteredResults[letter] = filteredBlogs;
        }
      }
      setFilteredData(filteredResults);
    }
  };

  const navigate = useNavigate();

  const handleClick = (id) => {
    // Find the blog item by iterating over the values of the filteredData object
    let clickedItem = null;
    for (const key in filteredData) {
      clickedItem = filteredData[key].find((item) => item._id === id);
      if (clickedItem) break; // Stop searching once the item is found
    }

    // if (clickedItem) {
    //   // console.log(clickedItem);
    //   // console.log(filteredData);
    // } else {
    //   console.log("Item not found");
    // }

    navigate(`/blog/${urlEncoded(clickedItem.mainHeading)}/${id}`);
  };

  return (
    <section className="searchBar bg-white position-relative ">
      {/* <select name="countries" id="countries">
        {countries.map((country, index) => (
          <option value="" key={index}>
            {country}
          </option>
        ))}
      </select> */}
      <div className="searchInput ">
        <ImSearch className="fs-5 me-2" />
        <input
          type="text"
          placeholder="Search Blogs . . . ."
          onChange={handleSearchChange}
          className="border-0"
          value={searchQuery}
        />
        <ul className="suggestionsList position-absolute p-0 ms-0">
          {Object.entries(filteredData).map(([letter, blogs]) => (
            <React.Fragment key={letter}>
              <li className="groupHeading ps-2 fs-5 fw-bold">{letter}</li>
              {blogs.map((item) => (
                <li
                  key={item._id}
                  onMouseDown={() => handleClick(item._id)}
                  style={{ listStyleType: "none" }}
                  className="headings"
                >
                  {truncateText(item.mainHeading, 30)}
                </li>
              ))}
            </React.Fragment>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default SearchComponent;
