import React from "react";
import PteContent from "../components/Test prep/Pte/PteContent";
import PteImages from "../components/Test prep/Pte/Pte-images";
import TestBanner from "../components/Test prep/TestBanner";
import HelmetWrapper from "../HelmetWrapper";

function Pte() {
  return (
    <>
      <HelmetWrapper
        title="PTE Academic Overview | Confluence Education"
        description="Learn about the PTE Academic exam and its significance for studying or working abroad. Prepare effectively with Confluence Education's expert resources."
        canonicalUrl="https://www.confluenceedu.com/pte"
      />

      <TestBanner PTE={"PTE"} />
      <PteContent />
      <PteImages />
    </>
  );
}

export default Pte;
