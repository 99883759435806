import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import ImageEditor from "./ImageEditor/ImageEditor";
import { AuthContext } from "../../App";
import { MdDelete, MdModeEdit } from "react-icons/md";
import config from "../../Boot/config.json";
// import TextToSpeech from "../TextToSpeech/TextToSpeech";
import axios from "axios";
import "./courses.css";

const Courses = ({ images, visitMode }) => {
  const [show, setShow] = useState(false);
  const [courseBtn, setCourseBtn] = useState(false);
  const [visitBtn, setVisitBtn] = useState(false);
  const [visitImages, setVisitImages] = useState([]);
  const [delCouseStat, setDelCourseStat] = useState(false);
  const [addCourseStatus, setAddCourseStatus] = useState(false);
  const [visibleImages, setVisibleImages] = useState(8);
  const [itemsToLoad, setItemsToLoad] = useState(8);
  const [counter, setCounter] = useState(1);
  const [cPage, setCpage] = useState(1);
  const [country, setCountry] = useState("");
  const [university, setUniversity] = useState("");
  const [filterstatus, setFilterStatus] = useState(false);
  const [queryString, setQuerySting] = useState({});
  const [singleCourse, setSingleCourse] = useState({});
  const [editBtn, setEditBtn] = useState(false);
  const [couiesUnies, setCouiesUnies] = useState({
    universities: [],
    countries: [
      "United States of America",
      "United Kingdom",
      "Australia",
      "Canada",
      "Germany",
      "France",
      "Italy",
      "Netherlands",
      "New Zealand",
      "Spain",
      "Ireland",
      "Switzerland",
    ],
  });

  const { token } = useContext(AuthContext);

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/DisplayCV`,
    headers: {
      Authorization: token,
    },
  });

  const loadData = (newImageRecords) => {
    setVisitImages((prevImages) => {
      // Combine new records and previous images
      const combinedRecords = [...prevImages, ...newImageRecords];

      // Create a Set to store unique _id values
      const uniqueIds = new Set();

      // Filter out duplicates based on the _id property
      const uniqueRecords = combinedRecords.filter((record) => {
        if (!uniqueIds.has(record._id)) {
          // Add the current _id to the Set if it's not present
          uniqueIds.add(record._id);
          return true;
        }
        return false;
      });

      return uniqueRecords;
    });
  };

  useEffect(() => {
    (async () => {
      try {
        let response;
        if (visitMode) {
          response = await axios.get(
            `${config.baseUrl}/DisplayCV/getAllVisits?page=${counter}`
          );
        } else {
          response = await axios.get(
            `${config.baseUrl}/DisplayCV/getAllCourses?page=${counter}`
          );
          const queryString = { country: "All" };
          localStorage.setItem("searchCouUni", JSON.stringify(queryString));
          const { uniqueUniversities } = response.data;

          setCouiesUnies((prevCouiesUnies) => ({
            ...prevCouiesUnies,
            universities: uniqueUniversities,
          }));
        }
        const ImgaeRecords = response.data.data;

        setFilterStatus(false);
        loadData(ImgaeRecords);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [delCouseStat, addCourseStatus, visitMode, counter]);

  useEffect(() => {
    (async () => {
      try {
        if (visitMode) {
        } else {
          const formData = { country, queryString };
          const response = await axiosInstance.post(
            `/filterCountryBase?page=${cPage}`,
            formData
          );
          const ImgaeRecords = response.data.data;
          loadData(ImgaeRecords);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [cPage]);

  const handleVisitImage = () => {
    setShow(true);
    setVisitBtn(true);
  };

  const handleCourseImage = () => {
    setSingleCourse({
      alt: "",
      description: "",
      country: "",
      universityName: "",
      aboutCourse: "",
      whyThisCourse: "",
      whatsNext: "",
    });
    setShow(true);
    setEditBtn(false);
    setCourseBtn(true);
  };

  const handleVisitDelete = async (id) => {
    try {
      await axiosInstance.delete(`/deleteVisits/${id}`);
      alert("Image deleted sucessfully");
      const updatedImages = visitImages.filter((image) => image._id !== id);
      setVisitImages(updatedImages);

      setDelCourseStat(!delCouseStat);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCourseEdit = async (id) => {
    try {
      const response = await axiosInstance.get(`/getSingleCourse/${id}`);
      setSingleCourse({
        alt: "",
        description: "",
        country: "",
        universityName: "",
        aboutCourse: "",
        whyThisCourse: "",
        whatsNext: "",
      });
      setSingleCourse((prevCourseData) => {
        return { ...prevCourseData, ...response.data };
      });

      setEditBtn(true);
      setShow(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCourseDelete = async (id) => {
    try {
      await axiosInstance.delete(`/deleteCourses/${id}`);
      alert("Image deleted sucessfully");
      const updatedImages = visitImages.filter((image) => image._id !== id);
      setVisitImages(updatedImages);

      setDelCourseStat(!delCouseStat);
    } catch (err) {
      console.log(err);
    }
  };

  function selectCountry(e) {
    e.target.blur();
    setCountry(e.target.value);
  }
  function selectUniversity(e) {
    e.target.blur();
    setUniversity(e.target.value);
  }

  async function CountryHandler(e, queryString) {
    // console.log(e.target.value);
    e.preventDefault();
    try {
      setQuerySting(queryString);
      const formData = { country, queryString };
      const response = await axiosInstance.post(
        `/filterCountryBase?page=${1}`,
        formData
      );
      setCpage(1);
      setFilterStatus(true);
      localStorage.setItem("searchCouUni", JSON.stringify(queryString));
      const ImgaeRecords = response.data.data;
      setVisitImages([]);
      loadData(ImgaeRecords);
    } catch (err) {
      console.log(err);
    }
  }

  const handleLoadMore = () => {
    setVisibleImages((visibleImage) => visibleImage + itemsToLoad);
    if (filterstatus === true) {
      setCpage((prevCount) => prevCount + 1);
    } else {
      setCounter((prevCount) => prevCount + 1);
    }
  };
  return (
    <section className="mx-5 my-5 cvheight ">
      <div>
        {visitMode === true
          ? token && (
              <button
                onClick={handleVisitImage}
                className="ImageEditorBtn end-0 me-4 me-md-5 p-2 z-1 position-fixed rounded-4
            "
              >
                Add Image
              </button>
            )
          : token && (
              <button
                onClick={handleCourseImage}
                className="ImageEditorBtn end-0 me-4 me-md-5 p-2 z-1 position-fixed rounded-4
            "
              >
                Add Image
              </button>
            )}

        {visitMode === true ? (
          <></>
        ) : (
          <div className="d-flex w-100 justify-content-center">
            <div className="d-flex flex-column flex-md-row gap-md-4 justify-content-center align-items-center w-100 pt-5 pb-2">
              <div className=" selectCoun  mt-3">
                <h5>Select Country </h5>
                <div className="d-flex">
                  <select
                    name=""
                    id=""
                    onChange={(e) => selectCountry(e)}
                    className="px-2 py-2 rounded-start border-0  bg-white shadow  w-100 countryList"
                  >
                    <option value="">Choose Country</option>
                    <option value="All">All</option>
                    {couiesUnies.countries.map((eachCountry) => (
                      <option key={eachCountry} value={`${eachCountry}`}>
                        {eachCountry}
                      </option>
                    ))}
                  </select>
                  <button
                    type="submit"
                    className="bg-dark text-light px-2 py-2 border-0"
                    onClick={(e) => CountryHandler(e, { country: country })}
                    disabled={country === "" ? true : false}
                  >
                    search
                  </button>
                </div>
              </div>
              <div className="selectUniv mt-3">
                <h5>Select University</h5>
                <div className="d-flex">
                  <select
                    name=""
                    id=""
                    onChange={(e) => selectUniversity(e)}
                    className="px-2 py-2 rounded-start border-0 bg-white shadow w-100 universityList"
                  >
                    <option value="">Choose University</option>
                    <option value="All">All</option>
                    {couiesUnies.universities.map((eachUniv) => (
                      <option key={eachUniv} value={`${eachUniv}`}>
                        {eachUniv}
                      </option>
                    ))}
                  </select>
                  <button
                    type="submit"
                    onClick={(e) =>
                      CountryHandler(e, { universityName: university })
                    }
                    className="border-0 bg-dark text-light px-2 py-2"
                    disabled={university === "" ? true : false}
                  >
                    search
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className={
            visitMode === true
              ? "d-flex flex-column align-items-center justify-content-center py-4"
              : "Py-4"
          }
        >
          <ImageEditor
            singleCour={singleCourse}
            editButton={editBtn}
            countriesDD={couiesUnies.countries}
            handleClose={setShow}
            btnChangeC={courseBtn}
            btnChangeV={visitBtn}
            addC={setAddCourseStatus}
            addCStat={addCourseStatus}
            showModal={show}
            countryhandler={CountryHandler}
          />
          <div
            className={
              visitMode === true
                ? "d-flex flex-column justify-content-center "
                : "image-gallery position-relative d-grid justify-content-center"
            }
          >
            {visitImages.slice(0, visibleImages).map((image) => (
              <div
                key={image._id}
                className={
                  visitMode === true
                    ? "position-relative visitsImgSize mt-3"
                    : "position-relative"
                }
              >
                <img src={image.Image} alt={image.alt} />

                {visitMode === true ? (
                  <p className="">{image.description}</p>
                ) : (
                  <></>
                )}

                {visitMode === true
                  ? token && (
                      <MdDelete
                        className={
                          image.description
                            ? "position-absolute bottom- end-0  bg-white fs-2 rounded deleteButtonVisits"
                            : "position-absolute bottom- end-0 m-2 bg-white fs-2 rounded deleteButtonVisits"
                        }
                        onClick={(e) => {
                          handleVisitDelete(image._id);
                        }}
                      />
                    )
                  : token && (
                      <>
                        <MdModeEdit
                          className="position-absolute top-0 start-0 m-2 bg-white fs-2 rounded deleteButton"
                          onClick={(e) => handleCourseEdit(image._id)}
                        />
                        <MdDelete
                          className="position-absolute top-0 end-0 m-2 bg-white fs-2 rounded deleteButton"
                          onClick={(e) => {
                            handleCourseDelete(image._id);
                          }}
                        />
                      </>
                    )}
                {/* {visitMode === true ? (
                  <></>
                ) : (
                  <div className="bg-white p-3 courseDescription">
                    <div className="pt-2">
                      <div>
                        <b>About course :</b>
                      </div>
                      {image.aboutCourse}
                    </div>
                    <div className="pt-2">
                      <div>
                        <b>Why This Course :</b>
                      </div>
                      {image.whyThisCourse}
                    </div>
                    <div className="pt-2">
                      <div>
                        <b>WhatsNext :</b>
                      </div>
                      {image.whatsNext}
                    </div>
                  </div>
                )} */}
              </div>
            ))}
          </div>
        </div>

        {visitMode === true ? (
          <Button
            onClick={handleLoadMore}
            className="ImageEditorBtn d-block bg-dark text-white outline-0  mt-4 mx-auto w-50"
          >
            View More Representatives
          </Button>
        ) : (
          <Button
            onClick={handleLoadMore}
            className="ImageEditorBtn d-block bg-dark text-white outline-0  mt-4 mx-auto w-50"
          >
            View More Courses
          </Button>
        )}
      </div>
    </section>
  );
};

export default Courses;
