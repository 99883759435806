import CountryPage from "../CountryPage";
import "../countryPage.css";
import FeeStructureTables from "../FeeStructureTable";

import universityIcon from "../../../Assets/icons8-university-48.png";
import bookIcon from "../../../Assets/book.png";
import cultureIcon from "../../../Assets/culture.png";
import cuisine from "../../../Assets/cuisine.png";
import statue from "../../../Assets/statue of liberty.png";
import courseIcon from "../../../Assets/courses.png";
import innovationIcon from "../../../Assets/innovation.png";
import growthIcon from "../../../Assets/personel growth.png";

//ivy league university logos
import PrincetonUniv from "../../../Assets/princeton_univ.png";
import HarvardUinv from "../../../Assets/harvard_univ.png";
import YaleUniv from "../../../Assets/yale_univ.png";
import PennsylvaniaUniv from "../../../Assets/pennsylvania_univ.png";
import Dartmouth from "../../../Assets/dartmouth_univ.png";
import BrownUniv from "../../../Assets/brown_univ.png";
import CornellUniv from "../../../Assets/cornell_univ.png";
import ColumbiaUniv from "../../../Assets/columbia_univ.png";

//courses iumages
import Engineering from "../../../Assets/engineering.jpg";
import HealthCare from "../../../Assets/healthCare.jpg";
import Business from "../../../Assets/Business.png";
import NaturalScience from "../../../Assets/naturalScience.jpg";
import Design from "../../../Assets/design.png";
import CreativeArts from "../../../Assets/CreativeArts.jpg";
import LiberalArts from "../../../Assets/LiberalArts.jpg";
import computerScience from "../../../Assets/computerScience.png";
import Architecture from "../../../Assets/Architecture.jpg";
import Pharmacy from "../../../Assets/pharmacy.jpg";
import LAW from "../../../Assets/law.png";

function USA() {
  const data = {
    countryName: `United States of America`,
    titleTag: `Study In United States of America For International Students | Cost of Living in USA | Why study in USA | Confluence Educational Services`,
    aboutCountry: ` The United States offers a diverse range of academic programs,
            campus settings, and institution types, with 1,892 public
            institutions, 1,754 private nonprofit institutions, and 2,270
            private for-profit institutions. The country is renowned for its
            quality education and research opportunities, providing
            international students with a commitment to excellence in higher
            education. International students can expect strong support and guidance, as
            the U.S. is known for its welcoming approach to students from around
            the world.`,
    WhyStudyinThatCountry: [
      { icon: universityIcon, text: "Home to Renowed Universities" },
      { icon: bookIcon, text: "Immersive Educational Experience" },
      { icon: courseIcon, text: "Wide range of Courses" },
      { icon: cultureIcon, text: "Cross Culture Exploration" },
      { icon: growthIcon, text: "Personel growth" },
      { icon: innovationIcon, text: "Home to innovation" },
      { icon: statue, text: "Notable Landmarks" },
      { icon: cuisine, text: "International Cuisine" },
    ],
    TopCities: [
      {
        city: "Boston",
        description:
          "Boston is known as the unofficial student capital of the US, with world-renowned universities like Harvard and MIT. Around 20% of students are international, and Boston has more than 500 international employers, providing excellent post-graduate prospects.",
      },
      {
        city: "Chicago",
        description:
          "Chicago is the third most populous city in the US, renowned for its skyscrapers, baseball, and deep-dish pizza. It's also a top choice for international students. PwC's 'Cities of Opportunity' ranked Chicago as one of only four US cities, making it a great urban center.",
      },
      {
        city: "San Francisco",
        description:
          "San Francisco is a top choice for international students, thanks to its iconic landmarks, thriving businesses, and renowned universities. As the economic, commercial, and cultural hub of northern California, the city offers an array of attractions beyond the famous Golden Gate Bridge and Alcatraz Island.",
      },
      {
        city: "Los Angeles",
        description:
          "Los Angeles is a student-friendly city, known for its numerous prestigious universities, including UCLA and USC, providing various academic opportunities and research programs. In addition, the city offers a vibrant and diverse culture, with endless entertainment options, including world-class museums, theaters, and restaurants.",
      },
      {
        city: "New York",
        description:
          "New York City is an exciting and vibrant destination for international students. With prestigious universities like Columbia, NYU, and Cornell, it offers top-notch education. Beyond academics, the city boasts world-class cultural attractions, iconic landmarks, and a thriving industry scene, providing international students with diverse experiences and career opportunities.",
      },
      {
        city: "Washington DC",
        description:
          "Studying in Washington, D.C. places students at the epicenter of U.S. history and politics, surrounded by iconic landmarks such as the White House, Supreme Court, and national monuments. The city's prominence as a top student destination is further strengthened by its five universities, including the highly regarded University of Maryland, College Park.",
      },
      {
        city: "Philadelphia",
        description:
          "Philadelphia, with its Ivy League member, the University of Pennsylvania, along with esteemed institutions like Drexel University and Temple University, stands out as an attractive international study destination. Ranked 48th in the Best Student Cities index, Philadelphia boasts a rich historical legacy, having played a pivotal role in US history and politics, including the signing of the Declaration of Independence in 1776.",
      },
      {
        city: "Pittsburgh",
        description:
          "Pittsburgh stands out by providing a strong quality of living, a diverse student population, and access to a world-renowned university, making up for the high tuition fees common in the US. Carnegie Mellon University, known for its academic excellence, also contributes to the city's impressive employer activity score.",
      },
      {
        city: "Atlanta",
        description:
          "Situated in the southeastern region of the US, Atlanta gained the moniker 'the city too busy to hate' due to its progressive stance during the US Civil Rights Movement. Among the notable universities in Atlanta, Georgia Institute of Technology takes the lead in the QS World University Rankings. Renowned for its extensive research initiatives and strong connections to the industrial sector, Georgia Tech offers a dynamic academic environment.",
      },
      {
        city: "Baltimore",
        description:
          "Situated in the northeastern United States, Baltimore holds the 71st position in the Best Student Cities index and serves as the largest city in Maryland. Renowned for its academic institutions, Baltimore boasts prestigious universities such as Johns Hopkins University, globally recognized for its outstanding medical programs. In close proximity to the city center, the University of Maryland, Baltimore County also garners international recognition for its academic excellence.",
      },
      {
        city: "San Diego",
        description:
          "San Diego, known as 'America's Finest City', captures attention with its picturesque beaches and abundant tourist attractions. San Diego is frequently recognized as one of the safest cities in the US and benefits from its year-round excellent weather, much like Los Angeles. The city's thriving biotechnology sector owes its growth in part to the esteemed University of California, San Diego, and other prominent research institutions located there.",
      },
      {
        city: "Houston",
        description:
          "Ranking as the fourth-largest city in the United States, Houston is often referred to as both the Bayou City and Space City. Recognized as a prominent center for the visual and performing arts, Houston also serves as a major international business hub and hosts the second-busiest port in the country. Notably, it is home to the renowned NASA's Johnson Space Center, a pivotal hub for human spaceflight activities for over 50 years.",
      },
      {
        city: "Dallas",
        description:
          "Dallas offers international students an enjoyable experience without the hefty price tag associated with cities like New York or San Francisco. The city is home to vibrant campuses and provides fascinating cultural opportunities. With over 41 colleges and universities in the metroplex, Dallas boasts the highest concentration of higher education institutions among all metropolitan areas in Texas.",
      },
    ],
    IVYLeagueColleges: [
      {
        university: "PRINCETON UNIVERSITY",
        logo: PrincetonUniv,
        description:
          "Princeton University is a private Ivy League research university located in New Jersey, United States. It was founded in 1746, making it the fourth-oldest institution of higher education in the country. Princeton University's most sought-after majors are social sciences, engineering, computer and information sciences, biological and biomedical sciences, and public administration and social service professions. The university's 4-to-1 student-faculty ratio allows for small class sizes and personalized advising relationships. Known for its exceptional undergraduate program, world-class research facilities, and distinguished faculty, Princeton consistently ranks among the top universities in the world.",
      },
      {
        university: "HARVARD UNIVERSITY",
        logo: HarvardUinv,
        description:
          "Harvard University, located in Cambridge, Massachusetts, founded in 1636, is one of the most prestigious and selective universities in the world. It is renowned for its academic excellence, esteemed faculty, and strong alumni network. Harvard University comprises 13 schools and institutes, each renowned for their academic excellence, including the top-ranked medical school in the U.S. and highly regarded schools of business, graduate education, engineering, and law. Additionally, Harvard is home to the prestigious John F. Kennedy School of Government. The oldest national collection and the largest private collection of books in the world are housed in Harvard University's extensive library system.",
      },
      {
        university: "YALE UNIVERSITY",
        logo: YaleUniv,
        description:
          "Yale University is a renowned private Ivy League research university located in New Haven, Connecticut, founded in 1701. The Yale School of Drama is well-respected for its drama and music programs, making Yale University a top choice for students interested in the performing arts. Along with Yale College and Yale Graduate School of Arts and Sciences, the university comprises 12 professional schools, including the country's top-ranked law school, as well as highly regarded schools of medicine, management, art, and nursing. Yale University's most popular areas of study include social sciences, biological and biomedical sciences, history, mathematics and statistics, and engineering.",
      },
      {
        university: "UNIVERSITY OF PENNSYLVANIA",
        logo: PennsylvaniaUniv,
        description:
          "The University of Pennsylvania, co-founded by Benjamin Franklin, offers a blend of exceptional liberal arts education and professional programs. Penn boasts highly-ranked graduate schools such as the Wharton School, the School of Engineering and Applied Science, the Perelman School of Medicine, and the education and law schools. In addition, Penn's noteworthy graduate schools include the Stuart Weitzman School of Design and the School of Dental Medicine. The School of Engineering and Applied Science offers top-notch faculty, advanced research facilities, and a multidisciplinary curriculum.",
      },
      {
        university: "DARTMOUTH COLLEGE",
        logo: Dartmouth,
        description:
          "Dartmouth College is a private Ivy League research university located in Hanover, New Hampshire, United States. It is known for being the birthplace of the BASIC programming language and boasts the highly ranked Tuck School of Business, which is home to the world's first graduate school of management, as well as the Thayer School of Engineering and the Geisel School of Medicine. With a student-faculty ratio of 7-to-1 and over 60% of classes having fewer than 20 students, Dartmouth provides a personalized learning experience.",
      },
      {
        university: "BROWN UNIVERSITY",
        logo: BrownUniv,
        description:
          "Brown University is a widely-recognized Ivy League institution known for its open curriculum that allows students to design their own academic program. It offers a unique, interdisciplinary education in over 80 fields of study, including Egyptology and Assyriology, cognitive neuroscience, and business entrepreneurship. Additionally, the university offers a competitive program in liberal medical education, allowing students to obtain both an undergraduate degree and medical degree in an eight-year program.",
      },
      {
        university: "CORNELL UNIVERSITY",
        logo: CornellUniv,
        description:
          "Cornell University, established in 1865, is the youngest of the Ivy League schools and has a mission to create, preserve and disseminate knowledge, promote a culture of inquiry, and produce creative work. Cornell has two large undergraduate colleges, the College of Arts and Sciences and the College of Agriculture and Life Sciences, and highly ranked graduate schools such as the Cornell SC Johnson College of Business, Weill Cornell Medical College, College of Engineering, and Law School. Additionally, Cornell is renowned for its School of Hotel Administration and top-ranked College of Veterinary Medicine.",
      },
      {
        university: "COLUMBIA UNIVERSITY",
        logo: ColumbiaUniv,
        description:
          "Columbia University, situated on the Upper West Side of Manhattan, is New York State's oldest institution of higher education. It is home to several significant technologies and platforms, including FM radio and the country's oldest literary magazine. The university has three undergraduate schools: Columbia College, the Fu Foundation School of Engineering and Applied Science, and the School of General Studies. Its highly ranked graduate and professional schools include the business and law schools, the Teachers College, and the Vagelos College of Physicians and Surgeons. The campus has also been featured in numerous films, such as the 'Spider-Man' franchise, which includes scenes shot in Havemeyer Hall.",
      },
    ],
    popularCourses: [
      {
        imageURL: Engineering,
        department: "ENGINEERING",
        courses: [
          "Aerospace Engineering",
          "Mechanical Engineering",
          "Automation Engineering",
          "Civil Engineering",
          "Industrial Engineering",
          "Nuclear Engineering",
          "Chemical Engineering",
          "Electrical Engineering",
        ],
      },
      {
        imageURL: HealthCare,
        department: "Health Care",
        courses: [
          "Medicine",
          "Biomedical Informatics",
          "Nursing",
          "Public Health",
          "Health Administration",
          "Nutrition",
          "Physical Therapy",
          "Sports Medicine",
        ],
      },
      {
        imageURL: Business,
        department: "Business",
        courses: [
          "International Management",
          "Supply Chain",
          "Marketing",
          "Project Management",
          "Human Resources",
          "Finance",
          "Digital Marketing & E-Commerce",
          "International Tourism",
          "Sports Management",
        ],
      },
      {
        imageURL: NaturalScience,
        department: "Natural Science",
        courses: [
          "Agricultural Biology",
          "Applied Mathematics and Statistics",
          "Microbiology",
          "Space Studies",
          "Neural Engineering",
          "Imaging Science",
          "Biotechnology",
          "Marine Science",
          "Genetics",
          "Geospatial Sciences",
        ],
      },
      {
        imageURL: Design,
        department: "Design",
        courses: [
          "Graphic Design",
          "UX/UI Design",
          "Fashion Design",
          "Interior Design",
          "Product Design",
          "Animation and Video Game Design",
        ],
      },
      {
        imageURL: CreativeArts,
        department: "CreativeArts",
        courses: [
          "Fine Arts",
          "Film Studies",
          "Photography",
          "Performing Arts",
          "Animation",
          "Culinary Arts",
        ],
      },
      {
        imageURL: LiberalArts,
        department: "Liberal Arts",
        courses: [
          "Psychology",
          "Sociology",
          "Anthropology",
          "Political Science",
          "Economics",
          "History",
          "International Relations",
          "Gender Studies",
          "Environmental Studies",
        ],
      },
      {
        imageURL: computerScience,
        department: "Computer Science",
        courses: [
          "Media Applications",
          "Game Development",
          "Databases & Data Mining",
          "Computational Mathematics",
          "Natural Language Processing",
          "Artificial Intelligence and Robotics",
          "Biocomputation",
          "Computer & Network Security",
        ],
      },
      {
        imageURL: Architecture,
        department: "Architecture",
        courses: [
          "Biodigital Architecture",
          "Interior Architecture, Interior Design and Decoration",
          "Ephemeral Architecture and Temporary Spaces",
          "Landscaping",
          "Urbanism Projects",
          "Construction Management",
        ],
      },
      {
        imageURL: Pharmacy,
        department: "Pharmacy",
        courses: [
          "Pharmacology and Toxicology",
          "Pharmaceutical Engineering",
          "Pharmaceutics and Drug Delivery",
          "Molecular, Cellular and Biochemical Pharmacology",
          "Pharmacy Administration",
          "Biopharmaceutical Commercialization",
          "Pharmaceutical Manufacturing",
        ],
      },
      {
        imageURL: LAW,
        department: "Law",
        courses: [
          "Criminal Justice",
          "Taxation",
          "Comparative and International Law",
          "Political Theory and Public Law",
          "Environmental and Resource Law",
          "Justice, Law and Crime Policy",
          "Indigenous Peoples Law and Policy",
        ],
      },
    ],
  };

  // const nonStudentServices = [
  //   { visaType: "B", service: "Business or Tourist" },
  //   { visaType: "C", service: "Transit" },
  //   { visaType: "D", service: "Ship & Airline crew" },
  //   { visaType: "I", service: "Journalist or Media" },
  //   { visaType: "J", service: "Exchange Visitor" },
  //   { visaType: "M", service: "Student Vocation" },
  //   { visaType: "CW", service: "Transitinal Worker or CNMI" },
  //   {
  //     visaType: "H",
  //     service: "Temporary or Seasonal Workers and Employment, Trainees",
  //   },
  //   { visaType: "L", service: "Intracompany Transferees" },
  //   { visaType: "P", service: "Athletes, Artists, Entertainers" },
  //   { visaType: "Q", service: "International Cultural Exchange" },
  //   { visaType: "R", service: "Religious Worker" },
  //   { visaType: "K", service: "Fiance or Spouse of Citizen" },
  // ];

  return (
    <div className="bg-white">
      <CountryPage data={data} />
      <div className="p-4 applicationContent mx-auto mb-0">
        <div className="p-2 p-md-4 ">
          <h2 className="text-center countryPageHeading pt-3">
            The Road from Application to Acceptance
          </h2>
          <div>
            <p>
              The procedure for admission into a specific university in the US
              may vary slightly depending on the institution, but typically
              involves the following steps:
            </p>
            <h6>Research</h6>
            <p>
              Identify the universities and programs that best suit your
              academic and personal goals, taking into account factors such as
              location, size, cost, and academic reputation.
            </p>
            <h6>Standardized tests</h6>
            <p>
              Many universities require standardized tests such as the SAT, ACT,
              GRE, or TOEFL. Make sure you research the specific requirements
              for each university you are interested in.
            </p>
            <h6>Application form</h6>
            <p>
              Most universities use the Common Application, which allows you to
              apply to multiple schools at once. The application form typically
              requires personal information, academic history, and an essay or
              personal statement.
            </p>
            <h6>Letters of recommendation</h6>
            <p>
              You will typically need to submit two or three letters of
              recommendation from teachers or other individuals who can attest
              to your academic or personal achievements.
            </p>
            <h6>Transcript</h6>
            <p>
              You will need to provide an official transcript from your high
              school or previous university.
            </p>
            <h6>Application Fee</h6>
            <p>
              Most universities require an application fee, which can range from
              $50 to $100 or more.
            </p>
            <h6>Interview</h6>
            <p>
              Some universities may require an interview as part of the
              application process. This can be conducted in person, over the
              phone, or via video conference.
            </p>
          </div>
        </div>

        <div className="p-2 p-md-4">
          <h2 className="text-center countryPageHeading ">Student Visa</h2>
          <h6>Determine Visa Type</h6>
          <p>
            Individuals must obtain a student visa to study in the US. The F
            visa is for academic programs, while the M visa is for vocational or
            non-academic programs.
          </p>
          <p>
            Individuals who enter the United States on a visitor (B) visa or
            through the Visa Waiver Program (VWP) are not allowed to enroll in
            academic studies, unless it is non-credit recreational study as part
            of a tourist visit.
          </p>
          <h6>Acceptance to a SEVP Approved School</h6>
          <p>
            Once accepted, you will be registered for the Student and Exchange
            Visitor Information System (SEVIS) and must pay the SEVIS I-901 fee.
            The school will issue a Form I-20 necessary for the visa
            application.
          </p>
          <p>
            During the visa interview, the Form I-20 must be presented to the
            consular officer. If the student plans to bring family members, the
            spouse and/or children must also enroll in SEVIS and obtain
            individual Form I-20s, but are not required to pay the SEVIS fee.
          </p>
          <h6>Submitting the Online Visa Application</h6>
          <p>
            Complete the online Nonimmigrant Visa Application, Form DS-160. Fill
            out the form and print the confirmation page to bring to your
            interview. When filling out the online form, you will also be
            required to upload a photo in the appropriate format.
          </p>
          <h6>Scheduling a Visa Interview</h6>
          <p>
            Contact the U.S. Embassy or Consulate in your country of residence
            to schedule an interview. Visa interviews are typically mandatory
            for applicants aged 14 to 79 (with some exceptions for renewals).
          </p>
          <h6>Student Visa Issuance and Entry Requirements</h6>
          <p>
            New student visas can be issued up to a year before the course start
            date, but entry into the US is only allowed 30 days prior.
            Continuing student visas can be issued anytime and they can enter
            the US before classes start.
          </p>
          <h6>Payment of Fees</h6>
          <p>
            Pay a non-refundable visa application fee, currently $185, before
            your interview.
          </p>
          <h6>Required Documents</h6>
          Before your visa interview, you must collect and organize the
          following necessary documents -
          <ul>
            <li>
              Valid passport with at least six months validity beyond the period
              of stay in the US
            </li>
            <li>
              Confirmation page for the DS-160 non-immigrant visa application
            </li>
            <li>Receipt of application fee payment, if applicable</li>
            <li>Uploaded photo in the required format</li>
            <li>
              Signed Form I-20 from a SEVIS-registered school for F-1 or M-1
              student status
            </li>
            <li>
              Individual Form I-20s for spouse and minor children if they plan
              to live with you in the US
            </li>
          </ul>
          <h6>Additional Documentation</h6>
          Additional documentation that may be requested:
          <ul>
            <li>
              Academic records such as transcripts, diplomas, degrees, or
              certificates from schools attended
            </li>
            <li>Standardized test scores required by your US school</li>
            <li>
              Proof of intent to return to home country upon completion of
              studies
            </li>
            <li>
              Evidence of how you will pay for educational, living, and travel
              costs
            </li>
          </ul>
          <h6>Interview Assessment</h6>
          <p>
            A consular officer will assess your eligibility for a student visa
            based on US law. Provide evidence to meet these requirements. You
            might need to pay a visa issuance fee upon approval based on your
            nationality.
          </p>
          <h6>Extending Stay</h6>
          <p>
            F visa holders must leave the US within 60 days of their program end
            date on Form I-20, including any practical training. Extensions can
            be requested through the USCIS website.
          </p>
        </div>

        <div className="p-2 p-md-4  p-md-2">
          <h2 className="text-center countryPageHeading pt-3">
            Post Education Oppurtunity
          </h2>
          <h6>Optional Practical Training (OPT)</h6>
          <p>
            OPT is Temporary employment authorization that allow international
            students to work in the US for up to 12 months after completing
            their degree. STEM students may qualify for a 24-month extension.
          </p>
          <h6>H-1B visa</h6>
          <p>
            Non-immigrant visa allowing US employers to hire foreign workers in
            specialty occupations. International students can apply for H-1B
            visa sponsorship from their employer after completing OPT.
          </p>
          <h6>Green card (Permanent Resident)</h6>
          <p>
            Allows foreign nationals to live and work permanently in the US.
            Obtainable through employment sponsorship, family sponsorship, or
            other avenues.
          </p>
          <h6>Entrepreneurship</h6>
          <p>
            Start your own company in the US. Visa options include the E-2
            treaty investor visa and the EB-5 immigrant investor visa for
            entrepreneurs.
          </p>
          <h6>Further Education</h6>
          <p>
            Pursue graduate school or professional programs such as law or
            business in the US.
          </p>
          <h6>Return to Home Country</h6>
          <p>
            Utilize US education and experience to pursue job opportunities or
            start a business in your home country.
          </p>
        </div>

        <div className="tableContent">
          <h2 className="text-center countryPageHeading">Cost of living</h2>
          <div className="d-flex justify-content-center align-item-center ">
            <table className="border border-secondary mb-3">
              <tr className="border border-secondary">
                <th className="border border-secondary text-center p-2">
                  Expense
                </th>
                <th className="border border-secondary text-center p-2">
                  Cost
                  <br />( in $ )
                </th>
                <th className="text-center p-2">Frequency</th>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 text-center">
                  Books and Study Material
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  900 - 2,000
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  Yearly
                </td>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 px-3 text-center">
                  Travel
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  300 - 700
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  Yearly
                </td>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 px-3 text-center">
                  Accommodation
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  9,800 - 11,100
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  Yearly
                </td>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 px-3 text-center">
                  Electricity
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  100 - 150
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  Monthly
                </td>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 px-3 text-center">
                  Food (On-campus)
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  250
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  Monthly
                </td>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 px-3 text-center">
                  Food (Off-campus)
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  400 - 600
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  Monthly
                </td>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 px-3 text-center">
                  Phone Subscription
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  50
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  Monthly
                </td>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 px-3 text-center">
                  Home Internet
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  45 - 50
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  Monthly
                </td>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 px-3 text-center">
                  Health Insurance
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  700 - 1,100
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  Yearly
                </td>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 px-3 text-center">
                  Clothes
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  500
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  Yearly
                </td>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 px-3 text-center">
                  Water, Trash, and Sewer
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  50 - 75
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  Monthly
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <FeeStructureTables countryName={data.countryName} />
    </div>
  );
}

export default USA;
