import React from "react";
import Courses from "../components/Study Abroad/Courses";
import HelmetWrapper from "../HelmetWrapper";

function CoursesPage() {
  return (
    <div>
      <HelmetWrapper
        title="Explore Our Courses for International Education | Confluence Education"
        description="Discover diverse courses tailored for international education. Learn about course details, locations, and why each course benefits your goals. Find out more with Confluence."
        canonicalUrl="https://www.confluenceedu.com/coursesInfo"
      />
      <Courses />
    </div>
  );
}

export default CoursesPage;
