import React from "react";
import HelmetWrapper from "../HelmetWrapper";

function RefundPage() {
  return (
    <>
      <HelmetWrapper
        title="Payment and Refund Policy of Confluence Educational services Hyderabad"
        description="Discover everything you need to know about Payment and Refund Policy of Confluence Educational services Hyderabad."
        canonicalUrl="https://www.confluenceedu.com/payment-refund-policy"
      />

      <div className="terms p-2 ">
        <h1>Payment and Refund Policy</h1>
        <div className="mt-2">
          <h4>Payment Policy</h4>
          <p>
            Only valid payment methods acceptable to Systems may be used, for
            orders. and all refunds if any will be credited back through our
            bankers.
          </p>
          <p>
            Concerning any payments by credit card or electronic funds transfer,
            by submitting your order for processing, you authorize us to charge
            your order (including taxes, shipping, handling, and any amounts
            agreed upon before order submission) to your credit card or account.
          </p>
          <p>
            If your credit card or account cannot be verified as otherwise
            acceptable, your order may be suspended or canceled automatically.
          </p>
          <p>
            All prices and availability of products are subject to change
            without notice. Taxes will be adjusted from the amount shown on the
            billing screens, which several factors, including variances between
            processor programs and changes in tax rates, may cause.
          </p>
          <p>
            This agreement shall continue in full force and effect on the same
            terms.
          </p>
        </div>
        <div className="mt-2">
          <h4>Refund policy</h4>
          <h5>Professional services</h5>
          <p>
            After thirty (30) days the performance of Professional Services for
            customers provided under a statement of work, such Professional
            Services were performed in a proper and professional manner
            consistent with industry standards will be deemed completed.
          </p>

          <p>
            If a project is delayed at the request of the client, due to the
            client’s internal processes, Confluence will not be responsible for
            any costs incurred by this delay, any fees or damages will stand
            with the client unless agreed in writing at the time or delay by the
            client.
          </p>

          <p>
            Confluence does not offer any refund for hardware, software,
            maintenance, and support services once agreed by the customer,
            annual fees which are passed an anniversary date of the EULA.
            Confluence will charge your account or credit card the amount agreed
            upon under the executed agreement, or as otherwise agreed upon by
            the parties.
          </p>
          <p>
            For more detailed information, please reference the Warranties and
            Disclaimer of Warranties sections of your EULA/SLA.
          </p>

          <h5>Training and services:</h5>
          <p>
            Customer reservations that are canceled thirty (30) or more days
            before the start of the workshop will be refunded, in their
            entirety.
          </p>
          <p>
            Customer reservations that are canceled fourteen to thirty (14-30)
            days from the beginning of the workshop, consultancy, or training
            will be refunded, by fifty percent (50%) of the registration fee.
          </p>
          <p>
            No refund is offered for any cancellations within two (2) weeks of
            the start of the workshop.
          </p>
          <h5>Changes to payment and refund policy</h5>
          <p>
            We reserve the right to change this payment, and refund policy terms
            and conditions at any time. Any such changes will take effect when
            posted on the website.
          </p>
        </div>
      </div>
    </>
  );
}

export default RefundPage;
