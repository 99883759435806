import React from "react";
import "./draftsComponent.css";
import BlogBox from "../Blogpage/BlogBox";
function DraftsComponent() {
  return (
    <div style={{ minHeight: "1000px" }}>
      <BlogBox draftsMode={true} />
    </div>
  );
}

export default DraftsComponent;
