import "./thinkific.css";
import ThinkificImg1 from "../../Assets/homepagethinkific study abroad.jpg";
import ThinkificImg2 from "../../Assets/study abroad thinkific people.png";
import ThinkificImg3 from "../../Assets/study abroard skills thinkific.jpg";
function Thinkific() {
  const thinkificCards = [
    {
      image: ThinkificImg1,
      title: "Study Abroad skills bundle",
    },
    {
      image: ThinkificImg2,
      title: "Study Abroad Admission Guide",
    },
    {
      image: ThinkificImg3,
      title: "Study Abroad Preparation Guide",
    },
  ];
  return (
    <div className="w-100 bg-white wr_thinkific">
      <a
        href="https://confluenceversity.thinkific.com/"
        target="_blank"
        rel="noreferrer"
      >
        <p className="text-center text-black mx-auto  pb-4 fs-4">
          Check our new learning platform, designed specifically to support
          students in their journey of building more knowledge.
        </p>
      </a>
      <div className="d-md-flex justify-content-center thinkificContainer">
        {thinkificCards.map((card) => (
          <a
            href="https://confluenceversity.thinkific.com/"
            target="_blank"
            rel="noreferrer"
            className="thinkificcrd"
          >
            <div className="thinkificCard bg-white my-2">
              <img src={card.image} alt="" className="thinkificImg" />
              <div className="textboxthickific p-3">
                <p className="text-center text-dark fw-bold">{card.title}</p>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default Thinkific;
