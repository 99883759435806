import React from "react";
import "./testPrepBanner.css";

function TestBanner({ IELTS, GRE, Duolingo, PTE, SAT, TOEFL, German, French }) {
  return (
    <div className="testPrepBanner">
      <h3 className="testPrep_Heading">
        Get your free {IELTS}
        {GRE} {Duolingo} {PTE} {SAT} {TOEFL} {German} {French} demo
      </h3>
      <a
        href="https://testprep.confluenceedu.com/login?logout=true"
        className="demo__button border-0 text-center"
      >
        Click Here
      </a>
    </div>
  );
}

export default TestBanner;
