import React from "react";
import FrenchDemo from "../../Assets/french free demo.jpeg";
import FrenchBasics from "../../Assets/french basics.jpeg";
import FrenchA1 from "../../Assets/french A1.jpeg";

import TestBanner from "./TestBanner";
import "./Ielts/ielts-images.css";

function French() {
  const frenchImgs = [
    {
      imgSrc: FrenchDemo,
      alt: "french free demo",
    },
    {
      imgSrc: FrenchBasics,
      alt: "french basics",
    },
    {
      imgSrc: FrenchA1,
      alt: "french A1",
    },
  ];
  return (
    <div>
      <TestBanner French={"French"} />
      <div>
        {frenchImgs.map((Img) => (
          <a href="https://testprep.confluenceedu.com/">
            <img
              src={Img.imgSrc}
              alt={Img.alt}
              className="ieltsimg1 mb-2"
            ></img>
          </a>
        ))}
      </div>
    </div>
  );
}

export default French;
