import CountryPage from "../CountryPage.js";
import "../countryPage.css";
import FeeStructureTables from "../FeeStructureTable.js";
// import Services from "./Services.json";

//Univeristy logos
import BELFAST from "../../../Assets/IvyLeague/BELFAST.png";
import BIRMINGHAM from "../../../Assets/IvyLeague/BIRMINGHAM.svg";
import BRISTOL from "../../../Assets/IvyLeague/BRISTOL.svg";
import CAMBRIDGE from "../../../Assets/IvyLeague/CAMBRIDGE.svg";
import CARDIFF from "../../../Assets/IvyLeague/CARDIFF.png";
import DURHAM from "../../../Assets/IvyLeague/DURHAM.svg";
import EDINBURGH from "../../../Assets/IvyLeague/EDINBURGH.png";
import EXETER from "../../../Assets/IvyLeague/EXETER.jpg";
import GLASGOW from "../../../Assets/IvyLeague/GLASGOW.jpg";
import IMPERIAL from "../../../Assets/IvyLeague/IMPERIAL.jpg";
import KINGS from "../../../Assets/IvyLeague/KINGS.png";
import LIVERPOOL from "../../../Assets/IvyLeague/LIVERPOOL.svg";
import LSE from "../../../Assets/IvyLeague/LSE.png";
import MANCHESTERUni from "../../../Assets/IvyLeague/MANCHESTERUNI.png";
import NEWCASTLE from "../../../Assets/IvyLeague/NEWCASTLE.jpg";
import NOTTINGHAM from "../../../Assets/IvyLeague/NOTTINGHAM.png";
import OXFORD from "../../../Assets/IvyLeague/OXFORD.jpg";
import QUEENMARY from "../../../Assets/IvyLeague/QUEEN MARY.jpg";
import SHEFFIELD from "../../../Assets/IvyLeague/SHEFFIELD.svg";
import SOUTHAMPTON from "../../../Assets/IvyLeague/SOUTHAMPTON.png";
import UCL from "../../../Assets/IvyLeague/UCL.png";
import WARWICK from "../../../Assets/IvyLeague/WARWICK.png";
import YORK from "../../../Assets/IvyLeague/YORK.svg";
import LEEDS from "../../../Assets/IvyLeague/LEEDS.jpg";

import universityIcon from "../../../Assets/icons8-university-48.png";
import bookIcon from "../../../Assets/book.png";
import healthCare from "../../../Assets/healthcare.png";
import workOppurtunity from "../../../Assets/work oppurtunity.png";
import scholorship from "../../../Assets/scholorship.png";
import workVisa from "../../../Assets/workVisa.png";
import innovationIcon from "../../../Assets/innovation.png";
import gateway from "../../../Assets/eiffel tower.png";

import Engineering from "../../../Assets/engineering.jpg";
import HealthCare from "../../../Assets/healthCare.jpg";
import Business from "../../../Assets/Business.png";
import NaturalScience from "../../../Assets/naturalScience.jpg";
import Design from "../../../Assets/design.png";
import CreativeArts from "../../../Assets/CreativeArts.jpg";
import LiberalArts from "../../../Assets/LiberalArts.jpg";
import computerScience from "../../../Assets/computerScience.png";
import Architecture from "../../../Assets/Architecture.jpg";
import Pharmacy from "../../../Assets/pharmacy.jpg";

function Uk() {
  // const { StudentServices } = Services;
  const data = {
    countryName: `United Kingdom`,
    titleTag: `Study In United Kigdom For International Students | Cost of Living in UK | Why study in UK | Confluence Educational Services`,
    aboutCountry: `The UK emerged as the 2nd most popular student destination for higher education. In fields such as business and management, engineering and technology, computing, mathematical sciences, and architecture, building and planning, international students make up more than 30% of the total student population.`,
    WhyStudyinThatCountry: [
      { icon: universityIcon, text: "World Class Education" },
      { icon: bookIcon, text: "Diverse Academic Program" },
      { icon: healthCare, text: "Free Healthcare" },
      { icon: workOppurtunity, text: "Work opportunities" },
      { icon: scholorship, text: "Scholarships and financial aid" },
      { icon: innovationIcon, text: "Access to Cutting Edge Research" },
      { icon: workVisa, text: "Post-Study Work Visa" },
      { icon: gateway, text: "Gateway to Europe" },
    ],
    TopCities: [
      {
        city: "London",
        description:
          "London, the leading student city in the UK, has made remarkable progress in this year's Best Student Cities rankings by securing the top position worldwide for the first time. The city's unsurpassed ranking in the indicator highlights its exceptional standing, while its second-place ranking for employer activity further enhances its appeal.",
      },
      {
        city: "Edinburgh",
        description: `The Scottish capital, Edinburgh, has achieved a strong position in this year's Best Student Cities ranking, securing the 16th spot overall. Renowned for its rich historical heritage and vibrant cultural scene, the city attracts visitors from around the world, particularly during the iconic Edinburgh Fringe Festival.
The city excels in categories such as "student experience," receiving top scores for factors like affordability, employment opportunities, arts, and culture, and more.
`,
      },
      {
        city: "Manchester",
        description: `With a student population of approximately 100,000, Manchester boasts one of the largest student communities in Europe. The city is renowned for its vibrant music scene, having served as the birthplace of iconic bands like Oasis, Joy Division, and The Smiths, making music an integral part of its cultural fabric.`,
      },
      {
        city: "Glasgow",
        description: `Glasgow offers a range of renowned and esteemed universities, including the University of Glasgow, the University of Strathclyde, Glasgow Caledonian University, and Glasgow School of Art.The city's warm and welcoming atmosphere is evident in its diverse student community, with 185,000 students from 140 different countries.`,
      },
      {
        city: "Coventry",
        description: `Ranked 44th overall in the Best Student Cities ranking, Coventry stands as the fifth highest-ranked UK city on the list, just behind Glasgow.The city attracts a significant number of international students, with more than 40 percent hailing from outside the UK, creating a diverse and multicultural environment.
Beyond its academic offerings, Coventry is rich in cultural experiences. It earned the prestigious title of UK City of Culture 2021, a testament to its vibrant arts and cultural scene.
`,
      },
      {
        city: "Nottingham",
        description: `Nottingham is a city rich in history and cultural significance. It boasts notable attractions such as the historic castle and tranquil Arboretum.Nottingham provides convenient transportation options, including affordable buses and trams, well-marked cycle lanes, and available taxis. The city's connection to the legendary figure of Robin Hood is celebrated through a statue near the castle, while Wollaton Hall has gained recognition as a filming location for The Dark Knight Rises.`,
      },
      {
        city: "Birmingham",
        description: `Birmingham, the UK's second-largest city, offers a vibrant and diverse experience for residents and visitors alike. Known for its rich industrial heritage, the city boasts an array of excellent restaurants, historical sites, entertainment venues, shopping opportunities, and cultural attractions. Birmingham's extensive canal network, surpassing even that of Venice, adds to its unique charm. The city is renowned for hosting the largest Christmas market in the UK and a wide range of festivals, including the popular Birmingham Comedy Festival.`,
      },
      {
        city: "Aberdeen",
        description: `For those who appreciate nature, Aberdeen serves as an excellent study destination with its expansive coastline and picturesque countryside waiting to be explored. While it may not be the most budget-friendly option, Aberdeen earns the highest affordability score among the UK's featured cities. Compared to larger urban areas, the city offers relatively lower living costs, including rent, making it a more financially manageable choice for students.`,
      },
      {
        city: "Newcastle-upon-Tyne",
        description: `Newcastle-upon-Tyne, commonly known as Newcastle, claims the 56th spot in the Best Student Cities ranking. As the most populous city in the north-east region of Tyne and Wear, Newcastle boasts a vibrant academic scene, with several esteemed universities, including Durham University, contributing to its educational prowess.`,
      },
      {
        city: "Brighton",
        description: `Brighton, known for its vibrancy and inclusivity, is home to a significant student population, accounting for over 11 percent of its residents. What makes Brighton even more diverse is its international student community, comprising 28 percent of the total student population. This rich mix of students from various backgrounds and cultures contributes to Brighton's strong score in the student mix indicator of the ranking.`,
      },
    ],
    IVYLeagueColleges: [
      {
        university: "UNIVERSITY OF BIRMINGHAM",
        logo: BIRMINGHAM,
        description:
          "Established in 1900 as the UK's first civic university, the University of Birmingham has a long history of nurturing and advancing exceptional intellects. With a diverse and vibrant international community, it employs over 8,500 staff, educates more than 38,000 students, and boasts a network of over 35,000 alumni, making it a truly global institution.",
      },
      {
        university: "UNIVERSITY OF BRISTOL",
        logo: BRISTOL,
        description:
          "Since 1876, the University of Bristol has been recognized for its culture of innovation, earning a global reputation for excellence in teaching and research. With exceptional facilities and a community of highly talented students and staff, it remains at the forefront of higher education.",
      },
      {
        university: "UNIVERSITY OF CAMBRIDGE",
        logo: CAMBRIDGE,
        description:
          "As one of the oldest universities in the world, the University of Cambridge is a self-governed society of esteemed scholars. Established in 1209, it strives to make meaningful contributions to society by pursuing education, learning, and research at the highest levels of global excellence.",
      },
      {
        university: "CARDIFF UNIVERSITY",
        logo: CARDIFF,
        description:
          "Cardiff University, founded in 1883 and situated in a bustling capital city, is an enterprising and forward-thinking institution dedicated to fostering strong global partnerships while emphasizing its allegiance to Wales.",
      },
      {
        university: "DURHAM UNIVERSITY",
        logo: DURHAM,
        description:
          "Situated in the picturesque Durham City in the UK, Durham University is a world-renowned hub of research and education with a global reputation for excellence.",
      },
      {
        university: "UNIVERSITY OF EDINBURGH",
        logo: EDINBURGH,
        description:
          "As a preeminent center of academic distinction, the University of Edinburgh is a world-class institution. With a rich history dating back to 1583, it stands as one of the oldest universities in the UK and the largest in Scotland, generating an annual turnover exceeding £840 million.",
      },
      {
        university: "UNIVERSITY OF EXETER",
        logo: EXETER,
        description:
          "With its main campuses situated in Exeter and Cornwall, the University of Exeter is a premier institution that integrates exceptional research with a high level of student contentment. Its roots can be traced back to the 19th century, although it was formally established in 1955, and it currently boasts a turnover exceeding £350 million, making it a vital contributor to the economy of the southwestern region of England.",
      },
      {
        university: "UNIVERSITY OF GLASGOW",
        logo: GLASGOW,
        description:
          "Established in 1451, the University of Glasgow is the fourth-oldest university in the UK and has since grown into a globally recognized institution renowned for its research-intensive approach and broad-based programs. With a workforce of over 6,700 staff and an annual turnover close to £570 million, it remains at the forefront of higher education.",
      },
      {
        university: "IMPERIAL COLLEGE LONDON",
        logo: IMPERIAL,
        description:
          "Imperial College London, founded in 1907, is a renowned university that focuses on the sciences and is highly regarded for its exceptional teaching and research programs on a global scale. Boasting a staff of over 8,100 individuals, it is a leading institution in its field.",
      },
      {
        university: "KING'S COLLEGE LONDON",
        logo: KINGS,
        description:
          "Established in 1829, King's College London is not only one of the oldest universities in England but also one of the most esteemed institutions worldwide. As a multi-faculty university that prioritizes research, it is home to a talented staff of 7,200 individuals and has an annual turnover surpassing £680 million.",
      },
      {
        university: "UNIVERSITY OF LEEDS",
        logo: LEEDS,
        description:
          "Founded in 1904, although its roots can be traced back to the 19th century, the University of Leeds has evolved into one of the largest universities in the UK. Featuring a diverse student body of over 38,000 individuals hailing from more than 150 countries, it is a thriving hub of global education.",
      },
      {
        university: "UNIVERSITY OF LIVERPOOL",
        logo: LIVERPOOL,
        description:
          "As a hub of research, knowledge, and innovation, the University of Liverpool is a distinguished institution that ranks among the top universities in the UK. Located in the north-west region of England, it boasts an impressive annual turnover exceeding £510 million and a dedicated staff of more than 5,600 individuals.",
      },
      {
        university: "LONDON SCHOOL OF ECONOMICS AND POLITICAL SCIENCE (LSE)",
        logo: LSE,
        description:
          "The London School of Economics and Political Science (LSE) is a preeminent institution that specializes in the field of social sciences. Established in 1895, its faculty and graduates have made significant contributions towards addressing critical global issues and shaping the contemporary society we inhabit.",
      },
      {
        university: "UNIVERSITY OF MANCHESTER",
        logo: MANCHESTERUni,
        description:
          "The University of Manchester, which is the largest university in the UK located on a single site, is dedicated to conducting world-class research, providing exceptional learning opportunities for students, and promoting social responsibility.",
      },
      {
        university: "NEWCASTLE UNIVERSITY",
        logo: NEWCASTLE,
        description:
          "As a civic university that prioritizes research, Newcastle University is globally renowned for its academic excellence, innovation, and creativity. Being a founding member of the Russell Group further solidifies its reputation. The university's impact goes beyond academia as it actively contributes to the economic, social, and cultural growth of North East England.",
      },
      {
        university: "UNIVERSITY OF NOTTINGHAM",
        logo: NOTTINGHAM,
        description:
          "The University of Nottingham is a global institution that operates in the UK, China, and Malaysia, offering inventive and captivating education while also producing research that leads the world. Its financial success is evident, with a turnover exceeding £590 million in the previous year.",
      },
      {
        university: "UNIVERSITY OF OXFORD",
        logo: OXFORD,
        description:
          "Dating back to the 12th century, the University of Oxford is the oldest university in the English-speaking world. It is renowned as one of the top centers of scholarship globally, adopting a collegiate structure and generating a yearly income of over £1.3 billion.",
      },
      {
        university: "QUEEN MARY UNIVERSITY OF LONDON",
        logo: QUEENMARY,
        description:
          "Queen Mary University of London, which originated from four historical colleges in the eastern part of London, has developed into a top-tier higher education institution in the UK that prioritizes research, with an annual turnover exceeding £474 million.",
      },
      {
        university: "QUEEN'S UNIVERSITY BELFAST",
        logo: BELFAST,
        description:
          "Queen's University Belfast, located at the center of Belfast's cultural, arts, and social scenes, is a vibrant and varied institution with more than 25,000 students, consisting of roughly 17,000 undergraduates and over 8,000 postgraduates. The university is also one of the biggest employers in Belfast, contributing a massive £1.9 billion annually to the economy. It hosts a diverse student body, with 15% coming from over 80 different countries.",
      },
      {
        university: "UNIVERSITY OF SHEFFIELD",
        logo: SHEFFIELD,
        description:
          "Established with a Royal Charter in 1905, the University of Sheffield has grown to become one of the largest and most respected research universities in the UK, boasting a student population of nearly 30,000.",
      },
      {
        university: "UNIVERSITY OF SOUTHAMPTON",
        logo: SOUTHAMPTON,
        description:
          "Although it can trace its roots back to 1862, the University of Southampton was officially founded in 1952. This institution excels in academic pursuits while maintaining an inventive and business-minded approach to research, fostering an environment that encourages students and staff to engage and push themselves in their academic endeavors.",
      },
      {
        university: "UNIVERSITY COLLEGE LONDON (UCL)",
        logo: UCL,
        description:
          "Since its establishment in 1826, University College London (UCL) has grown into one of the UK's largest research-focused universities, generating over £1.2 billion in turnover each year. Located in the bustling metropolis of London, UCL has established itself as a dominant force in research and education.",
      },
      {
        university: "UNIVERSITY OF WARWICK",
        logo: WARWICK,
        description:
          "Established in 1965, the University of Warwick is widely recognized for its outstanding research efforts both domestically and internationally. In the latest comprehensive evaluation of research quality among UK universities, 37% of the university's research was rated as world-class, with an additional 50% rated as internationally exceptional.",
      },
      {
        university: "UNIVERSITY OF YORK",
        logo: YORK,
        description:
          "Situated on the outskirts of the northern English city of York, the university's parkland campus is home to over 12,800 undergraduates and 4,000 postgraduates. The university boasts a yearly turnover of nearly £340 million and employs over 3,800 staff members. Approximately one-third of its academic staff and 22% of its students are from countries other than the UK.",
      },
    ],
    popularCourses: [
      {
        imageURL: Engineering,
        department: "Engineering",
        courses: [
          "Robotics",
          "Aerospace",
          "Mechanical Engineering",
          "Automation",
          "Civil Engineering",
          "Mechanical Engineering",
          "Structural Engineering",
          "Metallurgical Engineering",
        ],
      },
      {
        imageURL: HealthCare,
        department: "Health Care",
        courses: [
          "Medicine",
          "Biomedical Informatics",
          "Nursing",
          "Public Health",
          "Health Administration",
          "Nutrition",
          "Physical Therapy",
        ],
      },
      {
        imageURL: Business,
        department: "Business",
        courses: [
          "General Management",
          "International Management",
          "Marketing",
          "Strategic Management",
          "Human Resources",
          "Finance",
          "Consulting",
          "Operations Management ",
          "IT Management",
        ],
      },
      {
        imageURL: NaturalScience,
        department: "Natural Sciences",
        courses: [
          "Agricultural Biology",
          "Applied Mathematics and Statistics",
          "Microbiology",
          "Space Studies",
          "Neural Engineering",
          "Imaging Science",
          "Biotechnology",
          "Marine Science",
          "Genetics",
          "Geospatial Sciences",
        ],
      },
      {
        imageURL: Design,
        department: "Design",
        courses: [
          "Graphic Design",
          "UX/UI Design",
          "Fashion Design",
          "Interior Design",
          "Product Design",
          "Animation and Video Game Design",
        ],
      },
      {
        imageURL: CreativeArts,
        department: "CreativeArts",
        courses: [
          "Fine Arts",
          "Film Studies",
          "Photography",
          "Performing Arts",
          "Animation",
          "Culinary Arts",
        ],
      },
      {
        imageURL: LiberalArts,
        department: "Liberal Arts",
        courses: [
          "Psychology",
          "Sociology",
          "Anthropology",
          "Political Science",
          "Economics",
          "History",
          "International Relations",
          "Environmental Studies",
        ],
      },
      {
        imageURL: computerScience,
        department: "Computer Science",
        courses: [
          "Media Applications",
          "Game Development",
          "Databases & Data Mining",
          "Computational Mathematics",
          "Natural Language Processing",
          "Artificial Intelligence and Robotics",
          "Biocomputation",
          "Computer & Network Security",
        ],
      },
      {
        imageURL: Architecture,
        department: "Architecture",
        courses: [
          "Biodigital Architecture",
          "Interior Architecture, Interior Design and Decoration",
          "Ephemeral Architecture and Temporary Spaces",
          "Landscaping",
          "Urbanism Projects",
        ],
      },
      {
        imageURL: Pharmacy,
        department: "Pharmacy",
        courses: [
          "Pharmacology and Toxicology",
          "Pharmaceutical Engineering",
          "Pharmaceutics and Drug Delivery",
          "Molecular, Cellular and Biochemical Pharmacology",
          "Pharmacy Administration",
          "Biopharmaceutical Commercialization",
          "Pharmaceutical Manufacturing",
        ],
      },
    ],
  };

  return (
    <div className="bg-white">
      <CountryPage data={data} />

      <div className="p-4 applicationContent mx-auto mb-0">
        <div className="p-2 p-md-4">
          <h2 className="text-center countryPageHeading ">
            Student Visa procedure
          </h2>
          <h6>Eligibility</h6>
          <p>
            Individuals aged 16 and above who meet the following criteria are
            eligible to apply for a Student visa in the UK:
          </p>
          <ul>
            <li>
              Have received an unconditional offer of a place on a course with a
              licensed student sponsor.
            </li>
            <li>
              Demonstrate sufficient financial resources to support themselves
              and cover the course expenses, with the required amount varying
              based on individual circumstances.
            </li>
            <li>
              Possess the ability to communicate effectively in English,
              including speaking, reading, writing, and comprehension skills.
            </li>
            <li>
              If aged 16 or 17, provide evidence of parental consent, which is
              required for the application process.
            </li>
          </ul>
          <h6>Documents required</h6>
          <p>
            When individuals apply for a Student visa, they are required to
            submit the following documents:
          </p>
          <ul>
            <li>A current passport or valid travel documentation</li>
            <li>
              A Confirmation of Acceptance for Studies (CAS) from their course
              provider
            </li>
          </ul>
          <p>Additional documents that may be necessary include:</p>
          <ul>
            <li>
              Proof of sufficient funds to support themselves and cover course
              expenses, which can vary based on individual circumstances
            </li>
            <li>
              A valid ATAS certificate, if applicable to their course and
              nationality
            </li>
            <li>
              Evidence of parental or legal guardian consent for applicants
              under 18
            </li>
            <li>
              Documentation establishing the relationship to their parent or
              guardian, if under 18
            </li>
            <li>Results of a tuberculosis test</li>
            <li>
              Written consent from their financial sponsor, if they have
              received sponsorship for course fees and living costs within the
              past 12 months.
            </li>
          </ul>
          <h6>English proficiency</h6>
          <p>
            Applicants are required to demonstrate their proficiency in the
            English language during the application process. They can establish
            their English language proficiency by passing a Secure English
            Language Test (SELT) administered by an approved provider.
          </p>
          <h6>Application</h6>
          <p>
            To apply for a Visa, one must pay a non-refundable fee of £363. As a
            part of the application process, applicants are required to provide
            proof of identity and submit their biometric information, including
            fingerprints and a photograph, at a designated visa application
            center.
          </p>
          <h6>Decision</h6>
          <p>
            Typically, a decision on the application is received within 3 weeks.
            In some cases, applicants may have the option to expedite the
            decision by paying an additional fee, which will be communicated
            during the application process. Upon approval of the application,
            applicants who have provided their biometric information at a visa
            application center will receive a biometric residence permit.
          </p>
        </div>

        <div className="tableContent">
          <h2 className="text-center countryPageHeading">Cost of living</h2>
          <p>
            When arriving in the UK as an international student without
            dependents, you can anticipate spending approximately £1300-£1400
            per month in London or £900-£1300 elsewhere in the country to cover
            various living costs including accommodation, utilities, groceries,
            and other necessary expenses throughout your academic tenure.
          </p>
          <div className="d-flex justify-content-center align-item-center ">
            <table className="border border-secondary mb-3">
              <tr className="border border-secondary">
                <th className="border border-secondary text-center p-2">
                  Expense
                </th>
                <th className="border border-secondary text-center p-1">
                  Cost in London
                  <br />
                  <span>(in £)</span>
                </th>
                <th className="text-center p-2">
                  Rest Of the Uk
                  <br />
                  <span>( in £ )</span>
                </th>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 text-center">
                  Student Halls
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  848
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  664
                </td>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 px-3 text-center">
                  Room in private accommodation
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  750
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  554
                </td>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 px-3 text-center">
                  Household bills
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  140
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  80
                </td>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 px-3 text-center">
                  Groceries
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  155
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  116
                </td>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 px-3 text-center">
                  Socializing
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  18.69
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  17.50
                </td>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 px-3 text-center">
                  Public Transport
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  103
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  54
                </td>
              </tr>
              <tr className="border border-secondary">
                <td className="border border-secondary p-2 px-3 text-center">
                  Student gym membership
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  20
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  15
                </td>
              </tr>

              <tr className="border border-secondary">
                <td className="border border-secondary p-2 px-3 text-center">
                  Mobile phone bill (SIM only plan)
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  18
                </td>
                <td className="border border-secondary p-2 px-3 text-center">
                  18
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <FeeStructureTables countryName={"United Kingdom"} />
    </div>
  );
}

export default Uk;
