import React from "react";
import "./satContent.css";

function SatContent() {
  return (
    <div>
      <div className="sat_content p-2">
        <h1 className="mt-1">About SAT</h1>
        <h5>What is SAT?</h5>
        <p className="para_content">
          <strong style={{ fontSize: "30px" }}>S</strong>AT (Scholastic
          Assessment Test) is a globally recognized standardized test designed
          to assess a student's readiness for college. It evaluates critical
          thinking skills, mathematical ability, and proficiency in reading and
          writing. For many universities worldwide, the SAT score is an
          essential component of the admission process.
        </p>
        <h5>Exam Structure:</h5>
        <p className="para_content">
          The SAT consists of sections in Reading, Writing and Language, Math
          (with calculator and without), and an optional Essay. Each section is
          scored on a scale from 200 to 800, with a total score range of 400 to
          1600 (excluding the essay, which is scored separately).
        </p>
        <h5>Test Duration:</h5>
        <p className="para_content">
          The total duration of the SAT exam, including the optional Essay, is
          about 3 hours and 50 minutes. Without the Essay, the test duration is
          approximately 3 hours.
        </p>
        <h5>Our SAT Courses:</h5>
        <p className="para_content">
          At Confluence Edu, we offer specialized courses designed to equip
          students with the skills and confidence needed to excel in the SAT.
          Our courses include tailored study plans, expert guidance, practice
          tests, and resources aimed at maximizing performance.
        </p>
      </div>
    </div>
  );
}

export default SatContent;
