import { NavLink, Link, useNavigate } from "react-router-dom";
import { DropdownMenu } from "./DropdownMenu";
import "./navbar.css";
import { AuthContext } from "../App";
import { useContext, useEffect } from "react";
import { StudyAbroadDropdown } from "./StudyAbroadDD";

function Navlinks({ handleNavClick }) {
  const { token, setShowLogin, navState, setNavState, setToken } =
    useContext(AuthContext);
  const navigate = useNavigate();

  const handleuserLogout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("token");
    setToken("");
    setNavState(false);
    navigate("/");
  };

  useEffect(() => {}, [token]);

  return (
    <nav className="justify-content-between d-flex flex-column flex-lg-row  align-items-center nav-links-top-list">
      <NavLink to="/" onClick={handleNavClick} className="mx-2 px-3 py-2">
        Home
      </NavLink>

      <div className="dropdown my-2 mx-2 ">
        <NavLink to="/ielts" className="px-3 py-2">
          TestPrep
        </NavLink>
        <div className="dropdown-content">
          <DropdownMenu handleDropdownClick={handleNavClick} />
        </div>
      </div>
      <div className="dropdown my-2 mx-2">
        <Link>
          <NavLink to="/student-guide" className="px-3 py-2">
            Study Abroad
          </NavLink>
          <div className="dropdown-content studyAbroadDrpdwn studyAbroadLink">
            <StudyAbroadDropdown handleDropdownClick={handleNavClick} />
          </div>
        </Link>
      </div>
      <NavLink to="/blogs" onClick={handleNavClick} className="mx-2 px-3 py-2">
        Blogs
      </NavLink>
      {token && (
        <NavLink
          to={`/protected/drafts`}
          onClick={handleNavClick}
          className="mx-2 px-3 py-2"
        >
          Drafts
        </NavLink>
      )}
      <NavLink
        to="/careers"
        onClick={handleNavClick}
        className="mx-2 px-3 py-2"
      >
        Jobs
      </NavLink>
      {/* <a
        href="https://dashboard.confluenceedu.com/"
        target="_blank"
        rel="noreferrer"
        className="login-btn mx-2 px-3 py-2"
      >
        Login
      </a> */}

      {navState ? (
        <Link
          to="/"
          onClick={handleuserLogout}
          className="mx-2 px-3 py-1 text-white bg-danger border-1 rounded"
        >
          Logout
        </Link>
      ) : (
        <button
          onClick={() => {
            setShowLogin(true);
          }}
          className="mx-2 px-3 py-2 fw-medium border-0 rounded-2 login-btn"
        >
          Login
        </button>
      )}

      {/* {token && (
        <Link
          to="/"
          onClick={handleuserLogout}
          className="mx-2 px-3 py-1 text-white bg-danger border-1 rounded"
        >
          Logout
        </Link>
      )} */}
    </nav>
  );
}

export default Navlinks;
