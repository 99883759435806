import { useState, useContext } from "react";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { AuthContext } from "../../App";
import config from "../../Boot/config.json";
import axios from "axios";

function UserLoginPage({ LoginState }) {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { setShowLogin, setNavState } = useContext(AuthContext);
  const [signUp, setSignUp] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    path: "",
  });
  const [signUpData, setSignupData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    role: "Student",
    source: "website",
    path: "",
  });

  const location = useLocation();
  const { serviceId } = useParams();
  // const queryParams = new URLSearchParams(location.search);

  // const searchParams = queryParams.get("serviceId");

  // console.log("------------------searchParms ", searchParams);

  // Close the modal
  const handleClose = () => {
    setShowLogin(false);
  };

  const handleFormChange = (e) => {
    const { id, value } = e.target;
    if (!signUp) {
      setLoginData({ ...loginData, [id]: value });
    }
    if (signUp) {
      setSignupData({ ...signUpData, [id]: value });
    }
  };

  //handling form submission details
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!signUp) {
      const { email, password } = loginData;

      if (!email || !password) {
        setError("Enter a valid email and password");
        return;
      } else {
        if (serviceId) {
          loginData.otp = serviceId.slice(10, 14);
        }
        loginData.path = location.pathname;
        await axios
          .post(`${config.dashBoardUrl}/usersLogin`, loginData)
          .then((res) => {
            localStorage.setItem("userName", res.data.name);
            localStorage.setItem("userId", res.data.token);
            if (res.data.path) {
              navigate(res.data.path);
            } else {
              navigate("/destination");
            }
            setError("");
            setShowLogin(false);
            setNavState(true);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      const { name, email, phone, password, confirmPassword } = signUpData;
      if (!name) {
        setError("Enter a valid name");
      } else if (!email) {
        setError("Enter a valid email");
      } else if (!phone) {
        setError("Enter a valid phone number");
      } else if (!password || password !== confirmPassword) {
        setError("Enter a valid password");
      } else {
        signUpData.path = location.pathname;
        await axios
          .post(`${config.dashBoardUrl}/register`, signUpData)
          .then((res) => {
            setError("");
            setShowLogin(false);
            alert("Login through the link sent to registered email address");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  return (
    <div>
      <Modal show={LoginState} onHide={handleClose} size="md">
        {!signUp && (
          <div>
            <Modal.Header closeButton>
              <h3>Login</h3>
            </Modal.Header>
            <form
              action=""
              className="p-3 d-flex flex-column justify-content-start "
              onSubmit={handleFormSubmit}
            >
              <fieldset className="d-flex flex-column my-2">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  id="email"
                  placeholder="Enter Email Address"
                  className="p-2 rounded-2 border border-secondary"
                  onChange={handleFormChange}
                />
              </fieldset>
              <fieldset className="d-flex flex-column my-2">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  placeholder="Enter Password"
                  className="p-2 rounded-2 border border-secondary"
                  onChange={handleFormChange}
                />
              </fieldset>

              {error && error === "Enter a valid email and password" ? (
                <p className="text-danger">{error} </p>
              ) : (
                ""
              )}
              <p>
                Don't have an account?
                <span
                  className="text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSignUp(true);
                  }}
                >
                  &nbsp;Sign Up
                </span>
              </p>
              <button
                type="submit"
                className="p-2 border-0 BuyNowBtn rounded-2 text-white"
              >
                Submit
              </button>

              <p className="text-center mt-3">
                Go to
                <Link
                  to="/"
                  className="text-decoration-none"
                  onClick={() => {
                    setShowLogin(false);
                  }}
                >
                  &nbsp; HomePage
                </Link>
              </p>
            </form>
          </div>
        )}

        {signUp && (
          <div>
            <Modal.Header closeButton>
              <h3>Sign up</h3>
            </Modal.Header>
            <form
              action=""
              className="p-3 d-flex flex-column justify-content-start "
              onSubmit={handleFormSubmit}
            >
              <fieldset className="d-flex flex-column my-2">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  placeholder="Enter Your Name"
                  className="p-2 rounded-2 border border-secondary"
                  onChange={handleFormChange}
                />
                {error && error === "Enter a valid name" ? (
                  <p className="text-danger">{error} </p>
                ) : (
                  ""
                )}
              </fieldset>
              <fieldset className="d-flex flex-column my-2">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  id="email"
                  placeholder="Enter Email Address"
                  className="p-2 rounded-2 border border-secondary"
                  onChange={handleFormChange}
                />
                {error && error === "Enter a valid email" ? (
                  <p className="text-danger">{error} </p>
                ) : (
                  ""
                )}
              </fieldset>
              <fieldset className="d-flex flex-column my-2">
                <label htmlFor="phone">Mobile Number</label>
                <input
                  type="text"
                  id="phone"
                  placeholder="Enter Mobile Number"
                  className="p-2 rounded-2 border border-secondary"
                  onChange={handleFormChange}
                />
                {error && error === "Enter a valid phone number" ? (
                  <p className="text-danger">{error} </p>
                ) : (
                  ""
                )}
              </fieldset>
              <fieldset className="d-flex flex-column my-2">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  placeholder="Enter Password"
                  className="p-2 rounded-2 border border-secondary"
                  onChange={handleFormChange}
                />
              </fieldset>
              <fieldset className="d-flex flex-column my-2">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  className="p-2 rounded-2 border border-secondary"
                  onChange={handleFormChange}
                />
                {error && error === "Enter a valid password" ? (
                  <p className="text-danger">{error} </p>
                ) : (
                  ""
                )}
              </fieldset>
              <p>
                Already have an account?
                <span
                  className="text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSignUp(false);
                  }}
                >
                  &nbsp;Login
                </span>
              </p>
              <button
                type="submit"
                className="p-2 border-0 BuyNowBtn rounded-2 text-white"
              >
                Submit
              </button>

              <p className="text-center mt-3">
                Go to
                <Link
                  to="/"
                  className="text-decoration-none"
                  onClick={() => {
                    setShowLogin(false);
                  }}
                >
                  &nbsp; HomePage
                </Link>
              </p>
            </form>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default UserLoginPage;
