import React from "react";
import StudyAbroadDes from "../components/Study Abroad/StudyAbroadDes.js";
import StudentJourney from "../components/Study Abroad/StudentJourney.js";
import StudyAbroadBanner from "../components/Study Abroad/StudyAbroadBanner.js";
import HelmetWrapper from "../HelmetWrapper.js";

function StudyAbroad() {
  return (
    <div className="">
      <HelmetWrapper
        title="Benefits of Studying Abroad for Indian Students"
        description="Discover the advantages of studying abroad for Indian students, including global exposure, quality education, career opportunities, personal development, and language proficiency enhancement."
        canonicalUrl="https://www.confluenceedu.com/student-guide"
      />
      <StudyAbroadBanner />
      <StudyAbroadDes />
      <StudentJourney />
    </div>
  );
}

export default StudyAbroad;
