import React from "react";
import "./duolingoContent.css";
function DuolingoContent() {
  return (
    <div>
      {/* <div className="testPrepBanner"></div> */}
      <div className="duolingoContent p-2">
        <h1 className="mt-1">About Duolingo</h1>
        <h5>What is Duolingo?</h5>
        <p className="para_content para_content1">
          <strong style={{ fontSize: "30px" }}>D</strong>uolingo is a widely
          recognized language-learning platform offering courses in multiple
          languages. Renowned for its user-friendly interface and gamified
          approach, Duolingo provides an engaging experience for learners of all
          levels, from beginners to advanced.
        </p>
        <h5>How Duolingo Exam Works:</h5>
        <p className="para_content">
          Duolingo's proficiency exam is an innovative tool widely accepted by
          educational institutions and immigration departments as a language
          proficiency test. The exam evaluates a candidate's reading, writing,
          listening, and speaking skills in the chosen language. It's
          administered entirely online, providing convenience and accessibility
          to test-takers worldwide.
        </p>
        <h5>Why Choose Duolingo Courses with Us:</h5>
        <p className="para_content">
          At our consultancy, we offer tailored courses designed to prepare
          students for the Duolingo proficiency exam. Our curriculum focuses on
          enhancing language skills aligned with the exam format, ensuring
          students feel confident and well-prepared. With experienced
          instructors and comprehensive study materials, we aim to maximize
          success in achieving desired scores for academic or immigration
          purposes.
        </p>
        <br />
        <p className="para_content">
          Join our Duolingo courses and embark on a language-learning journey
          that prepares you for success in the Duolingo proficiency exam,
          opening doors to a world of possibilities abroad.
        </p>
      </div>
    </div>
  );
}

export default DuolingoContent;
