import React from "react";
import "./terms.css";
import HelmetWrapper from "../../HelmetWrapper";

function Terms() {
  return (
    <>
      <HelmetWrapper
        title="Terms And Conditions of Confluence Educational services Hyderabad"
        description="Discover everything you need to know about Terms And Conditions of Confluence Educational services Hyderabad."
        canonicalUrl="https://www.confluenceedu.com/TermsAndConditions"
      />

      <div className="terms p-2 fw-light">
        <h1>Terms And Conditions</h1>
        <ul>
          <li>
            Once a User has accepted these Terms and Conditions, they may
            register and avail the Services.
          </li>
          <li>
            In these Terms and Conditions, the term “Chargeback” shall mean,
            approved and settled credit card or net banking purchase
            transaction(s) which are at any time refused, debited or charged
            back to merchant account (and shall also include similar debits to
            Payment Service Provider’s accounts, if any) by the acquiring bank
            or credit card company for any reason whatsoever, together with the
            bank fees, penalties and other charges incidental thereto.
          </li>
          <li>
            Server Slow Down/Session Timeout: In case, the Website or Payment
            Service Provider’s webpage, which is linked to the Website, is
            experiencing any server related issues like ‘slow down’ or ‘failure’
            or ‘session timeout’, the User shall check whether his/her Bank
            Account has been debited or not and accordingly resort to one of the
            following options, before initiating the second payment :
            <ul>
              <li>
                In case, the Bank Account appears to be debited, ensure that he/
                she does not make the payment twice and immediately thereafter
                contact the Institute via e-mail or any other mode of contact as
                provided by the Institute to confirm payment.
              </li>
              <li>
                In case the Bank Account is not debited, the User may initiate a
                fresh transaction to make payment.
              </li>
              <li>
                However, the User agrees that under no circumstances, the
                Payment Gateway Service Provider shall be held responsible for
                such fraudulent/duplicate transactions and hence no claims
                should be raised to Payment Gateway Service Provider. No
                communication received by the Payment Service Provider(s) in
                this regard shall be entertained by the Payment Service
                Provider(s).
              </li>
            </ul>
          </li>
          <li>
            The Institute and the Payment Service Provider(s) assume no
            liability whatsoever for any monetary or other damage suffered by
            the User on account of:
            <ul>
              <li>
                The delay, failure, interruption, or corruption of any data or
                other information transmitted in connection with use of the
                Payment Gateway or Services in connection thereto; and/ or
              </li>
              <li>
                Any interruption or errors in the operation of the Payment
                Gateway.
              </li>
            </ul>
          </li>
          <li>
            The User agrees, understands and confirms that their personal data
            including without limitation details relating to debit card/ credit
            card transmitted over the Internet may be susceptible to misuse,
            hacking, theft and/ or fraud and that the Institute or the Payment
            Service Provider(s) have no control over such matters.
          </li>
        </ul>
      </div>
    </>
  );
}

export default Terms;
