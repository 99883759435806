import React from "react";
import FeeStructureTables from "../FeeStructureTable";
import Services from "./Services.json";
import "./countryImage.css";
import NewZealandAtoV from "../../../Assets/countryImages/NewZealandAtoV.jpg";

function Newzealand() {
  const StudentServices = [
    {
      service: "Bachelors Admission Counseling Services",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
    {
      service: "Masters Admission Counseling Services",
      Fees: 25000,
      GST: 4500,
      Total: 29500,
    },
    {
      service: "Visit Visa Services",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
  ];

  return (
    <div className="bg-white d-flex justify-content-center flex-column align-items-center">
      <img
        src={NewZealandAtoV}
        alt="Newzealand admission to visa"
        className="countryAtoVImg"
      />
      <FeeStructureTables
        countryName={"NewZealand"}
        studentServices={StudentServices}
      />
    </div>
  );
}

export default Newzealand;
