import React from "react";
import GreContent from "../components/Test prep/Gre/GreContent";
import GreImages from "../components/Test prep/Gre/Gre-images";
import TestBanner from "../components/Test prep/TestBanner";
import HelmetWrapper from "../HelmetWrapper";

function Gre() {
  return (
    <>
      <HelmetWrapper
        title="GRE Exam Overview | Confluence Education"
        description="Learn about the GRE exam and its components. Prepare for graduate and business school admissions with Confluence Education's expert guidance."
        canonicalUrl="https://www.confluenceedu.com/gre"
      />
      <TestBanner GRE={"GRE"} />
      <GreContent />
      <GreImages />
    </>
  );
}

export default Gre;
